import React, { useEffect, useState } from "react";
import { trackCustomEvent } from "../services/facebookPixel";

const HostedPage = ({ amount, orderRef }) => {
  const [signature, setSignature] = useState("");
  const [merchantID, setMerchanId] = useState("");
  const [url, setUrl] = useState("");
  const [formdata, setData] = useState({
    // merchantID: "267527",
    // merchantID: "267475",
    action: "SALE",
    type: 1,
    countryCode: 826,
    currencyCode: 826,
    amount: amount * 100,
    // amount: 900,
    orderRef: orderRef,
    // orderRef: "66a91b36d9028",
    // transactionUnique: "66a91b36d902b",
    transactionUnique:
      Date.now().toString(36) + Math.random().toString(36).substr(2),
    // redirectURL: "https://www.pass-swiftly.com/booking",
    redirectURL: `${window.location.protocol}//${window.location.host}/booking`,
    // redirectURL: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
  });
  const generateandSetUniqueId = () => {
    const uniqueId = Math.random().toString(36).substr(2, 10);
    setData((prevData) => (prevData.transactionUnique = uniqueId));
  };

  useEffect(() => {
    const fetchSignature = async () => {
      try {
        const response = await fetch("/api/generate-signature", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: formdata }),
        });
        // console.log(response);
        const data = await response.json();
        setSignature(data.signature);
        setMerchanId(data.merchantID);
        // console.log(data.signature);
        // console.log(data.merchantID);
      } catch (err) {
        console.log(err);
      }
      // .then((res) => res.json())
      // .then((data) => {
      //   setSignature(data.signature);
      //   console.log(signature);
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
    };
    // generateandSetUniqueId();
    fetchSignature();
  }, [formdata]);
  useEffect(() => {
    setData((prev) => ({
      ...prev,
      orderRef: orderRef,
    }));
  }, [orderRef]);
  const handlePaymentInitiation = () => {
    trackCustomEvent("PaymentInitiated", {
      content_name: "Payment Initiated",
      content_category: "Transaction",
      value: amount,
      currency: "GBP",
    });
  };

  return (
    <form method="post" action="https://gw1.tponlinepayments.com/paymentform/">
      {/* <input type="hidden" name="merchantID" value={formdata.merchantID} /> */}
      <input type="hidden" name="merchantID" value={merchantID} />
      <input type="hidden" name="action" value={formdata.action} />
      <input type="hidden" name="type" value={formdata.type} />
      <input type="hidden" name="countryCode" value={formdata.countryCode} />
      <input type="hidden" name="currencyCode" value={formdata.currencyCode} />
      <input type="hidden" name="orderRef" value={formdata.orderRef} />
      <input type="hidden" name="amount" value={formdata.amount} />
      {/* <input type="hidden" name="formResponsive" defaultValue="Y" />
      <input type="hidden" name="customerNameMandatory" defaultValue="Y" />
      <input type="hidden" name="captureDelay" defaultValue={5} /> */}
      <input
        type="hidden"
        name="transactionUnique"
        value={formdata.transactionUnique}
        // defaultValue="66a91b36d902b"
      />
      <input
        type="hidden"
        name="redirectURL"
        // defaultValue="https://dev.bee-online.com/cs-hosted/callback.php"
        // defaultValue="https://www.pass-swiftly.com/"
        // value={url}
        value={formdata.redirectURL}
      />
      <input
        type="hidden"
        name="signature"
        value={signature}
        // defaultValue="39deb4e2c6e129bf00ea6bc03f05696a799462da6ac026d3b9393e45a4eb55aa12fc7b38e8316a61705b197fb673fc0e617be07b362ef62d8cf064558c208c58"
        // defaultValue="ca0537c6770f6077ecd350dab967178d0a23720c5ccc25c690d849407e176bc173a3a2e3ebf0094d7de2a8677dbd0908525053601910b67160c148e298b97dd7"
      />
      <div className="w-full" style={{ opacity: 1, backgroundColor: "" }}>
        <div
          className="mb-4 p-5 text-center"
          // className="mb-4 p-5 d-flex justify-content-center align-items-center"
          style={{
            borderRadius: "10px",
            padding: "10px",
            boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          }}
        >
          <p>You are Paying £ {amount}</p>
          <input
            // onClick={() => {
            //   console.log("formdata", formdata);
            // }}
            type="submit"
            onClick={handlePaymentInitiation}
            value="Pay Now"
            title="Pay Now"
            style={{
              borderRadius: "20px",
              backgroundColor: "#FB8500",
              outline: "1px solid white",
              color: "white",
            }}
            className="btn"
          />
          {/* {orderRef} */}
        </div>
      </div>
      {/* {signature}
      {merchantID} */}
      {/* {JSON.stringify(formdata)} */}
      {/* <iframe
        title="paymentFrame"
        name="paymentFrame"
        style={{ display: "none" }}
        onLoad={() => {
          // Handle the payment response if needed
          const iframeDocument = document.querySelector(
            'iframe[name="paymentFrame"]'
          ).contentDocument;
          if (iframeDocument) {
            const formData = new URLSearchParams(
              new FormData(iframeDocument.querySelector("form"))
            );
            localStorage.setItem(
              "paymentResponse",
              JSON.stringify(Object.fromEntries(formData))
            );
            window.location.href = `/order/confirm/${orderRef}`;
          }
        }}
      /> */}
    </form>
    // {/* <form method="post" action="https://gw1.tponlinepayments.com/paymentform/">
    //   <input type="hidden" name="merchantID" defaultValue={267527} />
    //   <input type="hidden" name="action" defaultValue="SALE" />
    //   <input type="hidden" name="type" defaultValue={1} />
    //   <input type="hidden" name="countryCode" defaultValue={826} />
    //   <input type="hidden" name="currencyCode" defaultValue={826} />
    //   <input type="hidden" name="amount" defaultValue={900} />
    //   <input type="hidden" name="orderRef" defaultValue="66a91b36d9028" />
    //   {/* <input type="hidden" name="formResponsive" defaultValue="Y" />
    //   <input type="hidden" name="customerNameMandatory" defaultValue="Y" />
    //   <input type="hidden" name="captureDelay" defaultValue={5} /> */}
    //   <input
    //     type="hidden"
    //     name="transactionUnique"
    //     // value={transactionUnique}
    //     defaultValue="66a91b36d902b"
    //   />
    //   <input
    //     type="hidden"
    //     name="redirectURL"
    //     // defaultValue="https://dev.bee-online.com/cs-hosted/callback.php"
    //     defaultValue="https://www.pass-swiftly.com/"
    //   />
    //   <input
    //     type="hidden"
    //     name="signature"
    //     // defaultValue="39deb4e2c6e129bf00ea6bc03f05696a799462da6ac026d3b9393e45a4eb55aa12fc7b38e8316a61705b197fb673fc0e617be07b362ef62d8cf064558c208c58"
    //     defaultValue="ca0537c6770f6077ecd350dab967178d0a23720c5ccc25c690d849407e176bc173a3a2e3ebf0094d7de2a8677dbd0908525053601910b67160c148e298b97dd7"
    //   />
    //   <input type="submit" defaultValue="Pay Now" />
    // </form> */}
  );
};

export default HostedPage;
