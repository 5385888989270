import React from "react";
import { Calendar } from "primereact/calendar";

export const CustomDatePicker = (props) => {
  const {
    id,
    selectedValue,
    placeholder,
    handleChange,
    className,
    isRequired,
    isClearable,
    minDate,
    isDisabled,
    showTime,
    showIcon,
    isFormField,
    labelClass,
    label,
    panelClassName,
    autoFocus,
  } = props;
  const floatLabel = (
    <label
      htmlFor={id ? `${id}` : "calendar"}
      className={`float-label ${labelClass ? labelClass : ""}`}
      style={
        selectedValue || selectedValue === 0
          ? {
              borderTopLeftRadius: "3px",
              borderTopRightRadius: "3px",
              fontSize: "11px ",
              backgroundColor: "white",
              marginTop: "0px",
              paddingTop: "4px",
              paddingRight: "4px",
              paddingBottom: "0px",
              paddingLeft: "4px",
            }
          : undefined
      }
    >
      {`${label}${isRequired ? "*" : ""}`}
    </label>
  );
  return isFormField ? (
    <span className="p-float-label mb-3">
      <Calendar
        id={id ? `${id}` : "calendar"}
        baseZIndex={1111}
        className={`${className} w-100 custom-calendar form-field`}
        clearButtonClassName="color-secondary"
        dateFormat="dd-M-yy"
        dateTemplate={(date) => {
          return <strong>{date.day}</strong>;
        }}
        disabled={isDisabled}
        hourFormat="12"
        inputClassName={"border-radius-0"}
        monthNavigator
        minDate={minDate}
        onChange={(e) => handleChange(e.value)}
        panelClassName={`${
          panelClassName ? panelClassName : ""
        } p-0 ml-1 mt-2 border-radius-0 custom-datepicker`}
        required={isRequired}
        selectOtherMonths
        showButtonBar={isClearable}
        showIcon={showIcon}
        showOnFocus={autoFocus}
        showTime={showTime}
        todayButtonClassName="color-primary m-0 py-0"
        value={selectedValue}
        yearRange="2000:3000"
        yearNavigator
      />
      {floatLabel}
    </span>
  ) : (
    <>
      <Calendar
        id={id ? `${id}` : "calendar"}
        baseZIndex={1111}
        className={`${className} custom-calendar`}
        clearButtonClassName="color-secondary"
        dateFormat="dd-M-yy"
        dateTemplate={(date) => {
          return <strong>{date.day}</strong>;
        }}
        disabled={isDisabled}
        hourFormat="12"
        monthNavigator
        minDate={minDate}
        onChange={(e) => handleChange(e.value)}
        panelClassName={`${
          panelClassName ? panelClassName : ""
        } p-0 m-0 border-radius-0 custom-datepicker`}
        required={isRequired}
        placeholder={placeholder}
        selectOtherMonths
        showButtonBar={isClearable}
        showIcon={showIcon}
        showOnFocus={autoFocus}
        showTime={showTime}
        todayButtonClassName="color-primary m-0 py-0"
        value={selectedValue}
        yearRange="2010:2999"
        yearNavigator
      />
    </>
  );
};
