import React from "react";
import "../styles/VideosSection.css";
import { useInView } from "react-intersection-observer";
const VideosSection = () => {
  const { ref: video1Ref, inView: video1InView } = useInView({
    triggerOnce: true,
  });
  const { ref: video2Ref, inView: video2InView } = useInView({
    triggerOnce: true,
  });
  const { ref: video3Ref, inView: video3InView } = useInView({
    triggerOnce: true,
  });
  const localVideo1 = require("../Assets/Compilation.mp4");
  const localVideo2 = require("../Assets/Compilation1.mp4");
  const localVideo3 = require("../Assets/ClearingTest.mp4");

  return (
    <div className="d-flex flex-wrap container">
      <div
        style={{ height: "400px", position: "relative", overflow: "hidden" }}
        className="video-section-player-div d-flex flex-column py-2"
        ref={video1Ref}
      >
        {video1InView && (
          <video
            className="py-3"
            src={localVideo1}
            width="100%"
            height="100%"
            controls={false} // No controls if you want a background video
            autoPlay
            muted
            loop
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "contain", // Ensures the video covers the container
            }}
          />
        )}
      </div>
      <div
        style={{ height: "400px", position: "relative", overflow: "hidden" }}
        className="video-section-player-div  d-flex flex-column py-2"
        ref={video2Ref}
      >
        {video2InView && (
          <video
            className="py-3"
            src={localVideo2}
            width="100%"
            height="100%"
            controls={false} // No controls if you want a background video
            autoPlay
            muted
            loop
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "contain", // Ensures the video covers the container
            }}
          />
        )}
      </div>
      <div
        style={{ height: "400px", position: "relative", overflow: "hidden" }}
        className="video-section-player-div d-flex flex-column py-2"
        ref={video3Ref}
      >
        {video3InView && (
          <video
            className="py-3"
            src={localVideo3}
            width="100%"
            height="100%"
            controls={false} // No controls if you want a background video
            autoPlay
            muted
            loop
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "contain", // Ensures the video covers the container
            }}
          />
        )}
      </div>
    </div>
  );
};

export default VideosSection;

// import React from "react";

// const VideoPlayer = () => {
//   const videoSrc = `https://youtube.com/embed/7-Wt4NV1wJw?si=0bYr0Vc8_sTt9p0k?controls=0&modestbranding=1&rel=0&showinfo=0&autoplay=1`;
//   return (
//     <div
//       className="d-flex flex-column flex-grow-1"
//       style={{
//         position: "relative",
//         // paddingBottom: "56.25%",
//         paddingBottom: "50.25%",
//         height: 0,
//         overflow: "hidden",
//         width: "40%",
//         // maxWidth: "100%",
//         background: "#000",
//         margin: "auto",
//       }}
//     >
//       <iframe
//         title="YouTube video player"
//         src={videoSrc}
//         style={{
//           position: "absolute",
//           top: 0,
//           left: 0,
//           width: "100%",
//           height: "400px",
//         }}
//         frameBorder="0"
//         allow="autoplay; encrypted-media"
//         allowFullScreen
//       />
//     </div>
//   );
// };
// export default VideoPlayer;

/* <ReactPlayer
        url="https://www.youtube.com/watch?v=7-Wt4NV1wJw"
        width="100%"
        height="100%"
        controls={false} // Hide YouTube controls
        playing={true} // Autoplay video
        muted={true} // Mute the video
        config={{
          youtube: {
            playerVars: {
              modestbranding: 1, // Hide YouTube logo
              rel: 0, // Disable related videos at the end
              showinfo: 0, // Hide video info
              autoplay: 1, // Autoplay video
              controls: 0, // Hide player controls
              disablekb: 1, // Disable keyboard controls
              fs: 0, // Disable fullscreen button
            },
          },
        }}
      />
      <div
        className="money-back-guarantee d-flex flex-column"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
          backgroundColor: "transparent",
        }}
      /> */

// <div
//   className="d-flex flex-column flex-grow-1"
//   style={{
//     position: "relative",
//     paddingBottom: "56.25%", // Standard 16:9 aspect ratio
//     height: 0,
//     overflow: "hidden",
//     width: "40%",
//     background: "#000",
//     margin: "auto",
//   }}
// >
//   <ReactPlayer
//     url={videoSrc}
//     width="100%"
//     height="100%"
//     controls={false} // Hide YouTube controls
//     playing={true} // Autoplay video
//     muted={true} // Mute the video
//     config={{
//       youtube: {
//         playerVars: {
//           //   modestbranding: 1, // Hide YouTube logo
//           //   rel: 0, // Disable related videos at the end
//           //   showinfo: 0, // Hide video info
//           //   autoplay: 1, // Autoplay video
//           //   controls: 0, // Hide player controls
//         },
//       },
//     }}
//   />
// </div>
