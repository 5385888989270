import React, { Component } from "react";
// import { Dropdown } from 'primereact/dropdown';
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import AsyncSelect from "react-select/async";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "50px",
  color: "#112648",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};
class MyOwnSelect extends Component {
  _isMounted = true;
  constructor() {
    super();

    this.state = {
      isLoading: false,
      inputValue: "",
    };
    this.animatedComponents = makeAnimated({
      Option: this.Option,
      DropdownIndicator: this.DropdownIndicator,
      IndicatorSeparator: this.IndicatorSeparator,
      ValueContainer: this.ValueContainer,
      MultiValue: this.MultiValue,
      MenuList: this.MenuList,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  filterOptions = (inputValue) => {
    if (inputValue.length < 3) return [];
    return (
      this.props.options &&
      this.props.options.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
  };

  loadOptions = (inputValue) => {
    if (inputValue.length < 3) return;
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.filterOptions(inputValue));
      }, 1000);
    });
  };

  // componentWillReceiveProps({ isLoading, options, Entities }) {
  //     this._isMounted && this.setState({ ...this.state, isLoading, options, Entities })
  // }

  handleChange = (selectedOption, e) => {
    let { options } = this.props;
    if (this.props.isMulti && this.props.allowSelectAll) {
      if (selectedOption !== null && selectedOption.length > 0) {
        if (
          selectedOption[selectedOption.length - 1].value === allOption.value
        ) {
          this.props.handleChange([allOption, ...options]);
          return;
        }
        let result = [];
        if (selectedOption.length === options.length) {
          if (selectedOption.includes(allOption)) {
            result = selectedOption.filter(
              (option) => option.value !== allOption.value
            );
          } else if (e.action === "select-option") {
            result = selectedOption;
          }
          this.props.handleChange(result);
          return;
        }
      }
    }
    this.props.handleChange(selectedOption);
  };

  handleCreate = async (inputValue) => {
    this._isMounted && this.setState({ isLoading: true });
    await this.props.handleCreate(inputValue);
    this._isMounted && this.setState({ isLoading: false });
  };

  DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <i
            className="fa fa-chevron-down"
            aria-hidden="true"
            style={{ color: "#0e5b96" }}
          />
        </components.DropdownIndicator>
      )
    );
  };

  IndicatorSeparator = ({ innerProps }) => {
    return (
      <span
        style={{
          alignSelf: "stretch",
          backgroundColor: "#0e5b96",
          width: "2px",
          marginTop: 8,
          marginBottom: 8,
        }}
        {...innerProps}
      />
    );
  };

  Option = (props) => {
    const { innerProps, innerRef } = props;
    return (
      <div ref={innerRef} {...innerProps}>
        <div
          className="text-left ml-2 pl-2 mt-1 select-option"
          style={{
            color: `${props.data.color ? `${props.data.color}` : "#112648"}`,
            fontWeight: "400",
            cursor: "pointer",
          }}
        >
          {this.props.isMulti && (
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => {}}
            />
          )}
          &nbsp;&nbsp;
          {props.data.label}
        </div>
      </div>
    );
  };

  MultiValue = (props) => {
    let labelToBeDisplayed = `${props.data.label} `;
    if (props.data.value === allOption.value) {
      labelToBeDisplayed = "All selected";
    }
    return (
      <components.MultiValue {...props}>
        <span>{labelToBeDisplayed}</span>
      </components.MultiValue>
    );
  };

  ValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {children[0].props.data
            ? children[0].props.data.value == allOption.value &&
              children[0].props.in
              ? children[0]
              : children
            : children}
          {/* {children} */}
        </components.ValueContainer>
      )
    );
  };

  MenuList = (props) => {
    let { optionsHeading, required } = this.props;
    return (
      <components.MenuList {...props}>
        <div className={`text-center font-weight-bold color-primary-light`}>
          {optionsHeading && `${optionsHeading}${required ? "*" : ""}`}
        </div>
        {props.children}
      </components.MenuList>
    );
  };

  formatGroupLabel = (data) => {
    let Label;
    switch (data.label) {
      case "productCategories":
        Label = "product categories";
        break;
      case "orderItems":
        Label = "order items";
        break;
      case "orderSources":
        Label = "order sources";
        break;
      case "orderStatuses":
        Label = "order statuses";
        break;
      case "paymentMethods":
        Label = "payment methods";
        break;
      default:
        Label = data.label;
        break;
    }
    return (
      <div style={groupStyles}>
        <span>{Label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    );
  };

  render() {
    let {
        withOutIcon,
        withoutBorder,
        controlHeight,
        containerHeight,
        controlWidth,
        paddingLeft,
        placeHolder,
        creatable,
        isMulti,
        async,
        options,
        allowSelectAll,
        selectedValue,
        defaultValue,
        loadingMessage,
        noOptionsMessage,
        minCharLength, //only for async
        required,
        isDisabled,
        isOptionDisabled,
        isClearable,
        className,
      } = this.props,
      { isLoading } = this.state;

    const selectStyles = {
      container: (base) => ({
        ...base,
        paddingLeft: withOutIcon ? 0 : 32,
      }),
      control: (base, state) => {
        return {
          ...base,
          borderColor: state.isFocused
            ? "#ddd"
            : required
            ? selectedValue !== null
              ? "#ddd"
              : "red"
            : "#ddd",
          borderRadius: "0px",
          borderTop: withoutBorder && (state.isFocused ? "none" : "none"),
          borderLeft: withoutBorder && (state.isFocused ? "none" : "none"),
          borderRight: withoutBorder && (state.isFocused ? "none" : "none"),
          minHeight: "25px",
          // maxHeight: '35px',
          height: controlHeight,
          width: controlWidth,
        };
      },
      valueContainer: (base, state) => {
        return {
          ...base,
          paddingLeft: withOutIcon ? 7 : paddingLeft,
          fontWeight: 400,
          borderRadius: "0px",
          height: containerHeight,
        };
      },
      multiValue: (base, state) => ({
        ...base,
        background: "#031e33",
        color: "white",
        maxWidth: "110px",
        // width: '40px',
        borderRadius: "3px",
      }),
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "white",
      }),
      multiValueRemove: (base) => ({
        ...base,
        background: "#bf1515",
        color: "#031e33",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "3px",
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "3px",
      }),
      menu: (base) => ({
        ...base,
        zIndex: 11111,
      }),
    };

    // console.log(selectedValue)
    if (creatable) {
      return (
        <CreatableSelect
          closeMenuOnSelect={!isMulti}
          value={selectedValue}
          defaultValue={defaultValue}
          onChange={this.handleChange}
          onCreateOption={this.handleCreate}
          // components={{
          //     Option: this.Option, DropdownIndicator: this.DropdownIndicator, IndicatorSeparator: this.IndicatorSeparator, ValueContainer: this.ValueContainer,
          //     MultiValue: this.MultiValue, MenuList: this.MenuList
          // }}
          components={this.animatedComponents}
          options={options ? options : []}
          isMulti={isMulti}
          hideSelectedOptions={false}
          styles={selectStyles}
          placeholder={
            <small className="font-weight-bold color-primary-light">
              {placeHolder}
              {required && "*"}
            </small>
          }
          isClearable={isClearable}
          isSearchable
          isOptionDisabled={isOptionDisabled}
          allowSelectAll={allowSelectAll}
          isLoading={isLoading}
          isDisabled={isLoading || isDisabled}
          className={className}
        />
      );
    } else if (async) {
      return (
        <AsyncSelect
          closeMenuOnSelect={!isMulti}
          value={selectedValue}
          defaultValue={defaultValue}
          onChange={this.handleChange}
          components={this.animatedComponents}
          options={
            options && options.length > 0
              ? allowSelectAll
                ? [allOption, ...options]
                : options
              : []
          }
          isMulti={isMulti}
          hideSelectedOptions={false}
          styles={selectStyles}
          placeholder={
            <small className="font-weight-bold color-primary-light">
              {placeHolder}
              {required && "*"}
            </small>
          }
          isClearable={isClearable}
          isSearchable
          isOptionDisabled={isOptionDisabled}
          allowSelectAll={allowSelectAll}
          isDisabled={isDisabled}
          cacheOptions
          // defaultOptions
          loadOptions={this.loadOptions}
          onInputChange={(newValue) => {
            this.setState({ inputValue: newValue });
          }}
          loadingMessage={() => (
            <small className="color-primary font-light-bold float-left">
              {minCharLength
                ? this.state.inputValue.length < minCharLength
                  ? `Please enter ${minCharLength} or more chracters`
                  : loadingMessage
                : loadingMessage}
            </small>
          )}
          noOptionsMessage={() => (
            <small className="color-primary font-light-bold float-left">
              {minCharLength
                ? this.state.inputValue.length < minCharLength
                  ? `Please enter ${minCharLength} or more chracters`
                  : noOptionsMessage
                : noOptionsMessage}
            </small>
          )}
        />
      );
    } else
      return (
        <Select
          closeMenuOnSelect={!isMulti}
          value={selectedValue}
          defaultValue={defaultValue}
          onChange={this.handleChange}
          // components={{
          //     Option: this.Option, DropdownIndicator: this.DropdownIndicator, IndicatorSeparator: this.IndicatorSeparator, ValueContainer: this.ValueContainer,
          //     MultiValue: this.MultiValue, MenuList: this.MenuList
          // }}
          components={this.animatedComponents}
          formatGroupLabel={this.formatGroupLabel}
          // options={options ? options : entityOptions ? entityOptions : []}
          options={
            options && options.length > 0
              ? allowSelectAll
                ? [allOption, ...options]
                : options
              : []
          }
          isMulti={isMulti}
          hideSelectedOptions={false}
          styles={selectStyles}
          placeholder={
            <small className="font-weight-bold color-primary-light">
              {placeHolder}
              {required && "*"}
            </small>
          }
          isClearable={isClearable}
          isSearchable
          isOptionDisabled={isOptionDisabled}
          allowSelectAll={allowSelectAll}
          isDisabled={isDisabled}
          className={className}
        />
      );
  }
}

const allOption = {
  label: "Select all",
  value: "*",
};

export default MyOwnSelect;

// import React, { } from 'react';
// import { Dropdown } from 'primereact/dropdown';
// import { MultiSelect } from 'primereact/multiselect';

// const MyOwnSelect = (props) => {
//     let { placeHolder, options, selectedValue, handleChange, isMulti, filter, className, disabled, required } = props

//     return isMulti ?
//         (
//             <MultiSelect
//                 placeholder={<small style={{ fontWeight: '700' }} className="color-primary-light">{placeHolder}{required && '*'}</small>}
//                 options={options}
//                 value={selectedValue}
//                 onChange={handleChange}
//                 filter={filter}
//                 disabled={disabled}
//                 required={required}
//                 className={`${className} w-100 text-left`}
//             />
//         )
//         :
//         (
//             <Dropdown
//                 placeholder={<small style={{ fontWeight: '700' }} className="color-primary-light">{placeHolder}{required && '*'}</small>}
//                 options={options}
//                 value={selectedValue}
//                 onChange={handleChange}
//                 style={{ width: '100%' }}
//                 filter={filter}
//                 disabled={disabled}
//                 required={required}
//                 className={`${className} w-100 text-left`}
//             />
//         )

// }

// export default MyOwnSelect
