export const addCourseReview = async (courseReviewData) => {
  console.log(courseReviewData);
  const response = await fetch("/api/post-course-review", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify({
      student_id: courseReviewData.studentId,
      course_id: courseReviewData.courseId,
      rating: courseReviewData.rating,
      review_text: courseReviewData.reviewText,
    }),
  });
  const json = await response.json();
  //   console.log(json);
  return json;
};
export const getAllCourseReviews = async () => {
  const response = await fetch("/api/get-course-reviews", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  //   console.log(json);
  return json;
};
