import React, { useEffect, useState } from "react";
import "../styles/InstructorProfile.css";
import BasicInputFields from "./misc/BasicInputFields";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { InputText } from "primereact/inputtext";
import { useAuth } from "../contexts/AuthContext";
import { getUser, updateUser } from "../services/users";
import { Can } from "../configs/Ability-context";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

// import axios from 'axios';

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const StudentAvailability = ({ availability, setAvailability, readOnly }) => {
  // const [availability, setAvailability] = useState(
  //   daysOfWeek.map((day) => ({
  //     day,
  //     available: false,
  //     from: "15:00",
  //     to: "20:00",
  //   }))
  // );

  const handleCheckboxChange = (index) => {
    if (readOnly === true) {
      return;
    }
    // const newAvailability = availability.filter((availability) => {
    //   return availability.NotAvailable === false;
    // });
    const newAvailability = [...availability];
    newAvailability[index].available = !newAvailability[index].available;

    setAvailability(newAvailability);
  };

  const handleTimeChange = (index, field, value) => {
    if (readOnly === true) {
      return;
    }
    const newAvailability = [...availability];
    newAvailability[index][field] = value;
    setAvailability(newAvailability);
  };

  const handleSubmit = async () => {
    try {
      //   await axios.post('/api/availability', { availability });
      //   await fetch("/api/availability", { availability });
      console.log(availability);
      alert("Availability saved successfully!");
    } catch (error) {
      console.error("Error saving availability", error);
      alert("Failed to save availability");
    }
  };

  return (
    <div>
      <p className="fs-5">Select Your Availability</p>
      {availability.map((slot, index) => (
        <div key={index} style={{ marginBottom: "10px" }}>
          <label>
            <input
              type="checkbox"
              className="instructor-input-field mx-2"
              checked={slot.available}
              onChange={() => handleCheckboxChange(index)}
            />
            {slot.day}
            {/* {slot.day} NotAvailable */}
          </label>
          <div className=" ">
            from:
            <input
              type="time"
              className="instructor-input-field p-2 mx-2"
              value={slot.from}
              disabled={!slot.available}
              onChange={(e) => handleTimeChange(index, "from", e.target.value)}
            />
            to:
            <input
              type="time"
              className="instructor-input-field p-2 mx-2"
              value={slot.to}
              disabled={!slot.available}
              onChange={(e) => handleTimeChange(index, "to", e.target.value)}
            />
          </div>
        </div>
      ))}
      {/* <button onClick={handleSubmit}>Save Availability</button> */}
    </div>
  );
};

const StudentProfile = () => {
  const deobfuscate = (value) => atob(value); // Base64 decode
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Userid = queryParams.get("userId");

  const readOnlyParam = queryParams.get("readonly");
  const [readOnly, setReadOnly] = useState(
    deobfuscate(readOnlyParam) === "true"
  );

  const [editedDocumentPath, setEditedDocumentPath] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [editingPrfileImage, setEditingProfileImage] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  // const [editingDocument, setEditingDocument] = useState({
  //   profile_img: false,
  // });
  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);
  const [studentDetails, setStudentDetails] = useState({
    name: "",
    email: "",
    phone: "",
    gender: "",
    address: "",
    profile_img: "",
    postcode: "",
    provisional_license_number: "",
  });

  const [weekAvailability, setWeekAvailability] = useState(
    daysOfWeek.map((day) => ({
      day,
      available: false,
      from: "15:00",
      to: "20:00",
    }))
  );
  const handleImageClick = (imagePath) => {
    const imageUrl = `/uploads/${imagePath}`;
    setSelectedImage(imageUrl);
    setIsImageModalVisible(true);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;

    if (file) {
      setStudentDetails((prevDetails) => ({
        ...prevDetails,
        [name]: file,
      }));
      setEditedDocumentPath(file?.name);
      setPreviewUrl(URL.createObjectURL(file));
    } else {
      console.log(`No file selected for ${name}`);
      setEditedDocumentPath("");
      setPreviewUrl(null);
    }
  };
  const renderProfileImage = (documentPath, inputName, label) => {
    let hasDocument = false;

    // Check if documentPath is a string and not empty
    if (typeof documentPath === "string" && documentPath.trim() !== "") {
      hasDocument = true;
    }

    // If documentPath is a File object, use the file's name or a different condition
    if (documentPath instanceof File) {
      hasDocument = true; // Treat it as having a document
    }
    return (
      // <div className="instructor-input-column ">
      <div
        className="d-flex flex-column p-2 justify-content-center"
        style={{ minWidth: "200px" }}
      >
        <label className="instructor-form-input-label ">{label}</label>
        {hasDocument && !editingPrfileImage ? (
          <div className="d-flex flex-row justify-content-center ">
            <div
              className="p-2"
              style={{
                borderRadius: "50%",
                backgroundColor: "#f8f9fa",
                // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <img
                src={previewUrl || `/uploads/${documentPath}`}
                // src={`/uploads/${documentPath}`}
                alt="Profile"
                style={{
                  objectFit: "cover",
                  height: "100px",
                  width: "100px",
                }}
              />
            </div>
            {!readOnly && (
              <div className="d-flex flex-column justify-content-end px-2 mx-2">
                <i
                  className="pi pi-pencil cursor-pointer"
                  onClick={() => setEditingProfileImage(true)}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="d-flex  justify-content-center align-items-center pt-3">
            <label className="custom-file-input">
              <input
                className="file-input-hidden"
                type="file"
                name={inputName}
                onChange={handleFileChange}
              />
              <span className="file-name-display">
                {documentPath && typeof documentPath === "string"
                  ? `${documentPath
                      .split("-")
                      .slice(1)
                      .join("-")} - Click To Replace File`
                  : editedDocumentPath
                  ? editedDocumentPath
                  : "No file chosen"}
              </span>
            </label>
            <div className="d-flex flex-column justify-content-end px-2 mx-2 bg-light py-2">
              <i
                className="pi pi-times cursor-pointer"
                onClick={() => {
                  setEditingProfileImage(false);
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };
  const renderDocumentInputOrImage = (documentPath, inputName, label) => {
    // const hasDocument = documentPath && documentPath.trim() !== "";
    let hasDocument = false;

    // Check if documentPath is a string and not empty
    if (typeof documentPath === "string" && documentPath.trim() !== "") {
      hasDocument = true;
    }

    // If documentPath is a File object, use the file's name or a different condition
    if (documentPath instanceof File) {
      hasDocument = true; // Treat it as having a document
    }
    return (
      <div className="instructor-input-column ">
        <label className="instructor-form-input-label">{label}</label>
        {hasDocument && !editingPrfileImage ? (
          <>
            <p
              onClick={() => handleImageClick(documentPath)}
              className="cursor-pointer text-decoration-underline"
              style={{ color: "navy" }}
            >
              View {label}
            </p>
            {!readOnly && (
              <Button
                type="button"
                className="background-quinary border-0"
                label={`Edit ${label}`}
                onClick={() => setEditingProfileImage(true)}
              />
            )}
          </>
        ) : (
          <>
            <input
              className="instructor-form-input"
              type="file"
              name={inputName}
              placeholder={inputName}
              onChange={handleFileChange}
            />
            <Button
              type="button"
              className="background-quinary border-0"
              onClick={() => {
                setEditingProfileImage(false);
              }}
              label="Exit Edit"
            />
          </>
        )}
      </div>
    );
  };
  const userId = Userid || JSON.parse(localStorage.getItem("ui"));
  const handleChange = (e) => {
    if (readOnly === true) {
      return;
    }
    setStudentDetails({
      ...studentDetails,
      [e.target.name]: e.target.value,
    });
    console.log({ [e.target.name]: e.target.value });
    console.log(studentDetails);
  };
  const validateLicenseNumber = (licenseNumber) => {
    // const regex = /^[A-Z0-9]{16}$/; // Adjust based on expected format
    const regex = /^[A-Z]{2}\d{6}[A-Z0-9]{8}$/;
    return regex.test(licenseNumber);
  };
  const handleUpdateStudentProfile = async (e) => {
    e.preventDefault();
    console.log(studentDetails);
    console.log(weekAvailability);
    if (!validateLicenseNumber(studentDetails.provisional_license_number)) {
      toast.error("Invalid Provisional Licence Number");
      return;
    }
    const response = await updateUser(
      userId,
      studentDetails,
      weekAvailability
    ).then((res) => {
      console.log(res);
      if (res.success === true) {
        // alert("Profile updated successfully");
        toast.success("Profile updated successfully");
        window.location.reload();
        setEditingProfileImage(false);
        setRefreshData(!refreshData);
      } else {
        alert("Profile update failed");
      }
    });
    console.log(response);
  };
  const handleCloseImageModal = () => {
    setSelectedImage(null);
    setIsImageModalVisible(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getUser(userId);
      const {
        name,
        email,
        phone,
        gender,
        address,
        profile_img,
        postcode,
        availability,
        provisional_license_number,
      } = data;
      setStudentDetails({
        name,
        email,
        phone,
        gender,
        address,
        profile_img,
        postcode,
        provisional_license_number,
      });
      setWeekAvailability(availability || weekAvailability); // Ensure availability is handled correctly
    };
    if (userId) fetchData();
  }, [userId, refreshData]);
  return (
    <Can I="manage" a="studentProfile">
      <div className="container p-3" id="main-instructor-profile-container">
        <h2 className="text-center">Student Profile</h2>
        <form onSubmit={handleUpdateStudentProfile}>
          <BasicInputFields
            renderDocumentInputOrImage={renderDocumentInputOrImage}
            handleChange={handleChange}
            details={studentDetails}
            renderProfileImage={renderProfileImage}
          />
          <div className="instructor-input-column col-md-3">
            <label className="instructor-form-input-label">
              Provisional License Number{" "}
            </label>
            <input
              className="instructor-form-input"
              name="provisional_license_number"
              type="text"
              maxlength="16"
              pattern="[A-Za-z0-9]{16}"
              placeholder="Enter Provisional License Number"
              value={studentDetails.provisional_license_number || ""}
              onChange={handleChange}
            />
          </div>
          <StudentAvailability
            readOnly={readOnly}
            availability={weekAvailability}
            setAvailability={setWeekAvailability}
          />

          {readOnly !== true && (
            <button
              type="submit"
              className="instructor-profile-submit-button"
              // onClick={handleUpdateStudentProfile}
              // style={{
              //   backgroundColor: "black",
              //   color: "white",
              //   borderRadius: "20px",
              // }}
            >
              SUBMIT
            </button>
          )}

          {/* <button type="submit">Update</button> */}
        </form>{" "}
        <Dialog
          header="View Image"
          visible={isImageModalVisible}
          onHide={handleCloseImageModal}
          modal
        >
          <img src={selectedImage} alt="Profile" style={{ width: "100%" }} />
        </Dialog>
      </div>
    </Can>
  );
};

export default StudentProfile;
