import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // for interaction
import "../../styles/CalendarComponent.css"; // Import your updated CSS

export const CalendarView = ({ lessons, onRescheduleClick }) => {
  const events = lessons
    .filter((lesson) => !isNaN(new Date(lesson.date).getTime())) // Ensure valid dates
    .map((lesson) => {
      const startDate = new Date(lesson.date);
      const endDate = new Date(startDate.getTime() + lesson.duration * 60000);

      return {
        id: lesson.id,
        title: "", // Empty title to avoid default text
        start: startDate.toISOString(), // Convert to ISO string
        end: endDate.toISOString(), // Convert to ISO string
        extendedProps: {
          lessonDetails: lesson, // Any additional data you want to pass
        },
      };
    });
  const renderEventContent = (eventInfo) => {
    const { event } = eventInfo;
    const lesson = event.extendedProps.lessonDetails;
    const lessonsForDay = lessons.filter(
      (l) =>
        new Date(l.date).toDateString() === new Date(event.start).toDateString()
    );

    const lessonIndex = lessonsForDay.findIndex((l) => l.id === lesson.id) + 1; // Find the index of the lesson

    return (
      <div className="mx-4">
        {eventInfo.view.type === "dayGridMonth" ? (
          <div
            style={{
              width: "15px",
              height: "15px",
              backgroundColor: "#FFB703",
              borderRadius: "50%",
              display: "inline-block",
              margin: "auto",
            }}
          />
        ) : (
          <div className=" cursor-pointer">
            {`Lesson ${lessonIndex}`} {/* Display the lesson index */}
          </div>
        )}
      </div>
    );
  };
  // const renderEventContent = (eventInfo) => {
  //   const { event } = eventInfo;
  //   const lesson = event.extendedProps.lessonDetails;
  //   const lessonsForDay = lessons.filter(
  //     (l) =>
  //       new Date(l.date).toDateString() === new Date(event.start).toDateString()
  //   );
  //   // Display a dot or a number of lessons
  //   return (
  //     <div className=" mx-4">
  //       {eventInfo.view.type === "dayGridMonth" ? (
  //         <div
  //           style={{
  //             width: "15px",
  //             height: "15px",
  //             backgroundColor: "#007bff", // Vibrant color for dots
  //             borderRadius: "50%",
  //             display: "inline-block",
  //             margin: "auto",
  //           }}
  //         />
  //       ) : (
  //         <div>
  //           {lessonsForDay.length} Lessons
  //           {/* {lessonsForDay.map((_, index) => `Lesson ${index + 1}`).join(", ")} */}
  //         </div>
  //         /* `Lesson ${
  //           lessons.filter(
  //             (l) =>
  //               new Date(l.date).toDateString() ===
  //               new Date(event.start).toDateString()
  //           ).length
  //         } ` */
  //       )}
  //     </div>
  //   );
  // };

  const handleEventClick = ({ event }) => {
    const lesson = event.extendedProps.lessonDetails;
    onRescheduleClick(lesson);
  };

  return (
    <div className="fullcalendar-container">
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        eventContent={renderEventContent} // Custom rendering
        eventClick={handleEventClick} // Handle click events
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,dayGridWeek,dayGridDay",
        }}
        editable={false} // Disable dragging/resizing
      />
    </div>
  );
};

// import React from "react";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { Panel } from "primereact/panel";
// import { Button } from "primereact/button";
// import "../../styles/CalendarComponent.css";

// export const CalendarView = ({ lessons, onRescheduleClick }) => {
//   const groupedLessons = (lessons || []).reduce((acc, lesson) => {
//     const date = lesson.date;
//     if (!acc[date]) acc[date] = [];
//     acc[date].push(lesson);
//     return acc;
//   }, {});

//   return (
//     <div className="calendar-view">
//       {Object.keys(groupedLessons).map((date) => (
//         <Panel header={date} key={date} toggleable>
//           <DataTable value={groupedLessons[date]}>
//             <Column field="startTime" header="Start Time"></Column>
//             <Column field="duration" header="Duration (min)"></Column>
//             <Column
//               header="Actions"
//               body={(rowData) => (
//                 <Button
//                   label="Reschedule"
//                   icon="pi pi-pencil"
//                   onClick={() => onRescheduleClick(rowData)}
//                 />
//               )}
//             ></Column>
//           </DataTable>
//         </Panel>
//       ))}
//     </div>
//   );
// };

// import React from "react";
// // import { FullCalendar } from "primereact/fullcalendar";
// import { Button } from "primereact/button";

// export const CalendarView = ({ lessons, onRescheduleClick }) => {
//   const calendarOptions = {
//     plugins: ["dayGrid", "timeGrid", "interaction"],
//     headerToolbar: {
//       left: "prev,next today",
//       center: "title",
//       right: "dayGridMonth,timeGridWeek,timeGridDay",
//     },
//     events: lessons.map((lesson) => ({
//       id: lesson.id,
//       title: `Lesson with ${lesson.studentId}`,
//       start: `${lesson.date}T${lesson.startTime}`,
//       end: `${lesson.date}T${addMinutes(lesson.startTime, lesson.duration)}`,
//       extendedProps: lesson,
//     })),
//     eventClick: ({ event }) => onRescheduleClick(event.extendedProps),
//   };

//   return < options={calendarOptions} />;
// };

// const addMinutes = (time, minsToAdd) => {
//   const [hours, minutes] = time.split(":").map(Number);
//   const totalMinutes = hours * 60 + minutes + minsToAdd;
//   const newHours = Math.floor(totalMinutes / 60);
//   const newMinutes = totalMinutes % 60;
//   return `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(
//     2,
//     "0"
//   )}`;
// };
