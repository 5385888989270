import React, { useState, useEffect, useRef } from "react";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import { NewCustomListing } from "../Components/custom/NewCustomListing";
import { Can } from "../configs/Ability-context";
import { getPermissions } from "../services/permissions";

const AllPermissions = (props) => {
  let dt = useRef(null);
  let navigate = useNavigate();

  const [
    {
      allPermissions,
      isLoading,
      refreshData,
      selectedAction,
      selectedSubject,
      selectedEnabled,
    },
    setState,
  ] = useState({
    allPermissions: [],
    isLoading: false,
    refreshData: false,
    selectedAction: null,
    selectedSubject: null,
    selectedEnabled: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      changeValue("isLoading", true);
      const { data } = await getPermissions();
      setState((prevState) => ({
        ...prevState,
        allPermissions: data,
        isLoading: false,
      }));
    };
    fetchData();
  }, [refreshData]);

  const changeValue = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleEdit = (id) => (e) => {
    navigate(`/admin/users/permissions/edit/${id}`);
  };

  let allActions = allPermissions.map((permission) => permission.action);
  let allSubjects = allPermissions.map((permission) => permission.subject);

  let actionOptions = allActions.length
    ? [...new Set(allActions)]
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        .map((option, index) => ({
          key: index + 1,
          label: option,
          value: option,
        }))
    : [];
  let subjectOptions = allSubjects.length
    ? [...new Set(allSubjects)]
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        .map((option, index) => ({
          key: index + 1,
          label: option,
          value: option,
        }))
    : [];

  const actionFilter = (
    <Dropdown
      value={selectedAction}
      options={actionOptions}
      onChange={(e) => {
        dt.current.filter(e.value, "action", "equals");
        changeValue("selectedAction", e.value);
      }}
      placeholder="Select a permission"
      className="p-column-filter"
      showClear
    />
  );
  const subjectFilter = (
    <Dropdown
      value={selectedSubject}
      options={subjectOptions}
      onChange={(e) => {
        dt.current.filter(e.value, "subject", "equals");
        changeValue("selectedSubject", e.value);
      }}
      placeholder="Select a subject"
      className="p-column-filter"
      showClear
    />
  );
  const enabledFilter = (
    <Dropdown
      value={selectedEnabled}
      options={[
        { label: "Enabled", value: true },
        { label: "Disabled", value: false },
      ]}
      onChange={(e) => {
        dt.current.filter(e.value, "enabled", "equals");
        changeValue("selectedEnabled", e.value);
      }}
      placeholder="Enabled/Disabled"
      className="p-column-filter"
      showClear
    />
  );

  const enabledBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Enabled</span>
        <span className={`pi ${rowData.enabled ? "pi-check" : "pi-times"}`} />
      </React.Fragment>
    );
  };

  let columns = [
    {
      field: "subject",
      header: "Subject",
      sortable: true,
      filter: true,
      selectedByDefault: true,
      filterElement: subjectFilter,
    },
    {
      field: "slug",
      header: "Slug",
      sortable: true,
      filter: true,
      selectedByDefault: true,
      filterPlaceholder: "Search by slug",
      filterMatchMode: "contains",
    },
    {
      field: "enabled",
      header: "Enabled",
      body: enabledBodyTemplate,
      filter: true,
      selectedByDefault: true,
      filterElement: enabledFilter,
    },
  ];

  return (
    // <Can I="read" a="permissions">
    <>
      {/* <h2>{"All Permissions"}</h2> */}
      <NewCustomListing
        allRecords={allPermissions}
        heading={"Permissions"}
        firstColumn={
          <Column
            field="action"
            header="Permission"
            sortable={true}
            filter={true}
            style={{ borderBottom: "1px solid grey" }}
            filterElement={actionFilter}
            headerStyle={{ width: "170px" }}
          />
        }
        columns={columns}
        permissionSubject={"permissions"}
        isLoading={isLoading}
        handleRefresh={() => {
          changeValue("refreshData", !refreshData);
        }}
        handleEdit={true}
        customEditButton={(customButton, rowData) => {
          return (
            !(rowData.action === "manage" && rowData.subject === "all") &&
            customButton("pi pi-pencil", handleEdit(rowData.id), false, "edit")
          );
        }}
        tableSmall
        tableRef={dt}
        timeStamps={true}
      />
    </>

    /* </Can/> */
  );
};

export default AllPermissions;
