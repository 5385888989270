import React from "react";

const BasicInputFields = ({
  handleChange,
  details,
  renderDocumentInputOrImage,
  renderProfileImage,
}) => {
  return (
    <div>
      <div className=" instructor-input-row justify-content-md-start-sm-center">
        {renderProfileImage(
          details.profile_img,
          "profile_img",
          "Profile Picture"
        )}
      </div>
      <div className=" instructor-input-row">
        <div className="instructor-input-column">
          <label className="instructor-form-input-label">Name</label>
          <input
            className="instructor-form-input"
            name="name"
            type="text"
            placeholder="Enter Name"
            value={details.name || ""}
            onChange={handleChange}
          />
        </div>
        <div className="instructor-input-column">
          <label className="instructor-form-input-label">Email</label>
          <input
            className="instructor-form-input"
            type="email"
            name="email"
            placeholder="Enter Email"
            value={details.email || ""}
            onChange={handleChange}
            disabled={details.email}
          />
        </div>
        <div className="instructor-input-column">
          <label className="instructor-form-input-label">Phone</label>
          <input
            className="instructor-form-input"
            type="tel"
            name="phone"
            placeholder="Enter Phone"
            value={details.phone || ""}
            onChange={handleChange}
          />
        </div>
        <div className="instructor-input-column">
          <label className="instructor-form-input-label">Gender</label>
          <select
            className="instructor-form-input"
            name="gender"
            value={details.gender || ""}
            onChange={handleChange}
            required
          >
            <option value="" disabled hidden>
              Select Gender
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div>
      </div>
      <div className="instructor-input-row">
        {/* {renderProfileImage(
          details.profile_img,
          "profile_img",
          "Profile Picture"
        )} */}
        {/* {renderDocumentInputOrImage(
          details.profile_img,
          "profile_img",
          "Profile Picture"
        )} */}
        <div className="instructor-input-column">
          <label className="instructor-form-input-label">Postal Code</label>
          <input
            className="instructor-form-input"
            type="text"
            name="postcode"
            placeholder="Postal Code"
            value={details.postcode || ""}
            onChange={handleChange}
          />
        </div>
        <div className="instructor-input-column">
          <label className="instructor-form-input-label">Full Address</label>
          <input
            className="instructor-form-input"
            type="text"
            name="address"
            placeholder="Full Address"
            value={details.address || ""}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicInputFields;

// import React from "react";

// const BasicInputFields = ({
//   handleChange,
//   userEmail,
//   details,
//   initialdetails,
// }) => {
//   return (
//     <div>
//       <div className=" instructor-input-row">
//         <div className="instructor-input-column">
//           <label className="instructor-form-input-label">Name</label>
//           <input
//             className="instructor-form-input"
//             name="name"
//             type="text"
//             placeholder="Enter Name"
//             // defaultValue={initialdetails.name ? initialdetails.name : ""}
//             value={details.name}
//             onChange={handleChange}
//           />
//         </div>
//         <div className="instructor-input-column">
//           <label className="instructor-form-input-label">Email</label>
//           <input
//             className="instructor-form-input"
//             type="email"
//             name="email"
//             // placeholder="Enter Email"
//             // defaultValue={initialdetails.email ? initialdetails.email : ""}
//             value={userEmail}
//             disabled
//             // onChange={handleChange}
//           />
//         </div>
//         <div className="instructor-input-column">
//           <label className="instructor-form-input-label">Phone</label>
//           <input
//             className="instructor-form-input"
//             type="tel"
//             name="phone"
//             // defaultValue={initialdetails.phone ? initialdetails.phone : ""}
//             placeholder="Enter Phone"
//             value={details.phone}
//             onChange={handleChange}
//           />
//         </div>
//         <div className="instructor-input-column">
//           <label className="instructor-form-input-label">Gender</label>
//           <select
//             className="instructor-form-input"
//             name="gender"
//             value={details.gender}
//             onChange={handleChange}
//             required
//           >
//             <option value="" disabled hidden>
//               Select Gender
//             </option>
//             <option value="male">Male</option>
//             <option value="female">Female</option>
//           </select>
//         </div>
//       </div>
//       <div className="instructor-input-row">
//         <div className="instructor-input-column">
//           <label className="instructor-form-input-label">Postal Code</label>
//           <input
//             className="instructor-form-input"
//             type="text"
//             name="postcode"
//             // defaultValue={
//             //   initialdetails.postcode ? initialdetails.postcode : ""
//             // }
//             placeholder="Postal Code"
//             value={details.postcode}
//             onChange={handleChange}
//           />
//         </div>
//         <div className="instructor-input-column">
//           <label className="instructor-form-input-label">Full Address</label>
//           <input
//             className="instructor-form-input"
//             type="text"
//             name="address"
//             placeholder="Full Address"
//             value={details.address}
//             onChange={handleChange}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BasicInputFields;
