import React from "react";
import { Helmet } from "react-helmet-async";

const TermsAndConditions = () => {
  return (
    <div className="terms-conditions container">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <h1>Terms and Conditions</h1>
      <p>
        The vast majority of courses go without a hitch, but please read and
        understand the Terms and Conditions below prior to booking, so we both
        know where we stand and how the course works.
      </p>
      <p>
        All bookings are accepted on the STRICT understanding that the pupil has
        read, understood and accepted the terms and conditions outlined below.
        By booking a course of driving lessons with Pass Swiftly Ltd intensive
        driving courses UK you are indicating that you have read and understood
        the terms and conditions and that you agree to be bound by them.
      </p>
      <ul>
        <li>‘you’ means the person named on the booking form as the pupil</li>
        <li>‘we’ ‘us’ means The self-employed instructor</li>
        <li>
          ‘the driving school’ ‘Pass Swiftly Ltd’ means the driving school
          administering the provision of the driving course, booking the tests
          and allocating you to a self-employed subcontractor who is a
          registered DVSA driving instructor.
        </li>
        <li>
          ‘the instructor’ means a driving instructor acting on his/her own or
          on behalf of a driving school when providing the course
        </li>
      </ul>
      <h2>Terms & Conditions</h2>
      <ol>
        <li>
          Each course is for the total amount of hours booked. The total hours
          of the course booked includes the time required for taking your
          practical test and drive home at the end of the course. Additional
          hours can be booked with your instructor at the agreed hourly rate
          (this can be agreed between you the pupil and your instructor, on
          average it’s around £45 per hour, but this is subject to area and the
          instructor’s private rates), we cannot be held accountable for any
          additional lessons with you and your instructor, this becomes a
          private agreement between you and the instructor, if you require a new
          instructor after completing all your pre booked hours, you would have
          to book a new course with us. Also please note: No courses are
          guaranteed to be completed over one week and the larger courses 20
          hours or more may be completed over two weeks.
        </li>
        <li>
          If transport to the theory test centre is required during a lesson,
          this will form part of your lesson time, from when you arrive in the
          car until we drop you off home after the theory test. Transport to the
          theory centre will be arranged only if requested, as your instructor
          will need to make sure the lesson times sync up with the test time.
        </li>
        <li>
          All pupils must pass the legally required eyesight test (20.5m or 67
          ft). This will normally be conducted by your driving instructor on the
          first lesson, should you fail the eyesight check the course would have
          to be abandoned until you have corrected your eyesight. Any agreed
          hours booked in with the instructor would be lost.
        </li>
        <li>
          If your instructor feels that you are not ready to take your practical
          driving test at the end of the course, due to safety reasons, then you
          the pupil will not be allowed to take your driving test. If the test
          cannot be cancelled due to the time frame then you will lose your test
          fee. The Instructor can offer to complete any booked hours as tuition
          only should the pupil choose, if not they will be forfeited and cannot
          be rescheduled for a later date. If a test date can still be cancelled
          and the remaining hours are not yet booked the same applies. The
          Instructor is not obliged to make a refund of the hours and can offer
          to complete the remainder as tuition if the pupil chooses to do so, if
          not then those hours will be forfeited and will not be offered a
          refund of any unused hours. If the test is cancelled then a refund of
          the fee can be arranged. This does not affect any deposit amount paid
          which will be non-refundable.
        </li>
        <li>
          The prices quoted on the course guide include the test fees required
          for your course, which we book and organise for you.
        </li>
        <li>
          <strong>Start Dates and Deposit Refunds:</strong> All deposits made
          online are non-refundable, as when we receive the booking, we are
          already carrying out work to allocate a driving instructor, which
          includes tuition and car hire for your lessons and driving test ( as
          per reg. 28 of the Consumer Contracts Regulations 2013). Should we
          fail to allocate you to a driving instructor before your estimated
          completion date, you will be informed. Regarding the estimated
          completion date we offer online, this is based on what tests we are
          currently booking. However, this is estimated and tests can fluctuate
          depending on area and test centre. We are just giving you a rough idea
          of what to expect, if the test is later than expected, then please
          bear in mind that this is the soonest date we could find in your area
          at the time of booking. No refunds will be offered if you are unhappy
          with the test date. Also, if you fail to pass your theory test, this
          could delay us allocating you a driving instructor, same goes if there
          is an issue with your driving license, i.e. the details are incorrect
          and you haven’t contacted us to update these details. This could delay
          the allocation of a driving instructor and therefore we could refuse a
          refund of the deposit.
          <strong>Partial Refund:</strong> We may offer a partial refund on
          courses if we have failed to allocate a driving instructor but we have
          booked you a driving test which you want to keep. The admin fee on
          booking a standard driving test (£62) is £120. So we would keep £150
          in these cases and refund the difference. If you have booked an
          extended test then this the fee to keep the test in place is £212 as
          the extended test is (£124).
        </li>
        <li>
          We will automatically search for the following week to complete your
          booking if we are unsuccessful in booking you in for the first choice
          week. If an instructor makes contact with you and you agree on a
          schedule for any following week, the deposit would become
          non-refundable as the course is successfully booked in. The reason
          that we cannot refund a deposit once it is allocated is that driving
          instructors are self-employed businessmen and women and cancellation
          of the course leaves the driving instructor without any income for
          that week once we have already reserved them for you as requested on
          booking. Courses may be rescheduled for a later date that you can
          attend at the driving instructor’s discretion. If any payments have
          been made to the driving instructor for the booking of driving lessons
          We are unable to offer a refund if the 14 days have passed/waived and
          a driving instructor is in place to complete your lessons or test
          dates have been confirmed. If you cancel once a driving instructor is
          in place you may forfeit the entire course fee or at least some of it.
          For example, if you have completed more than 50% of your lessons, no
          refunds can be offered under any circumstance. However, if you have
          completed less than 50% of your lessons, we can offer you a refund of
          the remaining driving instructor fee or provide a new instructor for
          the remaining hours. Our deposit fee is non-refundable under any
          circumstances once the service has been provided as we accrue costs in
          doing so, we can only refund you for unused driving lessons at the
          agreement of the instructor. Once a refund request has been
          authorised, this will be processed immediately and you will receive
          this within 3-5 working days.
        </li>
        <li>
          Once a deposit has been received, the pupil has a period of 3 months
          to complete the course from the date that the pupil has selected on
          the booking form to begin the course, should we not hear from the
          pupil for a period of 3 months (e.g. if you have lost interest in
          completing the course) then any remaining deposit is forfeited and
          test fees lost.
        </li>
        <li>
          If the course is delayed due to no fault of Pass Swiftly Ltd such as
          an expired provisional licence, expired theory test, change of
          location or change of driving instructor (at pupil’s request) then
          this may be subject to a price increase when you start lessons. This
          is because you may have booked when prices were cheaper which means
          the driving instructor charges were cheaper, so if any delays are
          caused through the fault of the pupil and a new driving instructor is
          allocated or the previous instructors rates have increased, then Pass
          Swiftly Ltd will not cover this and you will have to pay the current
          going rate to the driving instructor. Also if a change is requested to
          the original booking then we will try to accommodate this. Changes can
          inevitably cause delays in allocating courses so a refund will not
          offered because of that. If the course takes longer to complete, no
          additional fees will be due as long as the pupil and driving
          instructor have confirmed the additional lessons.
        </li>
        <li>
          <strong>Driving test Cancellation/ Bad weather conditions</strong>:
          The DVSA stipulates that 3 working days’ notice must be given to
          cancel or re-arrange a test. If the DVSA cancels a driving test at
          short notice, i.e. within 3 working days, due to examiner sickness or
          bad weather, the DVSA will only provide the next available date and
          will not offer to compensate for any lessons lost or the test itself.
          Neither Pass Swiftly Ltd nor the driving instructor can be held
          responsible for any loss of hours for the course booked with the
          instructor. In such circumstances, any additional lessons required
          would have to be rescheduled and charged at the normal hourly rate. In
          this case, we will not provide a refund for the DVSA’s actions. You
          can of course write to the DVSA and claim out-of-pocket expenses
          should you wish to.
        </li>
      </ol>
    </div>
  );
};

export default TermsAndConditions;
