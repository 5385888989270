import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { getUsers } from "../../services/users";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "../../styles/JobAssignModal.css";

const JobAssignModal = (props) => {
  const { modalShow, hideModal, order, handleUpdate } = props;
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  const [allInstructors, setAllInstructors] = useState([]);
  const [hourlyRate, setHourlyRate] = useState(0);
  //   const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    const fetchInstructors = async () => {
      const { data } = await getUsers();
      //   setAllUsers(data);
      const instructors = data.filter(
        (user) => user?.role?.name === "instructor"
      );
      setAllInstructors(instructors);
      console.log(allInstructors);
    };
    fetchInstructors();
  }, []);
  useEffect(() => {
    if (selectedInstructor) {
      setHourlyRate(selectedInstructor.hourly_rate);
    } else {
      setHourlyRate(null); // or a default value like 0
    }
  }, [selectedInstructor]);
  const totalHours = order?.order_detail?.course?.hours;
  // const initialHourlyRate = selectedInstructor?.hourly_rate;
  // const [hourlyRate, setHourlyRate] = useState(initialHourlyRate);

  // let hourlyRate = selectedInstructor?.hourly_rate;
  const totalAmount = totalHours * hourlyRate;
  const handleAssign = async (e) => {
    e.preventDefault();
    console.log(selectedInstructor);
    await handleUpdate(order?.id, "instructor_id", selectedInstructor?.id);
    await handleUpdate(order?.id, "payable_to_instructor", totalAmount);
    await handleUpdate(order?.id, "status", "processing");
    hideModal();
  };
  return (
    <Dialog
      header="Assign To Instructor"
      visible={modalShow}
      style={{ width: "fit-content", height: "max-content" }}
      onHide={() => {
        hideModal();
        setSelectedInstructor(null);
      }}
      // onHide={() => setJobAssignModal(false)}
    >
      {/* {JSON.stringify(selectedInstructor)}
      {JSON.stringify(order.id)}
      {order?.order_detail?.course?.hours} */}
      {/* <form onSubmit={handleAssign}> */}
      <span>Total Order Amount: {order?.total} </span>
      <div>
        <p>
          Total Hours in the Course{" "}
          <span className="px-3" style={{ backgroundColor: "lightgrey" }}>
            {totalHours}
            {/* {order?.order_detail?.course?.hours} */}
          </span>
        </p>
        <div className="d-flex flex-wrap">
          <div className="jobAssign-modal-instructor-input-column">
            <label>Instructor</label>
            <Dropdown
              value={selectedInstructor}
              onChange={(e) => setSelectedInstructor(e.value)}
              options={allInstructors}
              optionLabel="name"
              placeholder="Select An Instructor"
              //   className="w-full md:w-14rem"
            />
          </div>{" "}
          <div
            className="jobAssign-modal-price-hour-input-column"
            // style={{  }}
          >
            <label>Price/hour</label>
            <InputText
              type="number"
              value={hourlyRate || 0}
              // value={hourlyRate || initialHourlyRate || 0}
              onChange={(e) => setHourlyRate(e.target.value)}
              //   className="instructor-form-input"
              //   className="w-full md:w-14rem"
            />
          </div>
          <div className="jobAssign-modal-payable-input-column">
            <label>Payable To Instructor</label>
            <InputText
              type="number"
              value={totalAmount || 0}
              disabled
              // onChange={(e) => setPrice(e.target.value)}
              //   className="w-full md:w-14rem"
              //   className="instructor-form-input"
            />
          </div>
        </div>
        <hr />
        <small>
          *Price/hour is auto fetched from the settings. You can Change it for
          this course.{" "}
        </small>
        <br />
        <div className=" d-flex justify-content-center align-items-center  mt-3">
          <Button
            disabled={!selectedInstructor}
            onClick={handleAssign}
            //   type="submit"
            className="w-auto text-center "
            label="Assign Now"
            style={{
              backgroundColor: "#219EBC",
              outline: "none",
              width: "max-content",
            }}
          />
        </div>
      </div>
      {/* </form> */}
      {/* <div className="flex flex-column gap-3">
        <div className="flex flex-column gap-2">
          <label htmlFor="job">Job</label>
          <input type="text" id="job" />
        </div>
        <div className="flex flex-column gap-2">
          <label htmlFor="job">Assign To</label>
          <input type="text" id="job" />
        </div>
      </div>
      <div className="flex justify-content-end gap-2 mt-3">
        <button
          className="p-button p-button-outlined"
          onClick={() => hideModal()}
        >
          Cancel
        </button>
        <button className="p-button">Assign</button>
      </div> */}
    </Dialog>
  );
};

export default JobAssignModal;
