export const addPayment = async (paymentInfo) => {
  const response = await fetch("/api/add-payment", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ paymentInfo }),
  });
  const json = await response.json();
  console.log(json);
  return json;
};
export const getPayementsByOrder = async (orderId) => {
  try {
    console.log(orderId);
    const response = await fetch(`/api/get-payments/${orderId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const getPayementsByUser = async (role, userId) => {
  try {
    const response = await fetch(`/api/get-user-payments/${role}/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
};
