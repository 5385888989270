import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// Step 1: Create Context
const AuthContext = createContext();

// Step 2: Provide Context
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState("");
  const [userRole, setUserRole] = useState("");
  let navigate = useNavigate();
  const login = () => {
    // Perform login logic (e.g., API call)
    // If login is successful, set isLoggedIn to true
    setIsLoggedIn(true);
    // localStorage.setItem("isLoggedIn", true);
    // setIsLoggedIn(localStorage.getItem("isLoggedIn"));
    setUserId(JSON.parse(localStorage.getItem("userId")));
    setUserRole(localStorage.getItem("userRole"));
  };

  const logout = async () => {
    // Perform logout logic (e.g., clearing session, API call)
    // If logout is successful, set isLoggedIn to false
    setIsLoggedIn(false);
    setUserId("");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userId");
    localStorage.removeItem("ui");
    localStorage.removeItem("userRole");

    // window.location.href("/");
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, login, logout, userId, userRole }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Step 3: Implement Login Logic (handled in AuthProvider)

// Step 4: Use Context in Components
export const useAuth = () => useContext(AuthContext);
