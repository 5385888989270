import React, { useEffect, useState } from "react";
// import { getLessonsByUser, updateLessonStatus } from "../../services/lessons";
import "../../styles/StudentLessons.css"; // Create a CSS file for custom styles
import { getLessonsByUser, updateLessonStatus } from "../../services/lessons";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { formatDuration } from "../../Components/custom/Helpers";
import { formatDate } from "../../Components/Helpers/Helper";

const StudentLessons = ({ userId, role }) => {
  const id = userId || localStorage.getItem("userId");
  const [lessons, setLessons] = useState([]);
  const [feedback, setFeedback] = useState([]); // Store feedback for each lesson

  useEffect(() => {
    const fetchLessons = async () => {
      const response = await getLessonsByUser(JSON.parse(role), id);
      setLessons(response);
    };
    fetchLessons();
  }, [id, role]);

  const handleAction = async (lessonId, action) => {
    // Update the lesson status (accept/decline)
    await updateLessonStatus(lessonId, action, feedback[lessonId] || "");
    // Refresh lessons
    const response = await getLessonsByUser(JSON.parse(role), id);
    setLessons(response);
  };
  const canDeclineLesson = (lessonTime, lessonDate, thresholdHours) => {
    const lessonDateTimeString = `${formatDateWithSlashes(
      lessonDate
    )}T${lessonTime}`;
    const lessonDateTime = new Date(lessonDateTimeString);
    // const lessonDate = new Date(lessonTime);
    console.log(lessonDateTime);

    const currentDate = new Date();
    const hoursDifference = (lessonDateTime - currentDate) / 1000 / 60 / 60; // Convert milliseconds to hours
    return hoursDifference >= thresholdHours;
  };
  const handleFeedbackChange = (lessonId, value) => {
    setFeedback((prevFeedback) => ({ ...prevFeedback, [lessonId]: value }));
  };
  function formatDateWithSlashes(date) {
    date = new Date(date);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  return (
    <div className="student-lessons container">
      <h1>Your Lessons</h1>
      <div className="student-lessons-cards-container">
        {lessons?.length === 0 ? (
          <p>No lessons available.</p>
        ) : (
          lessons?.map((lesson) => (
            <Card
              key={lesson.id}
              className="lesson-card mx-2"
              title={`Lesson on ${formatDate(lesson.date)}`}
              // title={`Lesson on ${new Date(lesson.date).toLocaleDateString()}`}
            >
              <p>
                <strong>Instructor:</strong> {lesson.instructor.name}
              </p>
              <p>
                <strong>Start Time:</strong> {lesson.start_time}
              </p>
              <p>
                <strong>Duration:</strong> {formatDuration(lesson.duration)}{" "}
                {/* minutes */}
              </p>
              {lesson.status === "waiting" && (
                <div className="lesson-actions">
                  <Button
                    label="Accept"
                    className="btn btn-success"
                    onClick={() => handleAction(lesson.id, "accepted")}
                  />
                  <Button
                    label="Decline"
                    className="btn btn-warning"
                    onClick={() => handleAction(lesson.id, "declined")}
                    disabled={
                      !canDeclineLesson(
                        lesson.start_time,
                        lesson.date,
                        lesson.instructor.decline_hours
                      )
                    }
                  />
                </div>
              )}
              {lesson.status === "completed" && (
                <div className="feedback-section">
                  <p className=" fs-5 fw-bold ">Lesson Completed</p>
                  {/* <InputText
                    value={feedback[lesson.id] || ""}
                    onChange={(e) =>
                      handleFeedbackChange(lesson.id, e.target.value)
                    }
                    placeholder="Add feedback"
                  />
                  <Button label="Submit" className="w-full md:w-5rem" /> */}
                </div>
              )}
            </Card>
          ))
        )}
      </div>
    </div>
  );
};

export default StudentLessons;
