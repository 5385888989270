import React, { useState, useEffect, useRef, useContext } from "react";
// import { Can, AbilityContext } from "../configs/Ability-context.js";
import { CustomListing } from "../Components/custom/CustomListing.jsx";
import { Column } from "primereact/column";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import { InputText } from "primereact/inputtext";
import { MDBPopper } from "mdbreact";
import { FaPlus } from "react-icons/fa";
// import PasswordConfirmationModal from "../misc/components/PasswordConfirmationModal";
// import CustomSelect from "../components/custom/CustomSelect";
// import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { getAllCourses, updateCourse } from "../services/courses.js";
import { NewCustomListing } from "../Components/custom/NewCustomListing.jsx";
import CourseEditViewModal from "./Modals/CourseEditViewModal.jsx";
const AllCourses = ({ collapsed }) => {
  const dt = useRef(null);
  const [
    {
      //   allCourses,
      isLoading,
      refreshData,
      isUpdatingSetting,
      selectedSetting,
      activeEditor,
      editorText,
    },
    setState,
  ] = useState({
    // allCourses: [],
    isLoading: false,

    refreshData: false,
    isUpdatingSetting: false,
    selectedSetting: null,
    activeEditor: { field: "", id: "" },
    editorText: "",
  });
  const [allCourses, setAllCourses] = useState([]);
  const [courseEditModalVisible, setCourseEditModalVisible] = useState(false);
  const [coursePopulated, setCoursePopulated] = useState({});
  const userRole = localStorage.getItem("userRole");
  const sortedCourses = allCourses.sort((a, b) => a.hours - b.hours);
  // const {userId}
  // const ability = useContext(AbilityContext);
  const getCourses = async () => {
    await getAllCourses()
      .then((data) => {
        setAllCourses(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCourses();
  }, []);
  // const handleUpdate = async (id, newValue) => {
  //   try {
  //     const response = await updateCourse(id, { value: newValue });
  //     if (response.success) {
  //       setAllCourses((prevCourses) =>
  //         prevCourses.map((course) =>
  //           course.id === id ? { ...course, value: newValue } : course
  //         )
  //       );
  //       console.log(response.message);
  //     } else {
  //       console.error(response.message);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleUpdate = async (id, field, newValue) => {
    try {
      const response = await updateCourse(id, { [field]: newValue });
      console.log(id, { [field]: newValue });
      if (response.success) {
        setAllCourses((prevCourses) =>
          prevCourses.map((course) =>
            course.id === id ? { ...course, [field]: newValue } : course
          )
        );
        console.log(response.message);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInlineUpdate = async (rowData, field) => {
    await handleUpdate(rowData.id, field, editorText);
    console.log(rowData.id, field, editorText);
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field: "", id: "" },
      editorText: "",
    }));
  };

  //   const handleUpdate = async (id, newValue) => {
  //     try {
  //       const response = await updateCourse(id, { value: newValue });
  //       if (response.success) {
  //         allCourses.map((course) => {
  //           if (course.id === id) {
  //             course.value = newValue;
  //           }
  //           return course;
  //         });
  //         // setState((prevState) => ({
  //         //   ...prevState,
  //         //   allCourses: prevState.allCourses.map((setting) =>
  //         //     setting.id === id ? { ...setting, value: newValue } : setting
  //         //   ),
  //         // }));
  //         console.log(response.message);
  //       } else {
  //         console.error(response.message);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  const handleInputChange = (e) => {
    setState((prevState) => ({ ...prevState, editorText: e.target.value }));
  };

  // const handleInlineUpdate = async (rowData) => {
  //   await handleUpdate(rowData.id, editorText);
  //   setState((prevState) => ({
  //     ...prevState,
  //     activeEditor: { field: "", id: "" },
  //     editorText: "",
  //   }));
  //   // }
  // };

  const setEditor = (field, id, value) => () => {
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field, id },
      editorText: value,
    }));
  };

  const handleCancelEdit = () => {
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field: "", id: "" },
      editorText: "",
    }));
  };
  const handleEditIconClick = (course) => {
    // console.log("edit icon clicked");
    setCourseEditModalVisible(true);
    setCoursePopulated(course);
  };
  const getEditor = (rowData, field) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <InputText autoFocus value={editorText} onChange={handleInputChange} />
      <Button
        icon="pi pi-check"
        className="p-button-rounded p-button-success p-mr-2"
        // style={{ width: "4%" }}
        onClick={() => handleInlineUpdate(rowData, field)}
      />
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-danger"
        // style={{ width: "4%" }}
        onClick={handleCancelEdit}
      />
    </div>
  );
  const ActionsBody = (rowData) => {
    return (
      // <div className="p-datatable-header">
      <Button
        label="View/Edit"
        icon="pi pi-pencil"
        className="button-primary"
        // className="p-button-success p-mr-2"
        onClick={() => handleEditIconClick(rowData)}
      />
      // </div>
    );
  };
  const inlineEditor = (field, rowData) => (
    <Inplace
      closable={false}
      active={activeEditor.field === field && activeEditor.id === rowData.id}
      onOpen={setEditor(field, rowData.id, rowData[field])}
    >
      <InplaceDisplay>
        <MDBPopper domElement tag="span" placement="right">
          {rowData[field] ? (
            <span>{rowData[field]}</span>
          ) : (
            <span>
              <FaPlus style={{ cursor: "pointer" }} />
            </span>
          )}
          <span>{rowData[field] ? "Click to edit" : "Click to add"}</span>
        </MDBPopper>
      </InplaceDisplay>
      <InplaceContent>{getEditor(rowData, field)}</InplaceContent>
    </Inplace>
  );
  // const inlineEditor = (field, rowData) => (
  //   <Inplace
  //     closable={false}
  //     active={activeEditor.field === field && activeEditor.id === rowData.id}
  //     onOpen={setEditor(field, rowData.id, rowData[field])}
  //   >
  //     <InplaceDisplay>
  //       <MDBPopper domElement tag="span" placement="right">
  //         <span>{rowData[field]}</span>
  //         <span>{" Click to edit"}</span>
  //       </MDBPopper>
  //     </InplaceDisplay>
  //     <InplaceContent>{getEditor(rowData, field)}</InplaceContent>
  //   </Inplace>
  // );
  const columns = [
    {
      field: "name",
      header: "Name",
      filter: true,
      sortable: true,
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
    },
    {
      field: "slug",
      header: "Slug",
      filter: true,
      sortable: true,
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
    },
    {
      field: "hours",
      header: "Hours",
      filter: true,
      sortable: true,
      body: (rowData) => inlineEditor("hours", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      // headerStyle: { width: "290px" },
    },
    {
      field: "price",
      header: "Price",
      filter: true,
      body: (rowData) => inlineEditor("price", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      // headerStyle: { width: "290px" },
    },
    // {
    //   field: "description",
    //   header: "Description",
    //   filter: true,
    //   body: (rowData) => inlineEditor("description", rowData),
    //   selectedByDefault: true,
    //   filterPlaceholder: "Search by value",
    //   filterMatchMode: "contains",
    //   headerStyle: { width: "290px" },
    // },
    // {
    //   field: "details",
    //   header: "Detail",
    //   filter: true,
    //   body: (rowData) => inlineEditor("details", rowData),
    //   selectedByDefault: true,
    //   filterPlaceholder: "Search by value",
    //   filterMatchMode: "contains",
    //   headerStyle: { width: "290px" },
    // },
    {
      field: "from_price",
      header: "From Price",
      filter: true,
      body: (rowData) => inlineEditor("from_price", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      // headerStyle: { width: "290px" },
    },
    {
      field: "course_deposit",
      header: "Course Deposit",
      filter: true,
      body: (rowData) => inlineEditor("course_deposit", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      // headerStyle: { width: "290px" },
    },
    {
      // field: "",
      header: "User Actions",
      // filter: true,
      body: ActionsBody,
      // body: (rowData) => inlineEditor("course_deposit", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      // headerStyle: { width: "290px" },
    },
  ];

  return userRole === `"admin"` ? (
    <form
      className="p-m-0 p-pt-4"
      // className={collapsed ? "" : "p-m-0 p-pt-4"}
      noValidate
      // style={!collapsed ? { marginLeft: "250px" } : {}}
      onSubmit={(e) => e.preventDefault()}
    >
      {/* <CustomListing */}
      <NewCustomListing
        // collapsed={collapsed}
        // allRecords={allCourses}
        allRecords={sortedCourses}
        heading={"Courses"}
        firstColumn={
          <Column
            field="category"
            header="Category"
            sortable={true}
            filter={true}
            style={{ borderBottom: "1px solid grey" }}
            filterPlaceholder="Search by name"
            filterMatchMode="contains"
            headerStyle={{ width: "170px" }}
          />
        }
        changeValue={() => {}}
        columns={columns}
        // permissionSubject={"appSettings"}
        isLoading={isLoading}
        handleRefresh={() =>
          setState((prevState) => ({
            ...prevState,
            refreshData: !prevState.refreshData,
          }))
        }
        editDataKey={"id"}
        tableRef={dt}
        timeStamps={true}
      />
      <CourseEditViewModal
        course={coursePopulated}
        visible={courseEditModalVisible}
        onHide={() => setCourseEditModalVisible(false)}
        handleUpdate={handleUpdate}
      />
    </form>
  ) : (
    <>
      {/* <h2>{role}</h2>
    <h3>{userRole}</h3> */}
      <div className="text-danger fs-2 text-center">
        you are not allowed to see this page
      </div>
    </>
  );
};

export default AllCourses;
