import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Slider } from "primereact/slider";
import React, { useState, useEffect } from "react";

export const LessonModal = ({
  visible,
  onHide,
  onCreate,
  onReschedule,
  lesson,
  orderPopulated,
  userPopulated,
}) => {
  const [date, setDate] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [duration, setDuration] = useState(30);
  useEffect(() => {
    // const fetchOrder()
    if (lesson) {
    }
  });
  useEffect(() => {
    if (lesson) {
      setDate(new Date(lesson.date));
      setStartTime(lesson.start_time);
      setDuration(lesson.duration);
    } else {
      setDate(null);
      setStartTime("");
      setDuration(30);
    }
  }, [lesson]);

  const handleSave = () => {
    const lessonInfo = {
      id: lesson ? lesson.id : null,
      date,
      startTime,
      duration,
      instructorId: orderPopulated
        ? orderPopulated.instructor_id
        : userPopulated
        ? userPopulated.instructor_id
        : null, // Conditionally set instructorId
      studentId: orderPopulated
        ? orderPopulated.customer_id
        : userPopulated
        ? userPopulated?.id
        : null,
      // studentId: orderPopulated.customer_id,
      orderId: orderPopulated?.id || null,
    };

    if (lesson) {
      onReschedule(lessonInfo);
    } else {
      onCreate(lessonInfo);
    }

    setDate(null);
    setStartTime("");
    setDuration(30);
    onHide();
  };

  const maxDuration =
    orderPopulated?.order_detail?.course?.hours * 60 < 360
      ? orderPopulated?.order_detail?.course?.hours
      : 360;
  const formatDuration = (value) => {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    return `${hours ? `${hours} hour${hours > 1 ? "s" : ""}` : ""}${
      minutes ? ` ${minutes} minute${minutes > 1 ? "s" : ""}` : ""
    }`;
  };
  return (
    <Dialog
      header={lesson ? "Reschedule Lesson" : "Create Lesson"}
      visible={visible}
      onHide={onHide}
      modal
      // style={{ width: "50vw" }}
    >
      <div className="p-fluid">
        <div className="p-field">
          <label htmlFor="date">Date</label>
          <Calendar
            id="date"
            value={date}
            onChange={(e) => setDate(e.value)}
            dateFormat="dd/mm/yy"
            // showIcon
          />
        </div>

        <div className="p-field">
          <label htmlFor="startTime">Start Time</label>
          <InputText
            id="startTime"
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
          />
        </div>
        <div className="p-field">
          <label htmlFor="duration">Duration</label>
          <Slider
            className="mt-2 mb-2"
            id="duration"
            value={duration}
            onChange={(e) => setDuration(e.value)}
            min={30}
            max={maxDuration}
            step={15}
          />
          <span>Selected Duration: {formatDuration(duration)}</span>
        </div>
        <div className="p-d-flex p-jc-between">
          <Button
            label="Cancel"
            icon="pi pi-times"
            className="p-button-text"
            onClick={onHide}
          />
          <Button
            label={lesson ? "Reschedule Lesson" : "Create Lesson"}
            icon="pi pi-check"
            className="p-button-primary"
            onClick={handleSave}
          />
        </div>
      </div>
    </Dialog>
  );
};

// import { Button } from "primereact/button";
// import { Calendar } from "primereact/calendar";
// import { Dialog } from "primereact/dialog";
// import { InputText } from "primereact/inputtext";
// import { Slider } from "primereact/slider";
// import React, { useState } from "react";

// const LessonModal = ({ onCreate, onHide, visible, orderPopulated }) => {
//   const [date, setDate] = useState(null);
//   const [startTime, setStartTime] = useState(null);
//   const [duration, setDuration] = useState(30);
//   const studentId = orderPopulated?.customer_id;
//   const instructorId = orderPopulated?.instructor_id;
//   const orderId = orderPopulated?.id;
//   const handleCreate = async () => {
//     await onCreate({
//       date,
//       startTime,
//       duration,
//       studentId,
//       instructorId,
//       orderId,
//     });
//     setDate(null);
//     setStartTime("");
//     setDuration(30);
//     onHide(); // Hide modal after creation
//   };
//   const maxDuration = (orderPopulated?.order_detail?.course?.hours || 0) * 60;
//   return (
//     <Dialog
//       header="Create Lesson"
//       visible={visible}
//       onHide={onHide}
//       modal
//       style={{ width: "max-content" }}
//     >
//       {maxDuration}
//       <div className="p-fluid">
//         <div className="p-field">
//           <label htmlFor="date">Date</label>
//           <Calendar
//             id="date"
//             value={date}
//             onChange={(e) => setDate(e.value)}
//             dateFormat="dd/mm/yy"
//             // showIcon
//           />
//         </div>

//         <div className="p-field">
//           <label htmlFor="startTime">Start Time</label>
//           <InputText
//             id="startTime"
//             type="time"
//             value={startTime}
//             onChange={(e) => setStartTime(e.target.value)}
//           />
//         </div>

//         <div className="p-field">
//           <label htmlFor="duration">Duration (minutes)</label>
//           <Slider
//             id="duration"
//             value={duration}
//             onChange={(e) => setDuration(e.value)}
//             min={30}
//             max={maxDuration}
//             step={30}
//             marks={{ [maxDuration]: maxDuration }}
//           />
//           <span>Selected Duration: {duration} minutes</span>
//         </div>

//         <div className="p-d-flex p-jc-between">
//           <Button
//             label="Cancel"
//             icon="pi pi-times"
//             className="p-button-text"
//             onClick={onHide}
//           />
//           <Button
//             label="Create Lesson"
//             icon="pi pi-check"
//             className="p-button-primary"
//             onClick={handleCreate}
//           />
//         </div>
//       </div>
//     </Dialog>
//   );
// };

export default LessonModal;
