import React, { useEffect, useState } from "react";
import "../styles/Reviews.css";
// require( "1.jpg",
//     "2.jpg",
//     "3.jpg",
//     "4.jpg",
//     "5.jpg",
//     "6.jpg",
//     "7.jpg",
//     "8.jpg",
//     "9.jpg",
//     "10.jpg",
//     "11.jpg",
//     "12.jpg",
//     "13.jpg",
//     "14.jpg",
//     "15.jpg",
//     "16.jpg",
//     "17.jpg",
//     "18.jpg") from '../Assets/images'
// const images = [
//   "image1.jpg",
//   "image2.jpg",
//   "image3.jpg",
//   "image4.jpg",
//   "image5.jpg",
//   "image6.jpg",
//   "image7.jpg",
//   "image8.jpg",
//   "image9.jpg",
//   "image10.jpg",
//   "image11.jpg",
//   "image12.jpg",
//   "image13.jpg",
//   "image14.jpg",
//   "image15.jpg",
//   "image16.jpg",
//   "image17.jpg",
//   "image18.jpg",
// ];
const importAll = (r) => r.keys().map(r);
const images = importAll(
  require.context(
    "../Assets/students testimonials",
    false,
    /\.(jpg|jpeg|png|gif|webp)$/
  )
);

const Reviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleImages, setVisibleImages] = useState(3);

  // Update visible images based on window size
  useEffect(() => {
    const updateVisibleImages = () => {
      if (window.innerWidth > 768) {
        setVisibleImages(4);
      } else if (window.innerWidth > 480) {
        setVisibleImages(3);
      } else {
        setVisibleImages(1);
      }
    };

    updateVisibleImages();
    window.addEventListener("resize", updateVisibleImages);

    return () => {
      window.removeEventListener("resize", updateVisibleImages);
    };
  }, []);
  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + visibleImages < images.length ? prevIndex + visibleImages : 0
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - visibleImages >= 0
        ? prevIndex - visibleImages
        : images.length - visibleImages
    );
  };
  useEffect(() => {
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval);
  }, [visibleImages]);
  //   const visibleImages =
  //     window.innerWidth > 768 ? 6 : window.innerWidth > 480 ? 3 : 1;
  return (
    <div className="">
      {" "}
      <h3 className="text-center mb-5">Recently Passed Students</h3>
      <div className="slider d-flex">
        <button onClick={prevSlide} className="slider-button prev">
          Prev
        </button>
        <div className="slider-wrapper ">
          <div
            className="slider-inner"
            style={{
              transform: `translateX(-${
                (currentIndex / visibleImages) * 100
              }%)`,
            }}
          >
            {images.map((image, index) => (
              <>
                <div
                  key={index}
                  className="slider-item"
                  style={{ flex: `0 0 ${100 / visibleImages}%` }}
                >
                  <img src={image} alt={`Slide ${index + 1}`} />
                </div>
              </>
            ))}
            {/* {images.map((image, index) => (
            <div key={index} className="slider-item">
              <img src={image} alt={`Slide ${index + 1}`} />
            </div>
          ))} */}
          </div>
        </div>
        <button onClick={nextSlide} className="slider-button next">
          Next
        </button>
      </div>
    </div>
  );
};

export default Reviews;
