import React, { createContext, useState, useEffect } from "react";
import { getSettings } from "../services/settings";
import { getAllCourses } from "../services/courses";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [allSettings, setAllSettings] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        // Making both API requests in parallel
        const [settingsResponse, coursesResponse] = await Promise.all([
          getSettings(),
          getAllCourses(),
        ]);

        // Extracting and setting data only if the component is still mounted
        if (isMounted) {
          // Handle destructuring of API response similarly to your individual functions
          const { data: settingsData } = settingsResponse; // Assuming getSettings returns an object with `data`
          setAllSettings(settingsData);

          const coursesData = coursesResponse; // Assuming getAllCourses returns the data directly
          setAllCourses(coursesData);
        }
      } catch (error) {
        if (isMounted) {
          setError("Failed to load data.");
          console.error(error);
        }
      } finally {
        if (isMounted) setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <GlobalContext.Provider
      value={{ allSettings, allCourses, isLoading, error }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
