import React, { useState } from "react";
import "../styles/DiscountPromosSection.css"; // Custom CSS file for additional styling
import CouponsViewModal from "../Pages/Modals/CouponsViewModal";
import PromoImage from "../Assets/HomePromoSection.jpg";
const DiscountPromosSection = () => {
  const [showCouponModal, setShowCouponModal] = useState(false);
  return (
    <>
      <div className="discount-section d-flex flex-column justify-content-center align-content-center">
        <img
          src={PromoImage}
          alt="Promo"
          onClick={() => {
            setShowCouponModal(true);
          }}
          loading="lazy"
        />
      </div>
      <CouponsViewModal
        show={showCouponModal}
        onHide={() => {
          setShowCouponModal(false);
        }}
      />
    </>
  );
};

export default DiscountPromosSection;
// import React, { useState } from "react";
// import "../styles/DiscountPromosSection.css"; // Custom CSS file for additional styling
// import CouponsViewModal from "../Pages/Modals/CouponsViewModal";
// import { Button } from "primereact/button";

// const DiscountPromosSection = () => {
//   const [showCouponModal, setShowCouponModal] = useState(false);

//   return (
//     <div className="discount-section d-flex ">
//       <div className="content-box shadow-lg p-4">
//         {/* <h2 className="discount-heading mb-4"> */}
//         <h2 className="">Unlock Exclusive Discounts on Driving Courses</h2>
//         <p className="discount-text mb-3 mt-3 py-2">
//           Get ready to hit the road with confidence! We’re offering{" "}
//           <strong>special promo codes</strong> that give you{" "}
//           <strong>amazing discounts</strong> on our top-rated driving courses.
//           Whether you're a beginner or looking to polish your skills, there's
//           never been a better time to learn. Don't miss out on this limited-time
//           offer!
//         </p>
//         <div className="text-center">
//           <Button
//             label="Get My Discount"
//             // className="button-quaternary"
//             className="p-button-rounded p-button-raised p-button-sm with-icon-button
//                         discount-button  button-quaternary p-py-1 p-px-3 bg-hover-tertiary "
//             onClick={() => {
//               setShowCouponModal(true);
//             }}
//           />
//         </div>
//         {/* <Button
//           className="discount-button button-primary text-center"
//           size="lg"
//           onClick={() => {
//             setShowCouponModal(true);
//           }}
//         >
//           <span className="text-center">Get My Discount</span>
//         </Button> */}
//       </div>
//       <CouponsViewModal
//         show={showCouponModal}
//         onHide={() => {
//           setShowCouponModal(false);
//         }}
//       />
//     </div>
//   );
// };

// export default DiscountPromosSection;
