import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { deleteRole, getRoles } from "../services/roles";
import { isConfirmed } from "../Components/custom/Helpers";
import { Can } from "../configs/Ability-context";
import { NewCustomListing } from "../Components/custom/NewCustomListing";

let _isMounted;
const AllRoles = (props) => {
  let dt = useRef(null);
  let toast = useRef(null);
  let navigate = useNavigate();

  const [{ allRoles, isLoading, selectedIsDefault, refreshData }, setState] =
    useState({
      allRoles: [],
      isLoading: false,
      roleToBeDeleted: null,
      selectedIsDefault: null,
      refreshData: false,
    });

  useEffect(() => {
    _isMounted = true;
    const fetchData = async () => {
      changeValue("isLoading", true);
      try {
        const { data } = await getRoles();
        changeValue("allRoles", data);
      } catch (error) {
        console.log(error);
      }
      changeValue("isLoading", false);
    };
    fetchData();
    return () => {
      _isMounted = false;
    };
  }, [refreshData]);

  const changeValue = (name, value) => {
    _isMounted && setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleEdit = (id) => (e) => {
    navigate(`/admin/users/roles/edit/${id}`);
  };

  const handleCreate = () => {
    navigate("/admin/users/roles/new");
  };

  const handleDelete = (id) => (e) => {
    isConfirmed({
      type: "delete",
      e,
      accept: async () => {
        changeValue("isLoading", true);
        const { success, message, affectedRows } = await deleteRole(id);
        console.log(message);
        let updatedRoles = allRoles.filter((role) => role.id !== id);
        if (success && affectedRows) {
          toast.current.show({
            severity: "success",
            summary: `Role deleted successfully.`,
          });
          setState((prevState) => ({
            ...prevState,
            allRoles: updatedRoles,
            isLoading: false,
          }));
        }
        changeValue("isLoading", false);
      },
    });
  };

  const isDefaultFilter = (
    <Dropdown
      value={selectedIsDefault}
      options={[
        { label: "Default", value: true },
        { label: "Not default", value: false },
      ]}
      onChange={(e) => {
        dt.current.filter(e.value, "is_default", "equals");
        changeValue("selectedIsDefault", e.value);
      }}
      placeholder="Default/Not default"
      className="p-column-filter"
      showClear
    />
  );

  const isDefaultBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Default ?</span>
        <span
          className={`pi ${rowData.is_default ? "pi-check" : "pi-times"}`}
        />
      </React.Fragment>
    );
  };

  const columns = [
    {
      field: "is_default",
      header: "Default ?",
      body: isDefaultBody,
      filter: true,
      filterElement: isDefaultFilter,
    },
  ];

  return (
    <>
      <Can I="read" a="roles">
        <NewCustomListing
          allRecords={allRoles}
          heading={"Roles"}
          firstColumn={
            <Column
              field="name"
              header="Name"
              sortable={true}
              filter={true}
              style={{ borderBottom: "1px solid grey" }}
              filterPlaceholder="Search by name"
              filterMatchMode="contains"
              headerStyle={{ width: "170px" }}
            />
          }
          columns={columns}
          permissionSubject={"roles"}
          isLoading={isLoading}
          handleCreate={handleCreate}
          handleRefresh={() => changeValue("refreshData", !refreshData)}
          handleEdit={true}
          handleDelete={true}
          updatePermission={(rowData) =>
            rowData.is_default ? "updateDefault" : "update"
          }
          customEditButton={(customButton, rowData) => {
            return (
              rowData.name.toLowerCase() !== "super_admin" &&
              customButton(
                "pi pi-pencil",
                handleEdit(rowData.id),
                false,
                "edit"
              )
            );
          }}
          customDeleteButton={(customButton, rowData) => {
            return (
              !rowData.is_default &&
              customButton(
                "pi pi-trash",
                handleDelete(rowData.id),
                false,
                "delete"
              )
            );
          }}
          // exportData={"roles"}
          // importData={"roles"}
          // columnsToImport={[
          //   "id",
          //   "name",
          //   "createdAt",
          //   "updatedAt",
          //   "deletedAt",
          //   "created_by",
          //   "last_updated_by",
          //   "deleted_by",
          // ]}
          updateColumns={["name"]}
          tableSmall
          tableRef={dt}
          toastRef={toast}
          timeStamps={true}
        />
      </Can>
    </>
  );
};

export default AllRoles;
