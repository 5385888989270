import React, { useState } from "react";
import "../styles/HeroSection.css";
import { Link } from "react-router-dom";
const HeroSection = () => {
  const [zipCode, setZipCode] = useState("");
  return (
    <div className="hero-section-root-container mt-4 mb-0">
      <div className="home">
        <div className="instructor-input-row hero-section mt-3 p-5 mx-5 ">
          <div className="instructor-input-column hero-text ">
            <h1>Learn Conveniently Pass Swiftly</h1>
            <p className="text-white secondary-hero-text ">
              Based on your driving skills, we have courses for you to pass
              test.
            </p>
            <div className="postal-code justify-content-center align-items-center">
              <input
                type="text"
                className="mail-input"
                placeholder="Enter your Post Code"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
              <div className="vertical-line"></div>
              <Link to="/courses">
                <button className="btn postal-button">
                  Start Your Journey
                </button>
              </Link>
            </div>
            <div className="postal-code-mobile d-md-none justify-content-center align-items-center">
              <input
                type="text"
                className="mail-input"
                placeholder="Enter your Postcode"
                required
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
              {/* <Link to="/courses/beginner"> */}
              <Link to="/courses">
                {" "}
                <button className="d-md-none mobile-postal-button ">
                  Start Your Journey
                </button>
              </Link>
            </div>
          </div>
          {/* <div className="instructor-input-column hero-image-container"> */}
          <div className="instructor-input-column ">
            <img
              className="hero-image"
              srcSet={`${process.env.PUBLIC_URL}/Assets/Hero-Section-Car-small.webp 400w,
           ${process.env.PUBLIC_URL}/Assets/Hero-Section-Car-medium.webp 800w,
           ${process.env.PUBLIC_URL}/Assets/Hero-Section-Car.webp 1200w`}
              sizes="(max-width: 600px) 400px, (max-width: 1200px) 800px, 1200px"
              src={`${process.env.PUBLIC_URL}/Assets/Hero-Section-Car.webp`}
              // src={require("../Assets/Hero-Section-Car.webp")}
              alt="Hero"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeroSection;
