export const createOrder = async ({
  order,
  customer_id,
  // browserInfo,
  orderTotal,
  amountPaying,
  coupon_id,
  discountApplied,
  // discountCoupon,
  // paymentSuccessful,
  // paymentDetails,
  // amount_paid,
}) => {
  try {
    const response = await fetch("/api/create-order", {
      method: "POST",
      body: JSON.stringify({
        order,
        customer_id,
        // browserInfo,
        orderTotal,
        amountPaying,
        coupon_id,
        discountApplied,
        // paymentSuccessful,
        // discountCoupon,
        // paymentDetails,
        // amount_paid,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Ensure the error is propagated
  }
};
export const updateOrderTests = async (
  orderId,
  testType,
  testPrice,
  op,
  promoCodeDiscount,
  couponType
) => {
  try {
    const response = await fetch(`/api/update-order-tests/${orderId}`, {
      method: "PUT",
      body: JSON.stringify({
        testType,
        testPrice,
        op,
        promoCodeDiscount,
        couponType,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(
      "orderId,testType,testPrice,op,promoCodeDiscount,couponType",
      orderId,
      testType,
      testPrice,
      op,
      promoCodeDiscount,
      couponType
    );

    if (!response.ok) {
      throw new Error("Failed to update tests: " + response.statusText);
    }

    const data = await response.json();
    return data; // You can return the response data for further use if needed
  } catch (err) {
    console.error("Error updating tests:", err);
  }
};

// export const updateCourse = async (id, updatedCourse) => {
//   try {
//     const response = await fetch(`/api/update-course/${id}`, {
//       method: "PUT",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         updatedCourse,
//       }),
//     });
//     if (!response.ok) {
//       throw new Error("Network response was not ok " + response.statusText);
//     }
//     const data = await response.json(); // Ensure response is parsed as JSON
//     return data;
//   } catch (err) {
//     console.log(err);
//   }
// };
export const getAllOrders = async () => {
  try {
    const response = await fetch("/api/get-all-orders", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    const data = await response.json();
    // console.log("Success in getting data:", data);
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Ensure the error is propagated
  }
};
export const addManualOrder = async (job, totalOrderAmount, customerId) => {
  try {
    console.log("job", job);
    console.log("totalOrderAmount", totalOrderAmount);

    const response = await fetch("/api/add-manual-order", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        order: job,
        orderTotal: totalOrderAmount,
        customer_id: customerId,
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Ensure the error is propagated
  }
};
export const getOrder = async (id) => {
  try {
    const response = await fetch(`/api/getorder/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    const data = await response.json();
    // console.log(data);

    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Ensure the error is propagated
  }
};
export const updateOrder = async (id, updatedOrder) => {
  try {
    const response = await fetch(`/api/update-order/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        updatedOrder: updatedOrder,
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    const data = await response.json(); // Ensure response is parsed as JSON
    return data;
  } catch (err) {
    console.log(err);
  }
};
export const moveOrderToTrash = async (orderId) => {
  try {
    const options = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(`/api/orders/trash/${orderId}`, options);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const deleteOrder = async (orderId) => {
  try {
    const options = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(`/api/orders/${orderId}`, options);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
export const deleteAllOrdersInTrash = async () => {
  try {
    const options = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(`/api/orders`, options);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const restoreOrder = async (orderId) => {
  try {
    const res = await fetch(`/api/orders/restore/${orderId}`);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
export const restoreAllOrdersFromTrash = async () => {
  try {
    const res = await fetch(`/api/orders/restore`);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
export const getOrdersTrash = async () => {
  try {
    const res = await fetch(`/api/order/trash`);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
// export const getOrder = async (orderId) => {
//   try {
//     const response = await fetch(`/api/get-order/${orderId}`, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });

//     if (!response.ok) {
//       throw new Error("Network response was not ok " + response.statusText);
//     }
//     const data = await response.json();
//     // console.log("Success in getting data:", data);
//     return data;
//   } catch (error) {
//     console.error("Error:", error);
//     throw error; // Ensure the error is propagated
//   }
// };

// export const updateOrder = async (id, updatedAmountPaid, remaining) => {
//   try {
//     const response = await fetch(`/api/update-order/${id}`, {
//       method: "PUT",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         updatedAmountPaid,
//         remaining,
//       }),
//     });
//     if (!response.ok) {
//       throw new Error("Network response was not ok " + response.statusText);
//     }
//     const data = await response.json(); // Ensure response is parsed as JSON
//     return data;
//   } catch (err) {
//     console.log(err);
//   }
// };
