import { Avatar } from "primereact/avatar";
import { Menubar } from "primereact/menubar";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import { getUser } from "../services/users";

const LoggedInHeader = ({ handleLogOut }) => {
  const { isLoggedIn } = useContext(UserContext);
  const [userName, setUserName] = useState("");
  const [userProfileImage, setUserProfileImage] = useState("");
  const [showingContextMenu, setShowingContextMenu] = useState(false);
  const handleOutsideClick = (e) => {
    if (
      !e.target.closest(".context-menu") &&
      !e.target.closest(".avatar") &&
      !e.target.closest(".name-span")
    ) {
      setShowingContextMenu(false);
    }
  };
  const userRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data } = await getUser(userId);
        setUserName(data.name);
        setUserProfileImage(data.profile_img);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUser();
  }, [userId]);
  useEffect(() => {
    if (showingContextMenu) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showingContextMenu]);

  const profileUrl =
    JSON.parse(userRole) === "customer"
      ? "/student/profile"
      : "/instructor/profile";
  const Contextitems = [
    {
      label: "Profile",
      icon: "pi pi-user",
      url: profileUrl,
      className: "context-menu-item",
    },
    isLoggedIn && {
      label: "Logout",
      className: "context-menu-item",
      icon: "pi pi-sign-out",
      command: () => handleLogOut(),
    },
  ];
  return (
    <div className=" background-primary py-1">
      <ul className="nav justify-content-between align-items-center container">
        <div className="m-3 contact-container"></div>

        <div className="flex-end m-0 mt-1 mb-1 p-0 text-white justify-content-center align-items-center d-flex position-relative text-white-50">
          <Avatar
            onClick={() => {
              setShowingContextMenu(!showingContextMenu);
            }}
            image={
              userProfileImage
                ? `/uploads/${userProfileImage}`
                : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
            }
            shape="circle"
            className="avatar"
            size="normal"
          />
          <span
            className="mx-2 cursor-pointer name-span"
            onClick={() => {
              console.log("Name clicked");
              setShowingContextMenu(!showingContextMenu);
            }}
          >
            {userName}
          </span>
          {showingContextMenu && (
            <Menubar
              model={
                JSON.parse(userRole) === "admin"
                  ? Contextitems.slice(1)
                  : Contextitems
              }
              style={isLoggedIn && { zIndex: "10001" }}
              className="dropdown-menubar"
            />
          )}
        </div>
      </ul>
    </div>
  );
};

export default LoggedInHeader;
