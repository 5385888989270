import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Rating } from "primereact/rating";
import React, { useState } from "react";
import { addCourseReview } from "../../services/courseReviews";
const CourseReviewModal = ({ visible, onHide, job }) => {
  const [newCourseReview, setNewCourseReview] = useState({
    courseId: null,
    studentId: null,
    rating: 0,
    reviewText: "",
  });
  const handleSaveCourseReview = async () => {
    console.log(job);

    console.log(newCourseReview);
    setNewCourseReview({
      ...newCourseReview,
      courseId: job.order_detail.course.id,
      studentId: job.customer_id,
    });
    await addCourseReview(newCourseReview)
      .then((res) => {
        console.log(res);
        // onHide();
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="Course Review"
      style={{ width: "400px" }}
    >
      {/* <form> */}
      <div className="d-flex justify-content-center align-items-center flex-column justify-content-evenly px-5">
        <Rating
          value={newCourseReview.rating}
          onChange={(e) =>
            setNewCourseReview({ ...newCourseReview, rating: e.value })
          }
          cancel={false}
          className=" my-2 "
        />
        <InputTextarea
          placeholder="Write a review"
          className="w-100"
          value={newCourseReview.reviewText}
          onChange={(e) => {
            setNewCourseReview({
              ...newCourseReview,
              reviewText: e.target.value,
            });
            console.log(newCourseReview.reviewText);
          }}
          rows={5}
        />
        <Button
          className="p-button p-button-rounded p-button-success my-2"
          // type="submit"
          onClick={handleSaveCourseReview}
          label="Submit"
        />
      </div>
      {/* </form> */}
    </Dialog>
  );
};

export default CourseReviewModal;
