import axios from "axios";

export const addBussinessReview = async (formData) => {
  try {
    await axios.post("/api/add-review", formData);
  } catch (err) {
    console.error(err);
  }
};
export const getBussinessReviews = async () => {
  try {
    const res = await axios.get("/api/get-reviews");
    // console.log(res);
    return res.data;
  } catch (err) {
    console.error(err);
  }
};
export const updateReview = async (id, updatedReview) => {
  try {
    const response = await axios.put(`/api/update-review/${id}`, updatedReview);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};
export const moveReviewToTrash = async (id) => {
  try {
    const response = await axios.delete(`/api/soft-delete-review/${id}`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};
export const deleteReview = async (id) => {
  try {
    const response = await axios.delete(`/api/hard-delete-review/${id}`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};
export const deleteAllReviewsInTrash = async () => {
  try {
    const response = await axios.delete(`/api/hard-delete-reviews`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const restoreReview = async (id) => {
  try {
    const response = await axios.get(`/api/restore-review/${id}`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const restoreAllReviewsFromTrash = async () => {
  try {
    const response = await axios.get(`/api/reviews/restore`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};
export const getReviewsTrash = async () => {
  try {
    const response = await axios.get(`/api/reviews/trash`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};
