import React from "react";
import "../styles/CourseRecommenderSection.css";
import { Link } from "react-router-dom";

const CourseRecommenderSection = () => {
  return (
    <section className="course-recommender-section py-5">
      <div className="container">
        <div className="row text-center mb-4">
          <div className="col-12">
            <h2 className=" mb-3">Try Our Course Recommender</h2>
            <p className="lead mb-4">
              Struggling to decide which driving course suits you best? Our
              Course Recommender tool is here to help! Whether you're a complete
              beginner or looking to refine your driving skills, our intelligent
              recommender will guide you towards the most suitable courses.
            </p>
            {/* <p className="lead mb-4">
              By simply answering a few questions about your driving goals,
              preferences, and current skills, you’ll receive personalized
              recommendations that align with your needs. We analyze various
              factors, such as course duration, instructor expertise, and
              training methods to ensure you get the best fit.
            </p> */}
            {/* <p className="lead mb-4">
              Don't waste time browsing through countless options. Let our tool
              streamline your decision-making process and connect you with a
              course that sets you up for success. Our recommender takes the
              guesswork out of finding the perfect course, so you can focus on
              what really matters – becoming a confident and skilled driver.
            </p> */}
            <p className="lead mb-3 ">
              Click the button below to get started and take the first step
              towards your driving goals. Your ideal course is just a few clicks
              away!
            </p>
            {/* <p className="lead mb-4">
              Struggling to decide which driving course suits you best? Our
              Course Recommender tool is here to help! Get personalized
              recommendations based on your needs and preferences, and find the
              ideal course to kickstart your journey. Click the button below to
              get started!
            </p> */}
            <Link to="/course-recommender">
              <button className="button-primary px-5 py-2">Try Now</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourseRecommenderSection;
