import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { resendVerificationEmail, verifyUserEmail } from "../services/users";

const EmailVerification = () => {
  const [message, setMessage] = useState("");
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  let navigate = useNavigate();

  // console.log(token);
  // token=searchParams.get("token")
  const verifyEmaiRequest = async () => {
    try {
      const { success, message } = await verifyUserEmail(token);

      if (success) {
        // toast.current?.show({
        //   severity: "success",
        //   summary: "Verified",
        //   detail: message + " Login to continue!",
        // });
        toast.success("Email Verified, Redirecting to Login Page");
        setMessage(message);
        setTimeout(() => {
          navigate("/auth/login");
        }, 3000);
      } else {
        toast.error(message);
        // toast.current?.show({
        //   severity: "error",
        //   summary: "Not Verified",
        //   detail: message,
        // });
      }
    } catch (error) {
      console.log(error);
    }
  };
  verifyEmaiRequest();
  // useEffect(() => {
  //     console.log("email verification in process...");

  // }, []);
  //   return (
  // <div className="flex justify-content-center align-items-center min-h-screen">
  //   <div className="card w-25rem">
  //     <div className="card-body">
  //       <div className="text-center">
  //         <img
  //           src="https://cdn-icons-png.flaticon.com/512/2950/2950856.png"
  //           alt="logo"
  //           className="mb-3"
  //         />
  //         <h2 className="mb-3">Email Verification</h2>
  //         <p className="mb-3">Please wait while we verify your email...</p>
  //         <p className="mb-3">{message}</p>
  //       </div>
  //     </div>
  //   </div>
  // </div>
  //   );
  //   return <Toast ref={toast} className="p-mt-5 p-mr-3" />;
};
// const ResendVerificationEmail = () => {
//   let toast = useRef(null);
//   let navigate = useNavigate();
//   // console.log(token);
//   // token=searchParams.get("token")
//   const resendVerificationEmailRequest = async () => {
//     let user_id = localStorage.getItem("ui");
//     // console.log(user_id);
//     try {
//       const { success, message } = await resendVerificationEmail(user_id);

//       if (success) {
//         toast.current?.show({
//           severity: "success",
//           summary: "Done",
//           detail: message,
//         });
//         navigate("/auth/login");
//       } else {
//         toast.current?.show({
//           severity: "error",
//           summary: "Failed",
//           detail: message,
//         });
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   resendVerificationEmailRequest();
//   // useEffect(() => {
//   //     console.log("email verification in process...");

//   // }, []);
//   return <Toast ref={toast} className="p-mt-5 p-mr-3" />;
// };

// const RequestEmailVerification = () => {
//   let toast = useRef(null);
//   return (
//     <>
//       <div className="screen-center h-50vh">
//         <div className="p-m-0 p-px-4 p-p-sm-0 w-100 w-sm-75 w-md-50 w-lg-25 p-d-flex p-flex-column">
//           {/* <div className="center-screen h-50vh"> */}
//           <h6
//             className="bg-tertiary mb-3 text-left mx-3 py-2 px-3 color-dark"
//             style={{
//               borderLeft: "8px solid #0e5b96",
//               borderTopLeftRadius: "3px",
//               borderBottomLeftRadius: "3px",
//             }}
//           >
//             Email is not verified. Please verify email to continue.
//             <Link to="/sellers/resend_verify_email">
//               Resend Verification Request
//             </Link>
//           </h6>
//         </div>
//       </div>
//       <Toast ref={toast} className="p-mt-5 p-mr-3" />
//     </>
//   );
// };

export { EmailVerification };
