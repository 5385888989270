// import React, { useEffect, useState } from "react";
// import { Rating } from "primereact/rating";
// import { RxAvatar } from "react-icons/rx";
// import "../styles/RatingComponent.css";
// import { getAllCourseReviews } from "../services/courseReviews";

// const RatingComponent = ({ courseId }) => {
//   const [allCourseReviews, setAllCourseReviews] = useState([]);

//   useEffect(() => {
//     const fetchCourseReviews = async () => {
//       const response = await getAllCourseReviews();
//       const filteredReviews = response.filter(
//         (review) => review.course_id === courseId
//       );
//       setAllCourseReviews(filteredReviews);
//     };
//     fetchCourseReviews();
//   }, [courseId]);

//   return (
//     <div className="reviews-container">
//       <div className="row justify-content-center align-items-center">
//         {allCourseReviews.map((review) => (
//           <div key={review.id} className="col-md-3 text-center rating-card">
//             <div className="avatar-container">
//               <div className="avatar background-primary">
//                 <RxAvatar className="avatar-icon" />
//               </div>
//               <p className="my-5">{review?.student?.name}</p>
//             </div>
//             <Rating
//               value={review?.rating}
//               cancel={false}
//               className="rating-stars"
//             />
//             <div className="review-text ">{`“${review?.review_text}”`}</div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default RatingComponent;
import React, { useEffect, useState } from "react";
import { Rating } from "primereact/rating";
import { RxAvatar } from "react-icons/rx";
import "../styles/RatingComponent.css";
import { getAllCourseReviews } from "../services/courseReviews";

const RatingComponent = ({ courseId }) => {
  const [allCourseReviews, setAllCourseReviews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleReviews, setVisibleReviews] = useState(4); // Default to 4 reviews

  // Fetch course reviews
  useEffect(() => {
    const fetchCourseReviews = async () => {
      const response = await getAllCourseReviews();
      const filteredReviews = response.filter(
        (review) => review.course_id === courseId
      );
      setAllCourseReviews(filteredReviews);
    };
    fetchCourseReviews();
  }, [courseId]);

  // Update visible reviews based on window size
  useEffect(() => {
    const updateVisibleReviews = () => {
      if (window.innerWidth > 768) {
        setVisibleReviews(4); // Show 4 reviews for larger screens
      } else if (window.innerWidth > 480) {
        setVisibleReviews(3); // Show 3 reviews for medium screens
      } else {
        setVisibleReviews(1); // Show 1 review for small screens
      }
    };

    updateVisibleReviews();
    window.addEventListener("resize", updateVisibleReviews);

    return () => {
      window.removeEventListener("resize", updateVisibleReviews);
    };
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + visibleReviews < allCourseReviews.length
        ? prevIndex + visibleReviews
        : 0
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - visibleReviews >= 0
        ? prevIndex - visibleReviews
        : allCourseReviews.length - visibleReviews
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval);
  }, [visibleReviews, allCourseReviews, nextSlide]);

  return (
    <div className="reviews-slider ">
      <h3
        className="text-center mb-5"
        style={{
          fontWeight: "600",
          fontSize: " 32pt",
        }}
      >
        Course Reviews
      </h3>
      {allCourseReviews.length ? (
        <div className="slider d-flex">
          <button
            onClick={prevSlide}
            className="rating-slider-button slider-button prev"
          >
            <i className="pi pi-arrow-left" />
          </button>
          <div className="rating-slider-wrapper">
            <div
              className="rating-slider-inner px-5"
              style={{
                transform: `translateX(-${
                  currentIndex * (100 / visibleReviews)
                }%)`,
                width: `${100 / visibleReviews}%`,
              }}
            >
              {allCourseReviews.map((review) => (
                <div
                  key={review.id}
                  className="rating-slider-item rating-card  mx-1 my-1 "
                  style={{ flex: `0 0 calc(100%/${visibleReviews})` }}
                >
                  <div className="rating-avatar-container">
                    <div className="rating-avatar background-primary">
                      <RxAvatar className="rating-avatar-icon" />
                    </div>
                    <p className="my-5">
                      {review?.student?.name || "Unknown User"}
                    </p>
                  </div>
                  <Rating
                    value={review?.rating}
                    cancel={false}
                    className="rating-stars"
                  />
                  <div className="review-text">{`“${review?.review_text}”`}</div>
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={nextSlide}
            className="rating-slider-button slider-button next"
          >
            <i className="pi pi-arrow-right" />
          </button>
        </div>
      ) : (
        <div className="text-center fw-bold my-5">
          No Review For This Course Yet{" "}
        </div>
      )}
    </div>
  );
};

export default RatingComponent;
