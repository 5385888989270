// App.js
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-quill/dist/quill.snow.css"; // Import the Quill styles
import Home from "./Pages/Home";
import LessonsBooking from "./Pages/LessonsBooking";
import { Toast } from "primereact/toast";
import { useContext, useEffect, useRef, useState } from "react";
import AllSettings from "./Pages/AllSettings";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import { useAuth } from "./contexts/AuthContext";
import AdminHome from "./Pages/AdminHome";
import AdminRoute from "./Components/routes/AdminRoute.js";
import AllCourses from "./Pages/AllCourses.jsx";
// import AllOrders from "./Pages/AllOrders.jsx";
import Courses from "./Pages/Courses.jsx";
import CourseDetail from "./Pages/CourseDetail.jsx";
import AllAreas from "./Pages/AllAreas.jsx";
import "../src/loader.js";
import { initializeFacebookPixel } from "./services/facebookPixel.js";
import ThankYouPage from "./Pages/ThankYouPage.jsx";
import { AbilityContext } from "./configs/Ability-context.js";
import { Ability } from "@casl/ability";
import AllRoles from "./Pages/AllRoles.jsx";
import AllPermissions from "./Pages/AllPermissions.jsx";
import EditPermission from "./Pages/EditPermission.js";
import AllUsers from "./Pages/AllUsers.js";
import Role from "./Pages/Role.jsx";
import { UserContext, userProps } from "./contexts/UserContext.js";
import InstructorProfile from "./Components/InstructorProfile.jsx";
import EditUser from "./Pages/EditUser.jsx";
import AllJobs from "./Pages/AllJobs.jsx";
import StudentProfile from "./Components/StudentProfile.js";
import SecurePayment from "./Pages/SecurePayment.jsx";
import HostedPage from "./Pages/HostedPage.jsx";
import SetPassword from "./Pages/SetPassword.js";
import { EmailVerification } from "./Pages/VerifyEmail.jsx";
import FAQPage from "./Pages/FaqPage.jsx";
import PrivacyPolicy from "./Pages/PrivacyPolicy.jsx";
import TermsAndConditions from "./Pages/TermsandConditions.jsx";
import ContactUs from "./Pages/ContactUs.jsx";
import AboutUs from "./Pages/AboutUs.jsx";
import ServicesPage from "./Pages/Services.jsx";
import InstructorJobs from "./Pages/InstructorSideBarPages/InstructorJobs.jsx";
import InstructorLessons from "./Pages/InstructorSideBarPages/InstructorLessons.jsx";
import InstructorPayments from "./Pages/InstructorSideBarPages/InstructorPayments.jsx";
import StudentPayments from "./Pages/StudentSidebarPages/StudentPayments.jsx";
import StudentLessons from "./Pages/StudentSidebarPages/StudentLessons.jsx";
import StudentCourses from "./Pages/StudentSidebarPages/StudentCourses.jsx";
import CourseRecommender from "./Pages/CourseRecommender.jsx";
import TagManager from "react-gtm-module";
import {
  NewPasswordForm,
  ProcessResetPassword,
  ResetPassword,
} from "./Pages/ResetPassword.jsx";
import AllCoupons from "./Pages/AllCoupons.jsx";
import MyAccount from "./Pages/MyAccount.jsx";
import AllLessons from "./Pages/AllLessons.jsx";
import InstructorLearners from "./Pages/InstructorSideBarPages/InstructorLearners.jsx";
import { GlobalContext } from "./contexts/GlobalContext.js";
import Blog from "./Pages/Blog.jsx";
import PostDetail from "./Pages/PostDetail.jsx";
import WPCourses from "./Pages/WPCourses.jsx";
import NotFound from "./Components/NotFound.jsx";
import Reviews from "./Pages/Reviews.jsx";
import AllReviews from "./Pages/AllReviews.jsx";
const INITIAL_STATE = {
  isUsingDemoDB: false,
  isLoggedIn: false,
  emailVerified: false,
  accountApproved: false,
  user: null,
  isLoading: true,
};
function App({ initialData }) {
  const [GTMID, setGTMID] = useState("");
  const [whatsAppNumber, setWhatsAppNumber] = useState();
  const [pixelLoaded, setPixelLoaded] = useState(false);

  const { allSettings } = useContext(GlobalContext);

  const UpdateRules = async (user, loggedIn, settings) => {
    let isEmailVerified = user && user.email_verified;
    let isAccountApproved = user && user.account_approved ? true : false;
    // user && user.account_approved === "approved" ? true : false;
    // console.log("user", user);

    // console.log("isEmailVerified", isEmailVerified);
    // console.log("isAccountApproved", isAccountApproved);

    // console.log("setting rules");
    settings && settings.length
      ? setState((prevState) => ({
          ...prevState,
          user,
          isLoggedIn: loggedIn,
          emailVerified: isEmailVerified,
          accountApproved: isAccountApproved,
          isLoading: false,
          appSettings: appSettings,
        }))
      : setState((prevState) => ({
          ...prevState,
          // isSellerLoggedIn,
          // isShopManagerLoggedIn,
          // isSellerManagerLoggedIn,
          user,
          isLoggedIn: loggedIn,
          emailVerified: isEmailVerified,
          accountApproved: isAccountApproved,
          isLoading: false,
        }));
  };
  let toast = useRef(null);
  const [collapsed, setCollapsed] = useState(false);
  const userRole = localStorage.getItem("userRole");
  const { logout } = useAuth();
  const handleToggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const [
    {
      isLoggedIn,
      emailVerified,
      accountApproved,
      user,
      updateRules,
      appSettings,
    },
    setState,
  ] = useState({
    ...INITIAL_STATE,
    isSellerLoggedIn: userProps.isSellerLoggedIn,
    isShopManagerLoggedIn: userProps.isShopManagerLoggedIn,
    isSellerManagerLoggedIn: userProps.isSellerManagerLoggedIn,
    updateRules: UpdateRules,
    // appSettings: appProps.AppSettings,
  });
  useEffect(() => {
    if (allSettings.length > 0) {
      const wn = allSettings.find(
        (setting) => setting.name === "whatsapp_number"
      )?.value;
      setWhatsAppNumber(wn);

      const gtmId = allSettings.find(
        (setting) => setting.name === "gtm_id"
      )?.value; // Adjust the setting name based on your actual data
      setGTMID(gtmId);

      const pixelId = allSettings.find(
        (setting) => setting.name === "facebook_pixel_id"
      )?.value;

      if (pixelId && !pixelLoaded) {
        initializeFacebookPixel(pixelId);
        setPixelLoaded(true); // To prevent re-initialization
      }

      // console.log(whatsAppNumber, GTMID);
    }
  }, [allSettings, pixelLoaded, whatsAppNumber, GTMID]);
  useEffect(() => {
    if (allSettings.length > 0) {
      const hs =
        allSettings.find((setting) => setting.name === "header_script")
          ?.long_value || "";
      const bs =
        allSettings.find((setting) => setting.name === "body_script")
          ?.long_value || "";
      const fs =
        allSettings.find((setting) => setting.name === "footer_script")
          ?.long_value || "";

      if (hs) {
        const headerScriptTag = document.createElement("script");
        headerScriptTag.type = "text/javascript";
        headerScriptTag.text = hs;
        document.head.appendChild(headerScriptTag);
      }
      if (bs) {
        const bodyScriptTag = document.createElement("script");
        bodyScriptTag.type = "text/javascript";
        bodyScriptTag.text = bs;
        document.body.appendChild(bodyScriptTag);
      }

      if (fs) {
        const footerScriptTag = document.createElement("script");
        footerScriptTag.type = "text/javascript";
        footerScriptTag.text = fs;
        const footerElement = document.querySelector("footer");
        if (footerElement) {
          footerElement.appendChild(footerScriptTag);
        } else {
          document.body.appendChild(footerScriptTag);
        }
      }
    }
  }, [allSettings]);

  let navigate = useNavigate();
  useEffect(() => {
    const checkUserAuth = () => {
      fetch("/api/isAuth")
        .then((res) => res.json())
        .then((json) => {
          let {
            success,
            // isUsingDemoDB,
            user,
            loggedIn,
            settings,
          } = json;
          // console.log(json);
          // const settingWhatsAppNumber = json.settings.filter(
          //   (setting) => setting.name === "whatsapp_number"
          // );
          // setWhatsAppNumber(settingWhatsAppNumber[0].value);
          // console.log(whatsAppNumber);
          if (
            success &&
            // !isUsingDemoDB&&
            user !== undefined &&
            user !== null &&
            loggedIn
          ) {
            localStorage.setItem("ui", JSON.stringify(user.id));
          } else {
            // !loggedIn && clearLocalStorage() && handleLogOut();
            // !loggedIn && handleLogOut();
            !loggedIn && logout();
          }
          updateRules(user, loggedIn, settings);
        })
        .catch((err) => {
          console.log(err);
          // changeValue("isLoading", false);
        });
    };
    checkUserAuth();
  }, [updateRules, logout]);

  // const logout = () => setState((prevState) => ({ ...prevState, isLoggedIn: false }));
  const handleLogOut = async () => {
    await fetch("/api/auth/logout")
      .then((res) => res.json())
      .then((json) => {
        // console.log(json)
        clearLocalStorage();
        logout();
        // console.log("logout");
        setState((prevState) => ({
          ...prevState,
          isLoggedIn: false,
          emailVerified: false,
          accountApproved: false,
          // isSellerLoggedIn: false,
          // isShopManagerLoggedIn: false,
        }));
        navigate("/");
      })
      .catch((err) => console.log(err));
    // console.log("isLoggedIn in handle logout", isLoggedIn);
  };
  useEffect(() => {
    // Initialize GTM here

    const tagManagerArgs = {
      gtmId: GTMID,
      // gtmId: "GTM-TXVFDSPQ", // Replace with your GTM ID
      // gtmId: "GTM-XXXXXXX", // Replace with your GTM ID
    };
    try {
      if (GTMID.length >= 1) TagManager.initialize(tagManagerArgs);
    } catch (err) {
      console.log(err);
    }
  }, [GTMID]);
  // const handleLogOut = () => {
  //   // fetch("/api/auth/logout")
  //   //   .then((res) => res.json())
  //   //   .then((json) => {
  //   // console.log(json)
  //   logout();
  //   clearLocalStorage();
  //   window.location.replace("/");
  //   // navigate("/");
  //   // })
  //   // .catch((err) => console.log(err));
  // };

  // const { isLoggedIn, login, logout, userId, userRole } = useAuth();

  // const rules =
  //   userId && isLoggedIn
  //     ? userRole &&
  //       userRole.permissions
  //         .filter((p) => p.enabled)
  //         .map((p) => {
  //           delete p.enabled;
  //           return p;
  //         })
  //     : [];
  const rules =
    user && isLoggedIn
      ? user.role &&
        user.role.permissions
          .filter((p) => p.enabled)
          .map((p) => {
            // Create a shallow copy of the permission object
            const { enabled, ...rest } = p;
            return rest;
          })
      : [];
  // const rules =
  //   user && isLoggedIn
  //     ? user.role &&
  //       user.role.permissions
  //         .filter((p) => p.enabled)
  //         .map((p) => {
  //           delete p.enabled;
  //           return p;
  //         })
  //     : [];
  // console.log("rules", rules);
  // console.log("isLoggedIn", isLoggedIn);
  // const stripePromise = loadStripe(
  //   "pk_test_51LudtJBXUR2lNEPeljNwPvilRpmIdhFswpoyQdePUC7KQtkWWShl48nOAUzvdlfIixtWjlAIjYXTyr2fx2tbXadK0090pr7awS"
  // );

  return (
    <div className="">
      {/* <h1 className="text-black">{JSON.stringify("rules", rules)}</h1> */}
      {/* <AbilityContext.Provider value={ability}> */}

      <AbilityContext.Provider value={new Ability(rules)}>
        <UserContext.Provider
          value={{
            isLoggedIn,
            // isSellerLoggedIn,
            // isShopManagerLoggedIn,
            // isSellerManagerLoggedIn,
            emailVerified,
            accountApproved,
            updateRules,
          }}
        >
          <ToastContainer />
          <Toast ref={toast} className="p-mt-5 p-mr-3" />
          <div className={`main-content`}>
            {/* <Header handleLogOut={handleLogOut} isLoggedIn={isLoggedIn} /> */}
            <Routes>
              <Route path="/" element={<Home handleLogOut={handleLogOut} />}>
                <Route path="/secure/payment" element={<SecurePayment />} />
                <Route path="/hosted/payment" element={<HostedPage />} />
                {/* <Route
                path="/stripe-payment"
                element={
                  // <Elements stripe={stripePromise}>
                    <StripeCheckout />
                  // </Elements>
                }
              /> */}
                <Route
                  path="/booking"
                  element={
                    // <Elements stripe={stripePromise}>
                    <LessonsBooking />
                    // </Elements>
                  }
                />
                <Route path="/courses" element={<WPCourses />} />
                {/* <Route path="/booking" element={<LessonsBooking />} /> */}
                {/* <Route path="/booking/:zipCode" element={<LessonsBooking />} /> */}
                <Route path="/auth/login" element={<Login />} />
                <Route path="/auth/instructor/sign-up" element={<SignUp />} />
                <Route path="/order/confirm/:id" element={<ThankYouPage />} />
                {/* <Route
                  path="/courses/"
                  element={<Navigate to="/courses/beginner" replace />}
                /> */}
                <Route path="/courses/:category" element={<Courses />} />
                <Route path="/course/:slug" element={<CourseDetail />} />
                {/* <Route path="/course-detail/:slug" element={<CourseDetail />} /> */}
                {/* <Route
                path="/instructor/profile"
                element={<InstructorProfile />}
              />
              <Route path="/student/profile" element={<StudentProfile />} /> */}
                <Route path="/set-password" element={<SetPassword />} />
                <Route path="/verify_email" element={<EmailVerification />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path="/newpassword" element={<NewPasswordForm />} />
                <Route
                  path="/processresetpassword"
                  element={<ProcessResetPassword />}
                />
                <Route path="/FAQs" element={<FAQPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="/terms-conditions"
                  element={<TermsAndConditions />}
                />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route
                  path="/course-recommender"
                  element={<CourseRecommender />}
                />
                <Route path="/services" element={<ServicesPage />} />
                {/* <Route path="/chatbot" element={<Chatbot />} /> */}
                <Route path="/blogs" element={<Blog />} />
                <Route
                  exact
                  path="/blogs/:slug"
                  element={<PostDetail post={initialData} />}
                />{" "}
                <Route path="/reviews" element={<Reviews />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route
                path="/admin"
                // path="/admin-home"
                element={
                  <AdminRoute>
                    <AdminHome
                      isLoggedIn={isLoggedIn}
                      accountApproved={accountApproved}
                      collapsed={collapsed}
                      handleLogOut={handleLogOut}
                      handleToggleSidebar={handleToggleSidebar}
                    />
                  </AdminRoute>
                }
              >
                <Route path="/admin/users/all" element={<AllUsers />} />
                <Route path="/admin/users/edit/:user" element={<EditUser />} />
                <Route path="/admin/user/roles" element={<AllRoles />} />
                <Route
                  path="/admin/users/roles/edit/:role"
                  element={<Role APO_role="update" />}
                />
                <Route
                  path="/admin/users/roles/new"
                  element={<Role APO_role="create" />}
                />
                <Route
                  path="/admin/user/permissions"
                  element={<AllPermissions />}
                />
                <Route
                  path="/admin/users/permissions/edit/:permission"
                  element={<EditPermission />}
                />
                <Route
                  path="/admin/courses/all"
                  element={<AllCourses collapsed={collapsed} />}
                />
                <Route path="/admin/jobs/all" element={<AllJobs />} />
                <Route path="/admin/areas/all" element={<AllAreas />} />
                <Route path="/admin/settings/all" element={<AllSettings />} />
                <Route path="/admin/coupons/all" element={<AllCoupons />} />
                <Route path="/admin/reviews/all" element={<AllReviews />} />
                {/* <Route path="/admin/orders/all" element={<AllOrders />} /> */}
                <Route path="/admin/lessons/all" element={<AllLessons />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route
                path="/instructor"
                element={
                  <MyAccount
                    accountApproved={accountApproved}
                    collapsed={collapsed}
                    handleLogOut={handleLogOut}
                    handleToggleSidebar={handleToggleSidebar}
                  />
                }
              >
                <Route
                  path="/instructor/jobs"
                  // path="jobs/instructor"
                  element={<InstructorJobs userId={user?.id} />}
                />
                <Route
                  path="/instructor/lessons"
                  // path="lessons/instructor"
                  element={
                    <InstructorLessons userId={user?.id} role={userRole} />
                  }
                />
                <Route
                  path="/instructor/payments"
                  element={
                    <InstructorPayments userId={user?.id} role={userRole} />
                  }
                />
                <Route
                  path="/instructor/learners"
                  element={
                    <InstructorLearners userId={user?.id} role={userRole} />
                  }
                />
                <Route
                  path="/instructor/profile"
                  element={<InstructorProfile />}
                />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route
                path="/student"
                element={
                  <MyAccount
                    accountApproved={accountApproved}
                    collapsed={collapsed}
                    handleLogOut={handleLogOut}
                    handleToggleSidebar={handleToggleSidebar}
                  />
                }
              >
                <Route
                  path="/student/courses"
                  // path="/courses/student"
                  element={<StudentCourses userId={user?.id} />}
                />
                <Route
                  path="/student/lessons"
                  // path="/lessons/student"
                  element={<StudentLessons userId={user?.id} role={userRole} />}
                />
                <Route
                  path="/student/payments"
                  // path="/payments/student"
                  element={
                    <StudentPayments userId={user?.id} role={userRole} />
                  }
                />
                <Route path="/student/profile" element={<StudentProfile />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              {/* <Route path="*" element={<NotFound />} /> */}
            </Routes>
            <a
              href={`https://wa.me/${whatsAppNumber}`}
              // href="https://wa.me/your-number"
              rel="noreferrer"
              target="_blank"
              className="whatsapp-link"
            >
              <i
                className="pi pi-whatsapp"
                style={{ fontSize: "3em", color: "#25D366" }}
              ></i>
            </a>
            {/* <whas */}
            {/* <FaWhatsapp className="float-end" /> */}
            {/* <Footer /> */}
          </div>
        </UserContext.Provider>
      </AbilityContext.Provider>
    </div>
  );
}

export default App;

const clearLocalStorage = () => {
  localStorage.getItem("ui") && localStorage.removeItem("ui");
};
