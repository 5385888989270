import React, { useState } from "react";
import "../../styles/PackageSelectStep.css";
import { RadioButton } from "primereact/radiobutton";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import CouponsViewModal from "../../Pages/Modals/CouponsViewModal";

const PackageSelectStep = ({
  input,
  handleChange,
  userCategory,
  handleSwitchChange,
  courses,
  selectedCourseId,
  selectedCourse,
  setSelectedCourseId,
  couponCode,
  setCouponCode,
  handleCoupon,
  discount,
  handleCouponRemove,
}) => {
  const refresherCourses = courses.filter(
    (course) => course.category === "Refresher"
  );
  const intermediateCourses = courses.filter(
    (course) => course.category === "Intermediate"
  );
  const beginnerCourses = courses.filter(
    (course) => course.category === "Beginner"
  );
  const [showCouponModal, setShowCouponModal] = useState(false);

  const handleCourseClick = (id) => {
    setSelectedCourseId(id);
    // console.log("Course clicked:", id);
  };

  return (
    <div className="w-full" style={{ opacity: 1, backgroundColor: "" }}>
      <div
        className="mb-4"
        style={{
          borderRadius: "10px",
          padding: "10px",
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        }}
      >
        <div className="align-items-center w-100 justify-content-center col-6">
          <div className="d-flex align-items-center justify-content-center mt-5">
            <div
              className="select-container"
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <div className="p-field-radiobutton ">
                <strong>Pick a gearbox </strong>
                <RadioButton
                  inputId="manual"
                  name="transmission"
                  value="manual"
                  onChange={handleChange}
                  checked={input.transmission === "manual"}
                />
                <label htmlFor="manual" className="mx-2">
                  Manual
                </label>
                <RadioButton
                  inputId="automatic"
                  name="transmission"
                  value="automatic"
                  onChange={handleChange}
                  checked={input.transmission === "automatic"}
                />
                <label htmlFor="automatic" className="mx-2">
                  Automatic
                </label>
                <div>
                  <p className="side-title">Add Theory Test?</p>
                  <InputSwitch
                    style={{ marginLeft: "30px" }}
                    checked={input.wantTheoryTest}
                    onChange={(e) =>
                      handleSwitchChange("wantTheoryTest", e.value)
                    }
                    // onChange={(e) => setWantTheoryTest(e.value)}
                  />
                </div>
                <div style={{}}>
                  <p className="side-title"> Add Practical Test?</p>
                  <InputSwitch
                    style={{ marginLeft: "30px" }}
                    className="switch"
                    checked={input.wantPracticalTest}
                    onChange={(e) =>
                      handleSwitchChange("wantPracticalTest", e.value)
                    }

                    // onChange={(e) => setWantPracticalTest(e.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {(userCategory === "refresher" || userCategory === "") && (
            <>
              <CourseSection
                title="Refresher Courses"
                courses={refresherCourses}
                selectedCourseId={selectedCourseId}
                handleCourseClick={handleCourseClick}
                selectedCourse={selectedCourse}
              />
            </>
          )}
          {(userCategory === "intermediate" || userCategory === "") && (
            <>
              <CourseSection
                title="Intermediate Courses"
                courses={intermediateCourses}
                selectedCourseId={selectedCourseId}
                handleCourseClick={handleCourseClick}
                selectedCourse={selectedCourse}
              />
              <hr />
            </>
          )}
          {(userCategory === "beginner" || userCategory === "") && (
            <>
              <CourseSection
                title="Beginner Courses"
                courses={beginnerCourses}
                selectedCourseId={selectedCourseId}
                handleCourseClick={handleCourseClick}
                selectedCourse={selectedCourse}
              />
            </>
          )}
        </div>
        {/* {selectedCourseId && discount === 0 && ( */}
        {selectedCourseId && (
          <div className="d-flex flex-column align-items-center">
            <label className="fw-bolder mb-2 ">Use Promo Code</label>

            <div>
              <InputText
                // name="postcode" // Added name attribute
                placeholder="Enter Promo Code"
                // id="postcode"
                type="text" // Changed to text for better handling of zip codes
                value={couponCode}
                onChange={(e) => {
                  setCouponCode(e.target.value);
                }}
                required
                style={{ borderRadius: "10px" }}
                disabled={discount > 0 ? true : false}
              />
              {discount <= 0 ? (
                <button
                  disabled={discount > 0 ? true : false}
                  type="button"
                  onClick={handleCoupon}
                  className="button-secondary px-3 py-2 mt-1 mx-2"
                  // className="btn text-white mx-2"
                  // style={{ backgroundColor: "#33b5e5" }}
                >
                  Apply
                </button>
              ) : (
                <span
                  className=" px-2 py-2 mt-3 mb-2 text-decoration-underline cursor-pointer "
                  onClick={handleCouponRemove}
                >
                  Change Promo
                </span>
              )}
            </div>
            {/* {discount > 1 && (
              <span
                className=" px-2 py-2 mt-3 mb-2 text-decoration-underline cursor-pointer "
                onClick={handleCouponRemove}
              >
                Change Promo
              </span>
            )} */}
            <span
              className=" px-2 py-2 mt-3 mb-2 text-decoration-underline cursor-pointer "
              onClick={() => {
                setShowCouponModal(true);
              }}
            >
              View Available Promos
            </span>
          </div>
        )}
        {/* {discount > 1 && (
          <div className="d-flex justify-content-end">
            <button
              type="button"
              onClick={handleCouponRemove}
              className="button-tertiary px-3 py-2 mt-1 mx-"
              // className="btn text-white mx-2"
              // style={{ backgroundColor: "#33b5e5" }}
            >
              Change Promo
            </button>
          </div>
        )} */}
      </div>
      <CouponsViewModal
        show={showCouponModal}
        onHide={() => {
          setShowCouponModal(false);
        }}
      />
    </div>
  );
};

const CourseSection = ({
  title,
  courses,
  selectedCourseId,
  handleCourseClick,
  selectedCourse,
}) => (
  <>
    <hr />
    <h4 className="text-center">{title}</h4>
    <div className="course-cards">
      {courses.map((course) => (
        <div
          key={course.id}
          className={`course-card ${
            JSON.parse(selectedCourseId) === course.id ? "selected" : ""
          }`}
          onClick={() => handleCourseClick(course.id)}
        >
          {/* {selectedCourseId} */}
          <div className="course-card-content">
            <span style={{ marginRight: "7px" }}>{course.hours} Hours</span>
            <strong>
              £{course.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </strong>
          </div>
        </div>
      ))}
      {selectedCourse && selectedCourse.category === courses[0]?.category && (
        <div className="course-description">
          <p>{selectedCourse.hours} Hours Course</p>
          <p>{selectedCourse.description}</p>
        </div>
      )}
    </div>
    <hr />
  </>
);

export default PackageSelectStep;

// import React, { useEffect, useState } from "react";
// import { getAllCourses } from "../../services/courses";
// import "../../styles/PackageSelectStep.css";
// import { RadioButton } from "primereact/radiobutton";
// const PackageSelectStep = ({
//   input,
//   handleChange,
//   handleManualChange,
//   userCategory,
//   PreSelectedcourseId,
//   setInput,
// }) => {
//   const [courses, setCourses] = useState([]);
//   // const [selectedCourseId, setSelectedCourseId] = useState(null);
//   const [selectedCourseId, setSelectedCourseId] = useState(
//     PreSelectedcourseId ? PreSelectedcourseId : null
//   );
//   const [selectedCourse, setSelectedCourse] = useState();
//   // const [userCategory, setUserCategory] = useState("");
//   // const [selectedCourse,setSelectedCourse]=useState(null)
//   // const [selectedCourseIndex,setSelectedCourseIndex]=useState(null)
//   // const [selectedCoursePrice,setSelectedCoursePrice]=useState(null)
//   // const [selectedCourseDuration,setSelectedCourseDuration]=useState(null)
//   const getCourses = async () => {
//     await getAllCourses()
//       .then((data) => {
//         setCourses(data);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
//   useEffect(() => {
//     if (selectedCourseId !== null) {
//       // const course = courses.find((course) => course.id === selectedCourseId);
//       // const course = courses.find((course) => course.id === selectedCourseId);
//       const course = courses.find((course) => {
//         console.log(
//           "Checking course ID:",
//           course.id,
//           "against selectedCourseId:",
//           selectedCourseId
//         );
//         return course.id === JSON.parse(selectedCourseId); // Ensure the comparison is correct
//       });
//       setSelectedCourse(course);
//       console.log("selectedCourseId", selectedCourseId);
//       console.log("cousres", courses);
//       console.log("cousre", selectedCourse);
//       handleManualChange("courseId", selectedCourseId);
//       // handleManualChange("price", course.price);
//     }
//   }, [selectedCourseId, courses, PreSelectedcourseId]);
//   useEffect(() => {
//     if (selectedCourse) {
//       console.log("selectedCourse", selectedCourse);
//       handleManualChange("price", selectedCourse.price);
//     }
//   }, [selectedCourse]);
//   useEffect(() => {
//     getCourses();
//   }, []);

//   const refresherCourses = courses.filter(
//     (course) => course.category === "Refresher"
//   );
//   const intermediateCourses = courses.filter(
//     (course) => course.category === "Intermediate"
//   );
//   const beginnerCourses = courses.filter(
//     (course) => course.category === "Beginner"
//   );
//   const handleCourseClick = async (id) => {
//     setSelectedCourseId(id);
//     console.log(selectedCourseId);
//   };

//   // const handleReset = () => {
//   //   setUserCategory("");
//   //   setSelectedCourse();
//   //   setSelectedCourseId(null);
//   //   handleManualChange("courseId", "");
//   // };
//   const handleTransmissionChange = (e) => {
//     // setInput({ transmission: e.target.value });
//     // handleManualChange("transmission", transmission);
//   };
//   return (
//     <div className="w-full" style={{ opacity: 1, backgroundColor: "" }}>
//       {/* <div>
//         {courses.map((course) => (
//           <div key={course.id}>
//             <h3>{course.name}</h3>
//             <h3>{course.id}</h3>
//             <p>{course.category}</p>
//             <p>{course.price}</p>
//             {/* Add more course details as needed */}
//       {/* </div>
//         ))} */}
//       {/* </div> */}
//       {/* {selectedCourse && selectedCourse.description} */}
//       {/* {selectedCourseId} */}
//       {/* {selectedCourse} */}
//       {/* {JSON.stringify(selectedCourse)} */}
//       <div
//         className="mb-4"
//         style={{
//           borderRadius: "10px",
//           padding: "10px",
//           // margin: "auto",
//           boxShadow: "0 0 10px rgba(0,0,0,0.1)",
//         }}
//       >
//         {" "}
//         {/* <div className="row"> */}
//         <div
//           className="align-items-center w-100 justify-content-center col-6"
//           style={
//             {
//               // marginLeft: "25%",
//             }
//           }
//         >
//           {/* <h5 className="driving-text text-center"> </h5> */}
//           {/* {"transmission" + input.transmission} */}
//           <div className="d-flex align-items-center justify-content-center mt-5">
//             <div
//               className="select-container"
//               style={{
//                 display: "flex",
//                 alignItems: "flex-start",
//                 justifyContent: "center",
//               }}
//             >
//               <div className="p-field-radiobutton ">
//                 <strong>Pick a gearbox </strong>
//                 <RadioButton
//                   inputId="manual"
//                   name="transmission"
//                   value="manual"
//                   onChange={handleChange}
//                   checked={input.transmission === "manual"}
//                 />
//                 <label htmlFor="manual" className="mx-2">
//                   Manual
//                 </label>
//                 <RadioButton
//                   inputId="automatic"
//                   name="transmission"
//                   value="automatic"
//                   onChange={handleChange}
//                   checked={input.transmission === "automatic"}
//                 />
//                 <label htmlFor="automatic" className="mx-2">
//                   Automatic
//                 </label>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* </div> */}
//         <div>
//           {/* <h4>Select your course</h4> */}
//           {(userCategory === "refresher" || userCategory === "") && (
//             <>
//               <CourseSection
//                 title="Refresher Courses"
//                 courses={refresherCourses}
//                 selectedCourseId={selectedCourseId}
//                 handleCourseClick={handleCourseClick}
//                 selectedCourse={selectedCourse}
//               />{" "}
//             </>
//             /* <>
//             <h4 className="text-center">Refresher Courses</h4>
//             <div className="course-row">
//               {refresherCourses.map((course) => (
//                 <div
//                   key={course.id}
//                   className={`course-card ${
//                     selectedCourseId === course.id ? "selected" : ""
//                   }`}
//                   onClick={() => handleCourseClick(course.id)}
//                 >
//                   <h3>{course.name}</h3>
//                   <p>
//                     {" "}
//                     {course.hours} <p>Hours</p>
//                   </p>
//                   <p>
//                     £
//                     {course.price
//                       .toString()
//                       .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
//                   </p>{" "}
//                 </div>
//               ))}
//               {selectedCourse && selectedCourse.category === "Refresher" && (
//                 <div className="course-description">
//                   <p>{selectedCourse.hours} Hours</p>
//                   <p>{selectedCourse.description}</p>
//                 </div>
//               )}
//             </div>
//           </> */
//           )}
//           {(userCategory === "intermediate" || userCategory === "") && (
//             <>
//               <h4 className="text-center">Intermediate Courses</h4>
//               <div className="course-cards">
//                 {intermediateCourses.map((course) => (
//                   <div
//                     key={course.id}
//                     className={`course-card ${
//                       selectedCourseId === course.id ? "selected" : ""
//                     }`}
//                     onClick={() => handleCourseClick(course.id)}
//                   >
//                     <div className="course-card-content">
//                       {/* <h3>{course.name}</h3> */}
//                       <span style={{ marginRight: "7px" }}>
//                         {course.hours} Hours
//                       </span>
//                       <strong>
//                         £
//                         {course.price
//                           .toString()
//                           .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
//                       </strong>
//                     </div>
//                     {/* <h3>{course.name}</h3>
//                     <p>
//                       {" "}
//                       {course.hours} <p>Hours</p>
//                     </p>
//                     <p>
//                       £
//                       {course.price
//                         .toString()
//                         .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
//                     </p> */}
//                   </div>
//                 ))}
//                 {selectedCourse &&
//                   selectedCourse.category === "Intermediate" && (
//                     <div className="course-description">
//                       <p>{selectedCourse.hours} Hours Course</p>
//                       <p>{selectedCourse.description}</p>
//                     </div>
//                   )}
//               </div>
//               <hr />
//             </>
//           )}
//           {(userCategory === "beginner" || userCategory === "") && (
//             <>
//               <h4 className="text-center">Beginner Courses</h4>
//               <div className="course-cards">
//                 {beginnerCourses.map((course) => (
//                   <div
//                     key={course.id}
//                     className={`course-card ${
//                       selectedCourseId === course.id ? "selected" : ""
//                     }`}
//                     onClick={() => handleCourseClick(course.id)}
//                   >
//                     <div className="course-card-content">
//                       {/* <h3>{course.name}</h3> */}
//                       <span style={{ marginRight: "7px" }}>
//                         {course.hours} Hours
//                       </span>
//                       <strong>
//                         £
//                         {course.price
//                           .toString()
//                           .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
//                       </strong>
//                     </div>
//                   </div>
//                 ))}
//                 {selectedCourse && selectedCourse.category === "Beginner" && (
//                   <div className="course-description">
//                     <p>{selectedCourse.hours} Hours Course</p>
//                     <p>{selectedCourse.description}</p>
//                   </div>
//                 )}
//               </div>
//             </>
//           )}
//         </div>
//       </div>
//       {/* <div className="mb-10 transmission-type">
//         <div className="grid grid-cols-2 gap-4 ">
//           <button
//             className={`w-full flex items-center text-left py-4 px-5  border text-secondary cursor-pointer  ${
//               input.transmission === "manual"
//                 ? "transmission-selected"
//                 : "bg-pmfGray"
//             }`}
//             onClick={() => handleTransmissionChange("manual")}
//           >
//             <div className=" w-full flex justify-between items-center">
//               <div className="flex items-center">
//                 <div className="pr-5 false">
//                   <svg
//                     width={19}
//                     height={19}
//                     viewBox="0 0 24 24"
//                     fill="none"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       fillRule="evenodd"
//                       clipRule="evenodd"
//                       d="M23 12C23 18.075 18.075 23 12 23C5.925 23 1 18.075 1 12C1 5.925 5.925 1 12 1C18.075 1 23 5.925 23 12Z"
//                       stroke="currentColor"
//                       strokeWidth={2}
//                     />
//                     <path
//                       fillRule="evenodd"
//                       clipRule="evenodd"
//                       d="M23 12C23 18.075 18.075 23 12 23C5.925 23 1 18.075 1 12C1 5.925 5.925 1 12 1C18.075 1 23 5.925 23 12Z"
//                       stroke="currentColor"
//                       strokeWidth={2}
//                     />
//                     <path
//                       d="M16.5 16.5V8.5L12 13.18L7.5 8.5V16.5"
//                       stroke="currentColor"
//                       strokeWidth={2}
//                     />
//                   </svg>
//                 </div>
//                 <div className="">
//                   <div className=" false">Manual</div>
//                 </div>
//               </div>
//             </div>
//           </button>
//           <button
//             className={`w-full flex items-center text-left py-4 px-5  border text-secondary cursor-pointer  ${
//               input.transmission === "automatic"
//                 ? "transmission-selected"
//                 : "bg-pmfGray"
//             }`}
//             onClick={() => handleTransmissionChange("automatic")}
//           >
//             <div className=" w-full flex justify-between items-center">
//               <div className="flex items-center">
//                 <div className="pr-5 false">
//                   <svg
//                     width={19}
//                     height={19}
//                     viewBox="0 0 24 24"
//                     fill="none"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       fillRule="evenodd"
//                       clipRule="evenodd"
//                       d="M23 12C23 18.075 18.075 23 12 23C5.925 23 1 18.075 1 12C1 5.925 5.925 1 12 1C18.075 1 23 5.925 23 12Z"
//                       stroke="currentColor"
//                       strokeWidth={2}
//                     />
//                     <path
//                       d="M17.4593 16.7279L12.0003 7.2719L6.5403 16.7279"
//                       stroke="currentColor"
//                       strokeWidth={2}
//                     />
//                     <path
//                       d="M8.3145 13.4275H15.6855"
//                       stroke="currentColor"
//                       strokeWidth={2}
//                     />
//                   </svg>
//                 </div>
//                 <div className="">
//                   <div className=" false">Automatic</div>
//                 </div>
//               </div>
//             </div>
//           </button>
//         </div>
//       </div> */}

//       {/* <div className="row">
//         <div
//           className={`col-md-6 gear-choice ${
//             input.transmission === "manual" ? "selected" : ""
//           }`}
//           onClick={() => handleTransmissionChange("manual")}
//         >
//           Manual
//         </div>
//         <div
//           className={`col-md-6 gear-choice ${
//             input.transmission === "automatic" ? "selected" : ""
//           }`}
//           onClick={() => handleTransmissionChange("automatic")}
//         >
//           Automatic
//         </div>
//       </div> */}
//       {/* <div className=" mb-10">
//         <h4 className="driving-text">How's your driving going so far?</h4>
//         <button
//           className={`w-full flex items-center text-left py-4 px-5 rounded-lg border font-semibold text-secondary cursor-pointer mb-3 ${
//             userCategory === "" ? "userCategory-selected" : ""
//           } `}
//           onClick={handleReset}
//         >
//           <div className=" w-full flex justify-between items-center">
//             <div className="flex items-center">
//               <div className="pr-5 false ">
//                 <svg
//                   className="user-category-icon"
//                   viewBox="0 0 18 22"
//                   fill="none"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     d="M0.75 12.5L11.25 1.25L9 9.5H17.25L6.75 20.75L9 12.5H0.75Z"
//                     fill="#ADFF00"
//                     stroke="black"
//                     strokeWidth="1.5"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                   />
//                 </svg>
//               </div>
//               <div className="">
//                 <div className="user-category">I know what course I want</div>
//               </div>
//             </div>
//           </div>
//         </button>
//         <button
//             className={`w-full flex items-center text-left py-4 px-5 rounded-lg border font-semibold text-secondary cursor-pointer mb-3 ${
//             userCategory === "beginner" ? "userCategory-selected" : ""
//           } `}
//           onClick={() => {
//             setUserCategory("beginner");
//           }}
//         >
//           <div className="flex items-center">
//             <div className="pr-5">
//               {" "}
//               <svg
//                 className="user-category-icon"
//                 viewBox="0 0 22 22"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M17 8.75L17.259 7.715C17.4073 7.12147 17.7142 6.57941 18.1468 6.14681C18.5794 5.71421 19.1215 5.40733 19.715 5.259L20.75 5L19.715 4.741C19.1215 4.59267 18.5794 4.28579 18.1468 3.85319C17.7142 3.42059 17.4073 2.87854 17.259 2.285L17 1.25L16.741 2.285C16.5927 2.87841 16.286 3.42038 15.8536 3.85297C15.4212 4.28556 14.8794 4.59251 14.286 4.741L13.25 5L14.286 5.259C14.8794 5.40749 15.4212 5.71444 15.8536 6.14703C16.286 6.57962 16.5927 7.12159 16.741 7.715L17 8.75Z"
//                   fill="#DCA4FF"
//                   stroke="black"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 />
//                 <path
//                   d="M8 17.75L8.813 14.904L8.814 14.903C9.02413 14.1679 9.4181 13.4984 9.95874 12.9577C10.4994 12.4171 11.1689 12.0231 11.904 11.813L14.75 11L11.903 10.186C11.1679 9.97587 10.4984 9.5819 9.95774 9.04126C9.4171 8.50062 9.02313 7.83114 8.813 7.096L8 4.25L7.186 7.097C6.97587 7.83214 6.5819 8.50162 6.04126 9.04226C5.50062 9.5829 4.83114 9.97687 4.096 10.187L1.25 11L4.097 11.814C4.83214 12.0241 5.50162 12.4181 6.04226 12.9587C6.5829 13.4994 6.97687 14.1689 7.187 14.904L8 17.75Z"
//                   fill="#7B66FF"
//                   stroke="black"
//                   strokeWidth="1.5"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 />
//                 <path
//                   d="M15.894 19.567L15.5 20.75L15.106 19.567C14.9955 19.2356 14.8094 18.9345 14.5625 18.6875C14.3155 18.4406 14.0144 18.2545 13.683 18.144L12.5 17.75L13.683 17.356C14.0144 17.2455 14.3155 17.0594 14.5625 16.8125C14.8094 16.5655 14.9955 16.2644 15.106 15.933L15.5 14.75L15.894 15.933C16.0045 16.2644 16.1906 16.5655 16.4375 16.8125C16.6845 17.0594 16.9856 17.2455 17.317 17.356L18.5 17.75L17.317 18.144C16.9856 18.2545 16.6845 18.4406 16.4375 18.6875C16.1906 18.9345 16.0045 19.2356 15.894 19.567Z"
//                   fill="#EBCAFF"
//                   stroke="black"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 />
//               </svg>
//             </div>
//             <div>
//               <div className="user-category">I'm just getting started</div>
//             </div>
//           </div>
//           <div
//             className="bg-opacity-25 py-1 px-3 text-xs rounded-full ml-auto user-category"
//             style={{ backgroundColor: "pink", borderRadius: "15px" }}
//           >
//             beginner
//           </div>
//         </button>
//         <button
//          className={`w-full flex items-center text-left py-4 px-5 rounded-lg border font-semibold text-secondary cursor-pointer mb-3 ${
//             userCategory === "intermediate" ? "userCategory-selected" : ""
//           } `}
//           onClick={() => {
//             setUserCategory("intermediate");
//           }}
//         >
//           <div className="flex items-center">
//             <div className="pr-5">
//               {" "}
//               <svg
//                 className="user-category-icon"
//                 viewBox="0 0 20 20"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M13.182 13.182C12.7641 13.5999 12.2681 13.9313 11.7221 14.1575C11.1761 14.3836 10.591 14.5 10 14.5C9.40905 14.5 8.82388 14.3836 8.27791 14.1575C7.73194 13.9313 7.23586 13.5999 6.818 13.182M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM7.75 7.75C7.75 8.164 7.582 8.5 7.375 8.5C7.168 8.5 7 8.164 7 7.75C7 7.336 7.168 7 7.375 7C7.582 7 7.75 7.336 7.75 7.75ZM7.375 7.75H7.383V7.765H7.375V7.75ZM13 7.75C13 8.164 12.832 8.5 12.625 8.5C12.418 8.5 12.25 8.164 12.25 7.75C12.25 7.336 12.418 7 12.625 7C12.832 7 13 7.336 13 7.75ZM12.625 7.75H12.633V7.765H12.625V7.75Z"
//                   fill="#FFC700"
//                 />
//                 <path
//                   d="M13.182 13.182C12.7641 13.5999 12.2681 13.9313 11.7221 14.1575C11.1761 14.3836 10.591 14.5 10 14.5C9.40905 14.5 8.82388 14.3836 8.27791 14.1575C7.73194 13.9313 7.23586 13.5999 6.818 13.182M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM7.75 7.75C7.75 8.164 7.582 8.5 7.375 8.5C7.168 8.5 7 8.164 7 7.75C7 7.336 7.168 7 7.375 7C7.582 7 7.75 7.336 7.75 7.75ZM7.375 7.75H7.383V7.765H7.375V7.75ZM13 7.75C13 8.164 12.832 8.5 12.625 8.5C12.418 8.5 12.25 8.164 12.25 7.75C12.25 7.336 12.418 7 12.625 7C12.832 7 13 7.336 13 7.75ZM12.625 7.75H12.633V7.765H12.625V7.75Z"
//                   stroke="black"
//                   strokeWidth="1.5"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 />
//               </svg>
//             </div>
//             <div>
//               <div className="user-category">I'm doing well</div>
//             </div>
//           </div>
//           <div
//             className="bg-opacity-25 py-1 px-3 text-xs rounded-full ml-auto user-category"
//             style={{ backgroundColor: "bisque", borderRadius: "15px" }}
//           >
//             intermediate
//           </div>
//         </button>
//         <button
//           className={`w-full flex items-center text-left py-4 px-5 rounded-lg border font-semibold text-secondary cursor-pointer mb-3 ${
//             userCategory === "refresher" ? "userCategory-selected" : ""
//           } `}
//           onClick={() => {
//             setUserCategory("refresher");
//           }}
//         >
//           <div className="flex items-center">
//             <div className="pr-5">
//               {" "}
//               <svg
//                 width={24}
//                 height={24}
//                 viewBox="0 0 18 21"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M12.362 4.214C14.0869 4.98477 15.495 6.32406 16.3512 8.00814C17.2074 9.69222 17.4599 11.6191 17.0663 13.4669C16.6728 15.3147 15.6571 16.9715 14.189 18.1606C12.7209 19.3496 10.8892 19.9989 9 20C7.38401 19.9999 5.80363 19.5253 4.45502 18.6349C3.10642 17.7446 2.04898 16.4778 1.41398 14.9918C0.778983 13.5058 0.59438 11.8661 0.88309 10.2761C1.1718 8.68608 1.92111 7.21589 3.038 6.048C3.80766 7.12273 4.82326 7.99775 6 8.6C6.02061 7.27863 6.33252 5.97808 6.91348 4.79109C7.49444 3.6041 8.33015 2.55992 9.361 1.733C10.1477 2.78819 11.1767 3.63876 12.361 4.213L12.362 4.214Z"
//                   fill="#FFB800"
//                   stroke="black"
//                   strokeWidth="1.5"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 />
//                 <path
//                   d="M9 17C9.95138 16.9996 10.8671 16.6377 11.5616 15.9875C12.2561 15.3372 12.6775 14.4473 12.7404 13.498C12.8033 12.5488 12.5031 11.611 11.9005 10.8748C11.2979 10.1386 10.438 9.65894 9.495 9.533C8.46257 10.4568 7.78229 11.71 7.57 13.079C6.79967 12.8905 6.07458 12.5506 5.437 12.079C5.25185 12.6423 5.20291 13.2414 5.29418 13.8273C5.38545 14.4131 5.61434 14.969 5.96206 15.4492C6.30978 15.9295 6.76642 16.3204 7.29451 16.59C7.8226 16.8596 8.40708 17.0001 9 17V17Z"
//                   fill="#F15937"
//                   stroke="black"
//                   strokeWidth="1.5"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 />
//               </svg>
//             </div>
//             <div>
//               <div className="user-category">I'm nearly test ready</div>
//             </div>
//           </div>
//           <div
//             className="bg-opacity-25 py-1 px-3 text-xs rounded-full ml-auto user-category"
//             style={{ backgroundColor: "#5abac7", borderRadius: "15px" }}
//           >
//             refresher
//           </div>
//         </button>
//       </div> */}

//       {/* {selectedCourseId && (
//         <button className=" w-full  mb-5 uppercase relative items-center justify-center px-4  py-2 rounded-md continue-button ">
//           <span className="flex items-center">
//             Continue
//             <span className="ml-4">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width={19}
//                 height={19}
//                 viewBox="0 0 24 24"
//                 fill="none"
//                 stroke="currentColor"
//                 strokeWidth={2}
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//               >
//                 <path d="M5 12h13M12 5l7 7-7 7" />
//               </svg>
//             </span>
//           </span>
//         </button>
//       )} */}
//     </div>
//   );
// };

// const CourseSection = ({
//   title,
//   courses,
//   selectedCourseId,
//   handleCourseClick,
//   selectedCourse,
// }) => (
//   <>
//     <hr />
//     <h4 className="text-center">{title}</h4>
//     <div className="course-cards">
//       {courses.map((course) => (
//         <div
//           key={course.id}
//           className={`course-card ${
//             selectedCourseId === course.id ? "selected" : ""
//           }`}
//           onClick={() => handleCourseClick(course.id)}
//         >
//           <div className="course-card-content">
//             {/* <h3>{course.name}</h3> */}
//             <span style={{ marginRight: "7px" }}>{course.hours} Hours</span>
//             <strong>
//               £{course.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
//             </strong>
//           </div>
//         </div>
//       ))}
//       {selectedCourse && selectedCourse.category === "Refresher" && (
//         <div className="course-description">
//           <p>{selectedCourse.hours} Hours Course</p>
//           <p>{selectedCourse.description}</p>
//         </div>
//       )}
//     </div>
//     <hr />
//   </>
// );
// export default PackageSelectStep;
