import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { CalendarView } from "../../Components/misc/CalendarComponent";
import LessonModal from "../Modals/LessonModal";
import { toast } from "react-toastify";
import { getLessonsByUser } from "../../services/lessons";
import LessonViewModal from "../Modals/LessonViewModal";

const InstructorLessons = ({ userId, role }) => {
  //   const [lessons, setLessons] = useState(initialLessons);
  const [lessons, setLessons] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const id = userId || localStorage.getItem("userId");
  useEffect(() => {
    const fetchLessons = async () => {
      console.log("userId", id);
      // const response = await getLessonsByUser(JSON.parse(role), userId);
      const response = await getLessonsByUser(JSON.parse(role), id);
      setLessons(response);
      // if (!response.ok) {
      //   toast.error("Error in Getting Lessons");
      // }
    };
    fetchLessons();
  }, [userId, refreshData]);

  const handleCreateLesson = (lessonInfo) => {
    // API call to create lesson and update state
    // Mock API response
    const newLesson = { id: lessons.length + 1, ...lessonInfo };
    setLessons([...lessons, newLesson]);

    toast.current.show({
      severity: "success",
      summary: "Lesson Created",
      detail: `Lesson scheduled for ${lessonInfo.date}`,
    });
  };

  const handleRescheduleLesson = (lessonInfo) => {
    // API call to reschedule lesson and update state
    const updatedLessons = lessons.map((lesson) =>
      lesson.id === lessonInfo.id ? lessonInfo : lesson
    );
    setLessons(updatedLessons);

    toast.done("Lesson Resheduled Successfully");
  };

  const handleRescheduleClick = (lesson) => {
    setSelectedLesson(lesson);
    setModalVisible(true);
  };

  return (
    <div className="lesson-screen container">
      <Toast ref={toast} />
      {/* <Button
        label="Create Lesson"
        icon="pi pi-plus"
        onClick={() => setModalVisible(true)}
      /> */}
      {/* {JSON.stringify(lessons)} */}
      <CalendarView
        lessons={lessons}
        onRescheduleClick={handleRescheduleClick}
      />
      <LessonViewModal
        visible={modalVisible}
        onHide={() => {
          setModalVisible(false);
          setSelectedLesson(null);
        }}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
        // onCreate={handleCreateLesson}
        lesson={selectedLesson}
        // orderPopulated={orderPopulated}
      />
      {/* <LessonModal
        visible={modalVisible}
        onHide={() => {
          setModalVisible(false);
          setSelectedLesson(null);
        }}
        // onCreate={handleCreateLesson}
        onReschedule={handleRescheduleLesson}
        lesson={selectedLesson}
        // orderPopulated={orderPopulated}
      />
       */}
    </div>
  );
};

export default InstructorLessons;
