import React, { useEffect, useState } from "react";
import { getAllOrders } from "../../services/orders";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { Chart } from "primereact/chart"; // Assuming you're using Chart.js or similar
import { Card } from "primereact/card";
import { Can } from "../../configs/Ability-context";
import CourseReviewModal from "../Modals/CourseReviewModal";

const StudentCourses = ({ userId }) => {
  const [studentJobs, setStudentJobs] = useState([]);
  const [courseReviewModalVisible, setCourseReviewModalVisible] =
    useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [review, setReview] = useState("");
  const id = userId || localStorage.getItem("userId");

  useEffect(() => {
    const getOrders = async () => {
      try {
        const data = await getAllOrders();
        const filteredJobs = data?.filter((order) => order.customer_id === id);
        setStudentJobs(filteredJobs);
      } catch (err) {
        console.log(err);
      }
    };
    getOrders();
  }, [id]);

  const handleAddCourseReview = (job) => {
    setSelectedJob(job);
    setCourseReviewModalVisible(true);
  };

  const handleSubmitReview = async () => {
    // Implement the review submission logic here
    console.log("Review submitted:", review);
    setSelectedJob(null);
  };
  const calculateCompletionPercentage = (lessons, courseHours) => {
    if (!lessons || !courseHours) return 0;

    const completedHours = lessons
      .filter((lesson) => lesson.status === "completed")
      .reduce((acc, lesson) => acc + lesson.duration / 60, 0); // Convert duration from minutes to hours

    const percentage = (completedHours / courseHours) * 100;
    return Math.min(100, Math.max(0, percentage)); // Ensure the value is between 0 and 100
  };

  const getProgressData = (job) => {
    const courseHours = job?.order_detail?.course?.hours;
    const lessons = job?.lessons;

    const completionPercentage = calculateCompletionPercentage(
      lessons,
      courseHours
    );
    return {
      labels: ["Completed", "Remaining"],
      datasets: [
        {
          data: [completionPercentage, 100 - completionPercentage],
          backgroundColor: ["#42A5F5", "#FF6384"],
        },
      ],
    };
  };

  return (
    <Can I="manage" a="studentProfile">
      <div className="container">
        <h1>Your Courses</h1>
        {studentJobs.map((job) => (
          <Card
            key={job.id}
            title={job?.order_detail?.course?.hours + " Hours Course"}
            className="p-mb-3"
          >
            <p>{job?.id}</p>
            <p>{job?.order_detail?.course?.description}</p>
            {job.status === "completed" && (
              <Button
                onClick={() => handleAddCourseReview(job)}
                label="Give Course Review"
              />
            )}
            <div>
              <h3>Progress:</h3>
              <Chart
                type="doughnut"
                className="w-full md:w-10rem"
                data={getProgressData(job)}
              />
            </div>
            {}
          </Card>
        ))}

        {selectedJob && (
          <div className="review-modal">
            <h2>Leave a Review for {selectedJob.title}</h2>
            <textarea
              value={review}
              onChange={(e) => setReview(e.target.value)}
              placeholder="Write your review here..."
            />
            <Button onClick={handleSubmitReview} label="Submit Review" />
            <Button
              onClick={() => setSelectedJob(null)}
              label="Cancel"
              className="p-button-secondary"
            />
          </div>
        )}
        <CourseReviewModal
          visible={courseReviewModalVisible}
          onHide={() => setCourseReviewModalVisible(false)}
          job={selectedJob}
        />
      </div>
    </Can>
  );
};

export default StudentCourses;
