import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabView, TabPanel } from "primereact/tabview";
import { Toast } from "primereact/toast";
import "../../styles/instructorPayments.css";
import { getPayementsByUser } from "../../services/payments.js";
// import { formatDate } from "../../Components/custom/Helpers";

const InstructorPayments = ({ userId, role }) => {
  const [allPayments, setAllPayments] = useState([]);
  const [coursePayments, setCoursePayments] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const toast = useRef(null);

  const id = userId || localStorage.getItem("userId");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPayementsByUser(JSON.parse(role), id);
        setAllPayments(response);
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error fetching payment data",
        });
      }
    };

    fetchData();
  }, []);

  const handleCourseChange = (course) => {
    setSelectedCourse(course);
    // Fetch payments specific to selected course if needed
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  }
  const dateBody = (rowData) => {
    return <div>{formatDate(rowData?.payment_date)}</div>;
  };

  return (
    <div className="payment-page container">
      <Toast ref={toast} />

      <h2>Payments Overview</h2>

      <TabView>
        <TabPanel>
          {/* <div className="p-d-flex p-jc-end"> */}
          {/* <Button
            label="View All Payments"
            icon="pi pi-eye"
            className="p-button-outlined w-25"
          /> */}
          {/* </div> */}
          <DataTable value={allPayments} paginator rows={10}>
            <Column
              field="payment_date"
              header="Date"
              sortable
              body={dateBody}
            />
            <Column field="amount" header="Amount" sortable />
            <Column field="payment_method" header="Method" sortable />
            <Column field="order_id" header="Job" sortable />
          </DataTable>
        </TabPanel>

        {/* <TabPanel header="Course-wise Payments"> */}
        {/* <div className="p-d-flex p-jc-end">
            <Button
              label="View Course Payments"
              icon="pi pi-eye"
              className="p-button-outlined"
            />
          </div> */}
        {/* <DataTable value={coursePayments} paginator rows={10}>
            <Column field="course" header="Course" sortable />
            <Column field="date" header="Date" sortable />
            <Column field="amount" header="Amount" sortable />
            <Column field="status" header="Status" sortable />
          </DataTable>
        </TabPanel> */}
      </TabView>
    </div>
  );
};

export default InstructorPayments;
