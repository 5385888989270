import React from "react";
import Sidebar from "../Components/Sidebar";
import { Outlet, Route, Routes } from "react-router-dom";
import InstructorJobs from "./InstructorSideBarPages/InstructorJobs";
import InstructorLessons from "./InstructorSideBarPages/InstructorLessons";
import InstructorPayments from "./InstructorSideBarPages/InstructorPayments";
import LoggedInHeader from "../Components/LoggedInHeader";
import ResponsiveSidebar from "../Components/ResponsiveSidebar";

const AdminHome = ({
  accountApproved,
  collapsed,
  handleLogOut,
  handleToggleSidebar,
}) => {
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  return (
    <div>
      <div className="layout">
        <LoggedInHeader handleLogOut={handleLogOut} />
        {/* <ResponsiveSidebar /> */}
        {userRole === `"admin"` || accountApproved ? (
          <Sidebar
            userId={userId}
            userRole={userRole}
            handleLogOut={handleLogOut}
            collapsed={collapsed}
            onToggleSidebar={handleToggleSidebar}
          />
        ) : null}
        {/* <header className="layout-header">
          <Menubar end={end} /> */}
        <main className="layout-content">
          <Outlet />
        </main>
        {/* </header> */}
      </div>
      {/* <Routes>
        <Route
          path="/jobs/instructor"
          element={<InstructorJobs userId={userId} />}
        />
        <Route
          path="/lessons/instructor"
          element={<InstructorLessons userId={userId} role={userRole} />}
        />
        <Route
          path="/payments/instructor"
          element={<InstructorPayments userId={userId} role={userRole} />}
        />
      </Routes> */}
    </div>
  );
};

export default AdminHome;

// import React, { useState } from "react";
// import "../styles/AdminHome.css";
// import HeroSection from "../Components/HeroSection";
// import AfterHeroSection from "../Components/AfterHeroSection";
// import BenifitsSection from "../Components/BenifitsSection";
// import Aside from "../Components/Aside";
// import Sidebar from "../Components/Sidebar";
// import { checkUserRole } from "../Components/custom/Helpers";

// const AdminHome = () => {
//   // const [collapsed, setCollapsed] = useState(false);
//   const [collapsed, setCollapsed] = useState(true);
//   // const [collapsed, setCollapsed] = useState((window.screen.width < 769 && window.screen.width > 576) ? true : false);
//   const handleToggleSidebar = () => {
//     setCollapsed(!collapsed);
//   };
//   const userRole = localStorage.getItem("userRole");

//   let role = checkUserRole(userRole, "admin");
//   return userRole === `"admin"` ? (
//     <>
//       <HeroSection />

//       <div className={`home ${collapsed ? "collapsed" : ""}`}>
//         {/* <Sidebar collapsed={collapsed} onToggleSidebar={handleToggleSidebar} /> */}
//         {/* <Aside
//         collapsed={collapsed}
//         toggled={toggled}
//         handleToggleSidebar={handleToggleSidebar}
//         handleCollapsedChange={handleCollapsedChange}
//       /> */}
//         {/* <h2>{role}</h2>
//       <h3>{userRole}</h3> */}
//         {/* <HeroSection /> */}
//         <AfterHeroSection />
//         <span>
//           <BenifitsSection />
//         </span>
//         {/* <ExtraBenifitSection /> */}
//       </div>
//     </>
//   ) : (
//     <>
//       {/* <h2>{role}</h2>
//       <h3>{userRole}</h3> */}
//       <div>you are not allowed to see this page</div>
//     </>
//   );
// };

// export default AdminHome;
