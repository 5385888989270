import React, { useState, useEffect, useRef, useContext } from "react";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Link, useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { toast } from "react-toastify";
import { getInstructorLearners } from "../../services/users";
import { formatToCamelCase } from "../../Components/custom/Helpers";
import UserDetailsModal from "../Modals/UserDetailsModal";
import { NewCustomListing } from "../../Components/custom/NewCustomListing";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import AddPrivateLearnerModal from "../Modals/AddPrivateLearnerModal";
import { TabPanel, TabView } from "primereact/tabview";
import { Card } from "primereact/card";
import { FaEnvelope, FaLocationArrow, FaMapPin, FaPhone } from "react-icons/fa";
import "../../styles/InstructorLearners.css";
import LessonModal from "../Modals/LessonModal";
const InstructorLearners = (props) => {
  let dt = useRef(null);
  let navigate = useNavigate();

  let contextMenu = useRef(null);
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [showUserDetailsDialog, setShowUserDetailsDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserRole, setSelectedUserRole] = useState(null);
  const [accountApproval, setAccountApproval] = useState(false);
  const [visible, setVisible] = useState(false);
  const [
    {
      allUsers,
      isLoading,
      // refreshData,
      selectedAction,
      selectedSubject,
      selectedEnabled,
      trashView,
      selectedRecord,
      selectedRecords,
    },
    setState,
  ] = useState({
    allUsers: [],
    isLoading: false,
    // refreshData: false,
    selectedAction: null,
    selectedSubject: null,
    selectedEnabled: null,
    trashView: false,
    selectedRecords: [],
  });
  const [showLessonModal, setShowLessonModal] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [userPopulated, setUserPopulated] = useState();
  const instructorId = localStorage.getItem("userId");
  const obfuscate = (value) => btoa(value);

  useEffect(() => {
    const fetchData = async () => {
      changeValue("isLoading", true);
      const { data } = await getInstructorLearners(props.userId);
      // console.log(data);
      const assignedStudents = data?.assignedStudents;
      const sortedUsers = assignedStudents?.sort((a, b) => b.id - a.id) || [];

      setState((prevState) => ({
        ...prevState,
        allUsers: sortedUsers,
        isLoading: false,
        trashView: false,
      }));
    };
    fetchData();
  }, [refreshData, props.userId]);
  const openGoogleMaps = (postcode) => {
    const query = `${encodeURIComponent(postcode)},+${encodeURIComponent(
      "UK"
    )}`;
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${query}`;
    // const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    //   postcode
    // )}`;
    window.open(googleMapsUrl, "_blank"); // Opens the link in a new tab
  };
  const changeValue = (name, value) => {
    // _isMounted
    //  &&
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const approveUser = async () => {
    try {
      const response = await fetch(
        `/api/users/account-approval/${selectedUserId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ accountApproval }),
        }
      );
      const result = await response.json();
      if (response.ok) {
        setRefreshData(!refreshData);
        // changeValue("refreshData", !refreshData); // Refresh data to reflect the changes
        setShowApproveDialog(false);
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error("Error approving user:", error);
    }
  };
  const handleEdit = (id) => (e) => {
    navigate(`/admin/users/edit/${id}`);
  };
  const openApproveDialog = (userId) => {
    const user = allUsers.find((user) => user.id === userId);
    if (user) {
      setAccountApproval(!user.account_approved);
      setSelectedUserId(userId);
      setShowApproveDialog(true);
    }
  };

  const handleCreateLesson = async (lessonData) => {
    try {
      console.log(lessonData);

      // Call API to create lesson with lessonData
      const response = await fetch("/api/instructor/create-user-lesson", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(lessonData),
      });
      console.log(response);
      if (response.status === 201) {
        toast.success("Lesson Created Successfully");
      }
      //   alert(response);
      // Handle success
    } catch (error) {
      console.error(error);
      alert("Failed to create lesson");
    }
  };
  const closeApproveDialog = () => {
    setShowApproveDialog(false);
    setSelectedUserId(null);
  };
  const learnerCard = (user) => {
    return (
      <div>
        <Card className="learner-card">
          <div className="d-flex">
            <p className="learner-name">{user?.name}</p>
            <div className="learner-contact-icons">
              <span
                className="contact-icon"
                onClick={() => (window.location = `mailto:${user?.email}`)}
              >
                <FaEnvelope />
              </span>
              <span
                className="contact-icon"
                onClick={() => (window.location = `tel:${user?.phone}`)}
              >
                <FaPhone />
              </span>
            </div>
          </div>
          <p className="horizantal-line" />
          {/* <hr /> */}
          <div className="learner-address">
            <p>
              <FaLocationArrow className="icon" />
              <strong>Address: </strong>
              {user?.address}
            </p>
          </div>
          <div className="learner-postcode">
            <p onClick={() => openGoogleMaps(user?.postcode)}>
              <FaMapPin className="icon" />
              <strong>Postcode: </strong>
              <span>{user?.postcode}</span>
            </p>
          </div>
        </Card>
      </div>
    );
  };

  // const learnerCard = (user) => {
  //   return (
  //     <div>
  //       <Card
  //         className="card px-2 mx-3  my-3 "
  //         style={{
  //           display: "flex",
  //           flexDirection: "row",
  //           // alignItems: "center",
  //           // justifyContent: "center",
  //           // backgroundColor: "#8ECAE6", // Light blue background
  //           borderRadius: "15px",
  //           width: "300px",
  //           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  //           transition: "transform 0.3s ease, box-shadow 0.3s ease",
  //           cursor: "pointer",
  //           border: "2px solid #8ECAE6", // Border color for contrast
  //         }}
  //       >
  //         {/* User Info */}
  //         <p
  //           className="fs-2 fw-bolder"
  //           style={{ marginBottom: "8px", color: "#023047" }}
  //         >
  //           {user?.name}
  //         </p>{" "}
  //         <div className="d-flex justify-content-end">
  //           <span
  //             className="mx-1 d-flex justify-content-center align-content-center"
  //             style={{
  //               color: "#023047",
  //               fontSize: "14px",
  //               margin: "0 0 5px 0",
  //               backgroundColor: "lightgray",
  //               padding: "5px",
  //               borderRadius: "9999px",
  //             }}
  //             onClick={() => (window.location = `mailto:${user?.email}`)}
  //           >
  //             {/* <i className="pi pi-envelope"></i> */}
  //             <FaEnvelope />
  //             {/* <strong>Email: </strong> */}
  //             {/* {user?.email} */}
  //           </span>
  //           <span
  //             className="mx-1 d-flex justify-content-center align-content-center"
  //             style={{
  //               color: "#023047",
  //               fontSize: "14px",
  //               margin: "0 0 5px 0",
  //               backgroundColor: "lightgray",
  //               padding: "5px",
  //               borderRadius: "9999px",
  //               cursor: "pointer",
  //             }}
  //             onClick={() => (window.location = `tel:${user?.phone}`)}
  //           >
  //             <FaPhone />
  //             {/* <strong>Phone: </strong>
  //         {user?.phone} */}
  //           </span>
  //         </div>
  //         <div className="d-flex align-items-center my-2 ">
  //           <p
  //             style={{
  //               color: "#023047",
  //               fontSize: "14px",
  //               margin: "0 0 5px 0",
  //             }}
  //           >
  //             <FaLocationArrow style={{ marginRight: "5px" }} />
  //             <strong>Address: </strong>
  //             {user?.address}
  //           </p>
  //         </div>
  //         <div className="d-flex align-items-center my-2 ">
  //           <p
  //             style={{ color: "#023047", fontSize: "14px", margin: "0" }}
  //             onClick={() => openGoogleMaps(user?.postcode)}
  //           >
  //             <FaMapPin style={{ marginRight: "5px" }} />
  //             <strong>Postcode: </strong>
  //             <span>{user?.postcode}</span>
  //           </p>
  //         </div>
  //       </Card>
  //     </div>
  //   );
  // };
  const EmptyCard = (user) => {
    return (
      <div className="empty-card-container">
        <button
          className="button-secondary py-2 px-2"
          style={{ fontSize: "17px" }}
        >
          <i className="pi pi-plus px-1"></i>
          <span className=" fw-semibold">Add New Learner</span>
        </button>
        {/* <Card
          className="empty-card"
          onClick={() => {
            setVisible(true);
          }}
        > */}
        {/* <div
            className="empty-card-content"
            onClick={() => {
              setVisible(true);
            }}
          >
            <i className="pi pi-plus"></i>
            <span className="fw-bold mx-2">Add New Learner</span>
          </div> */}
        {/* </Card> */}
      </div>
    );
  };
  const ActionBody = (rowData) => {
    return (
      <div>
        <span
          className=" cursor-pointer p-2"
          style={{
            backgroundColor: "#219EBC ",
            color: "white",
            borderRadius: "0px 5px 5px 0px",
          }}
          onClick={() => {
            setShowLessonModal(true);
            setUserPopulated(
              allUsers.find((user) => {
                return user.id === rowData.id;
              })
            );
          }}
        >
          <i className="pi pi-plus mx-2" style={{ color: "white" }}></i>
          Create Lesson
        </span>
      </div>
    );
  };
  const ApprovalBody = (rowData) => {
    return (
      <InputSwitch
        checked={rowData.account_approved}
        onChange={(e) => openApproveDialog(rowData.id)}
      />
    );
  };

  const RoleBody = (rowData) => {
    const formattedRole = formatToCamelCase(rowData?.role?.name);
    return <span>{formattedRole}</span>;
  };
  // const ActionBody = (rowData) => {
  //   const readonly = obfuscate("true");
  //   const profileUrl =
  //     rowData?.role?.name === "instructor"
  //       ? `/instructor/profile?userId=${encodeURIComponent(
  //           rowData?.id
  //         )}&readonly=${encodeURIComponent(readonly)}`
  //       : `/student/profile?userId=${encodeURIComponent(
  //           rowData?.id
  //         )}&readonly=${encodeURIComponent(readonly)}`;
  //   return (
  //     <div>
  //       <Link
  //         to={profileUrl}
  //         className=" cursor-pointer p-2"
  //         style={{
  //           backgroundColor: "#219EBC ",
  //           color: "white",
  //           borderRadius: "5px 0px 0px 5px",
  //           width: "25px",
  //           margin: "1px",
  //         }}
  //       >
  //         <i className="pi pi-eye" style={{ color: "white" }}></i>
  //       </Link>
  //     </div>
  //   );
  // };
  const postcodeBody = (rowData) => {
    return (
      <span
        style={{
          // color: "blue",
          cursor: "pointer",
          // textDecoration: "underline",
        }}
        onClick={() => openGoogleMaps(rowData.postcode)}
      >
        {rowData.postcode}
      </span>
    );
  };
  let columns = [
    {
      field: "email",
      header: "Email",
      sortable: true,
      filter: true,
      selectedByDefault: true,
    },
    {
      field: "postcode",
      header: "PostCode",
      sortable: true,
      filter: true,
      body: postcodeBody,
      selectedByDefault: true,
      filterPlaceholder: "Search by slug",
      filterMatchMode: "contains",
    },
    {
      field: "phone",
      header: "Phone",
      sortable: true,
      filter: true,
      selectedByDefault: true,
      filterPlaceholder: "Search by slug",
      filterMatchMode: "contains",
    },
    {
      header: "User Action",
      body: (rowData) => ActionBody(rowData),
      selectedByDefault: true,
      headerStyle: { width: "290px" },
    },
    // {
    //   field: "account_approved",
    //   header: "Account Approval",
    //   sortable: true,
    //   filter: true,
    //   // body: ApprovalBody,
    //   body: (rowData) => ApprovalBody(rowData),
    //   selectedByDefault: true,
    //   filterPlaceholder: "Search by slug",
    //   filterMatchMode: "contains",
    // },
    // {
    //   header: "User Action",
    //   body: (rowData) => ActionBody(rowData),
    //   selectedByDefault: true,
    //   headerStyle: { width: "290px" },
    // },
    // {
    //   field: "role.name",
    //   header: "Role",
    //   sortable: true,
    //   filter: true,
    //   body: (rowData) => RoleBody(rowData),
    //   selectedByDefault: true,
    //   filterPlaceholder: "Search by slug",
    //   filterMatchMode: "contains",
    // },
  ];

  return (
    // <Can I="read" a="persons">
    <>
      <UserDetailsModal
        userId={selectedUserId}
        userRole={selectedUserRole}
        visible={showUserDetailsDialog}
        onHide={() => {
          setShowUserDetailsDialog(false);
          setSelectedUserId(null);
          setSelectedUserRole(null);
        }}
      />
      <AddPrivateLearnerModal
        visible={visible}
        setVisible={setVisible}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
      />
      <LessonModal
        visible={showLessonModal}
        onHide={() => setShowLessonModal(false)}
        onCreate={handleCreateLesson}
        userPopulated={userPopulated}
        // maxDuration={}
      />
      <TabView className="container-md">
        <TabPanel
          header="List View"
          // leftIcon={<ListIcon />}
          // rightIcon={<ArrowRightIcon />}
        >
          {" "}
          <NewCustomListing
            allRecords={allUsers}
            heading={trashView ? "Users (trash)" : "Learners"}
            firstColumn={
              <Column
                field="name"
                header="Name"
                sortable={true}
                filter={true}
                style={{ borderBottom: "1px solid grey" }}
                headerStyle={{ width: "170px" }}
              />
            }
            columns={columns}
            permissionSubject={"users"}
            isLoading={isLoading}
            // handleRefresh={
            //   trashView ? false : () => changeValue("refreshData", !refreshData)
            // }
            handleAdd={() => {
              setVisible(true);
            }}
            // handleEdit={true}
            customEditButton={(customButton, rowData) => {
              return (
                !(rowData.action === "manage" && rowData.subject === "all") &&
                customButton(
                  "pi pi-pencil",
                  handleEdit(rowData.id),
                  false,
                  "edit"
                )
              );
            }}
            // handleEmptyTrash={trashView ? handleEmptyTrash : null}
            // handleRestoreAll={trashView ? handleRestoreAll : null}
            // handleTrash={
            //   trashView
            //     ? () => changeValue("refreshData", !refreshData)
            //     : handleGetTrash
            // }
            // extraButton={
            //   trashView
            //     ? (customButton, rowData) => {
            //         return ability.can("restore", "products")
            //           ? customButton(
            //               "pi pi-refresh color-primary-light",
            //               // "fas fa-undo-alt fa-sm color-primary",
            //               (e) => {
            //                 handleRestore(rowData.id)(e);
            //               },
            //               false,
            //               "restore this record"
            //             )
            //           : null;
            //       }
            //     : ""
            // }
            selectedRecords={selectedRecords}
            selectionDataKey={"id"}
            contextMenuRef={contextMenu}
            // contextMenuModel={menuModel}
            contextMenuSelection={selectedRecord}
            trashLabel={trashView ? "Users" : `Trash`}
            trashIcon={trashView ? "pi pi-refresh" : "pi pi-trash"}
            // handleDelete={handleMoveToTrash}
            deleteDataKey={"id"}
            tableSmall
            tableRef={dt}
            timeStamps={true}
          />
        </TabPanel>
        <TabPanel header="GridView">
          <h2 className="text-center">My Learners</h2>
          <EmptyCard />
          <div className="d-flex flex-wrap align-items-center">
            {/* <div className="d-flex flex-row flex-wrap learner-card-container"> */}
            {allUsers.map((user) => learnerCard(user))}
            {/* </div> */}
            {/* <EmptyCard /> */}
          </div>
          {/* Code For Empty Card for option of adding the new learner  */}
        </TabPanel>
      </TabView>

      <Dialog
        header="Approve User"
        visible={showApproveDialog}
        onHide={closeApproveDialog}
      >
        <p>
          Are you sure you want to {!accountApproval ? "disapprove" : "approve"}{" "}
          this user?
        </p>
        <Button label="Yes" onClick={() => approveUser()} />
        <Button
          label="No"
          onClick={closeApproveDialog}
          className="p-button-secondary"
        />
      </Dialog>
    </>
    // </Can>
  );
};

export default InstructorLearners;
