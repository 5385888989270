export const getLessonsByUser = async (role, id) => {
  try {
    const response = await fetch(`/api/get-user-lessons/${role}/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const getAllLessons = async (role, id) => {
  try {
    const response = await fetch(`/api/get-lessons`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
};
// src/services/lessons.js

export const updateLessonStatus = async (lessonId, status, note) => {
  try {
    const response = await fetch(`/api/lessons/${lessonId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status, note }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }

    const data = await response.json();
    console.log("Lesson status updated:", data);
    return data;
  } catch (error) {
    console.error("Error updating lesson status:", error);
  }
};
