import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { getBussinessReviews } from "../services/businessReviews";
import { toast } from "react-toastify";
import { ReviewCard } from "../Components/UI/ReviewCard";
import { ProgressBar } from "primereact/progressbar";
import StarRatings from "react-star-ratings";
import AddReviewModal from "../Components/AddReviewModal";
import { Helmet } from "react-helmet-async";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [sortedReviews, setSortedReviews] = useState([]);
  const [sortOption, setSortOption] = useState("newest");
  const [aggregateRating, setAggregateRating] = useState(0);
  const [addreviewModalVisible, setReviewModalVisible] = useState(false);

  useEffect(() => {
    const getReviews = async () => {
      await getBussinessReviews().then((res) => {
        const filteredReviews = res.filter(
          (review) => review.status === "approved"
        );
        setReviews(filteredReviews);
        if (res?.length === 0) {
          setAggregateRating(0); // No reviews, set rating to 0
          return;
        }
        // Calculate total rating and guard against undefined aggregate_rating
        const totalRating = res.reduce((sum, review) => {
          return sum + (review.aggregate_rating || 0); // Handle missing/undefined ratings
        }, 0);
        // Set aggregateRating as a number
        setAggregateRating(Number((totalRating / res?.length).toFixed(1)));
        setSortedReviews(res);
      });
    };
    getReviews();
  }, [refreshData]);
  useEffect(() => {
    const sortReviews = () => {
      let sorted = [...reviews]; // Create a copy of reviews to sort

      switch (sortOption) {
        case "newest":
          sorted = sorted.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          break;
        case "highest":
          sorted = sorted.sort(
            (a, b) => b.aggregate_rating - a.aggregate_rating
          );
          break;
        case "lowest":
          sorted = sorted.sort(
            (a, b) => a.aggregate_rating - b.aggregate_rating
          );
          break;
        //   case 'all':
        //     default:
        //       sorted = [...reviews]; // Do nothing, just reset to the original order
        default:
          break;
      }
      setSortedReviews(sorted); // Update sortedReviews with the sorted array
    };
    sortReviews();
  }, [sortOption, reviews]);
  const handleSortChange = (e) => {
    setSortOption(e.target.value); // Update the sort option when the user changes it
  };
  // const validateForm = () => {
  //   const newErrors = {};
  //   const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Simple email regex

  //   if (!userName) newErrors.userName = "Name is required";
  //   if (!userEmail) newErrors.userEmail = "Email is required";
  //   else if (!emailPattern.test(userEmail))
  //     newErrors.userEmail = "Invalid email address";
  //   if (!rating) newErrors.rating = "Rating is required";
  //   if (!reviewText) newErrors.reviewText = "Review cannot be empty";

  //   setErrors(newErrors);
  //   return Object.keys(newErrors)?.length === 0;
  // };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   let userId;
  //   if (validateForm()) {
  //     await createUser({
  //       user,
  //     }).then((res) => {
  //       userId = res.user.id;
  //       console.log(userId);
  //     });
  //     userId &&
  //       (await addBussinessReview({
  //         rating,
  //         reviewText,
  //         userId,
  //       })
  //         .then(() => {
  //           setReviewModalVisible(false);
  //           setUserName("");
  //           setUserEmail("");
  //           setRating(0);
  //           setReviewText("");
  //           toast.success("Review Added", { autoClose: 200 });
  //         })
  //         .catch((err) => {
  //           setUserName("");
  //           setUserEmail("");
  //           setRating(0);
  //           setReviewText("");
  //           toast.error("Error Adding Review", { autoClose: 200 });
  //           console.error(err);
  //         }));
  //   }
  // };
  //   const calculateAggregateRating = () => {
  //     if (reviews.length === 0) return 0;
  //     const totalRating = reviews.reduce(
  //       (sum, review) => sum + review.aggregate_rating,
  //       0
  //     );
  //     return (totalRating / reviews.length).toFixed(1);
  //   };
  //   const displayValueTemplate = (value, total) => {
  //     const percentage = ((value / total) * 100).toFixed(1);
  //     console.log(`${value} (${percentage}%)`);
  //     // return percentage;
  //     return `${value} (${percentage}%)`;
  //   };
  const totalReviews = reviews?.length;
  const fiveStarReviews = (
    (reviews.filter((review) => review.aggregate_rating === 5)?.length /
      totalReviews) *
    100
  ).toFixed(1);
  const fourStarReviews = (
    (reviews.filter((review) => review.aggregate_rating === 4)?.length /
      totalReviews) *
    100
  ).toFixed(1);
  const threeStarReviews = (
    (reviews.filter((review) => review.aggregate_rating === 3)?.length /
      totalReviews) *
    100
  ).toFixed(1);
  const twoStarReviews = (
    (reviews.filter((review) => review.aggregate_rating === 2)?.length /
      totalReviews) *
    100
  ).toFixed(1);
  const oneStarReviews = (
    (reviews.filter((review) => review.aggregate_rating === 1)?.length /
      totalReviews) *
    100
  ).toFixed(1);

  // prettier-ignore
  const reviewsSchema = reviews.map((review) => ({
    "@context": "https://schema.org",
    "@type": "Review",
    "itemReviewed": {
      "@type": "LocalBusiness",
      "@id": "https://pass-swiftly.com/#business",       
      "name": "Pass Swiftly Driving School",
      "address": "3 Loganswell Drive, Thornliebank, Glasgow, Scotland"
    },
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": review.aggregate_rating,
      "bestRating": 5
    },
    "author": {
      "@type": "Person",
      "name": review?.user?.name || ""
    },
    "datePublished": review.createdAt,
    "reviewBody": review?.review_text || ""
  }));

  return (
    <div className="container-md mt-5">
      <h1 className="text-center my-4">Reviews</h1>
      <Helmet>
        <title>
          What Our Students Say | Driving School Reviews | Pass Swiftly
        </title>
        <meta
          name="description"
          content="Read authentic reviews from Pass Swiftly students about their learning experiences, course satisfaction, and journey to becoming safe drivers. See why we’re trusted across the UK!"
        />
        <script type="application/ld+json">
          {JSON.stringify(reviewsSchema)}
        </script>
      </Helmet>
      {/* <div className="d-flex justify-content-center align-content-center align-items-center flex-column container-md"> */}
      <div className="d-grid justify-content-center align-content-center align-items-center flex-column container-md">
        <div className="row">
          <div className="col-md-6">
            <div className=" d-flex justify-content-center flex-column align-items-center mb-4">
              <span
                className="d-flex flex-row align-items-center "
                style={{ marginBottom: "5px" }}
              >
                {" "}
                5
                <ProgressBar
                  value={fiveStarReviews}
                  displayValueTemplate={() => null}
                  style={{
                    height: "10px",
                    width: "20%",
                    minWidth: "200px",
                    marginLeft: "10px",
                  }}
                />
              </span>{" "}
              <span
                className="d-flex flex-row align-items-center "
                style={{ marginBottom: "5px" }}
              >
                4
                <ProgressBar
                  value={fourStarReviews}
                  displayValueTemplate={() => null}
                  style={{
                    height: "10px",
                    width: "20%",
                    marginLeft: "10px",
                    minWidth: "200px",
                  }}
                />
              </span>{" "}
              <span
                className="d-flex flex-row align-items-center "
                style={{ marginBottom: "5px" }}
              >
                3
                <ProgressBar
                  value={threeStarReviews}
                  displayValueTemplate={() => null}
                  style={{
                    height: "10px",
                    width: "20%",
                    marginLeft: "10px",
                    minWidth: "200px",
                  }}
                />
              </span>{" "}
              <span
                className="d-flex flex-row align-items-center "
                style={{ marginBottom: "5px" }}
              >
                2
                <ProgressBar
                  value={twoStarReviews}
                  displayValueTemplate={() => null}
                  style={{
                    height: "10px",
                    width: "20%",
                    marginLeft: "10px",
                    minWidth: "200px",
                  }}
                />
              </span>{" "}
              <span
                className="d-flex flex-row align-items-center "
                style={{ marginBottom: "5px" }}
              >
                1
                <ProgressBar
                  value={oneStarReviews}
                  displayValueTemplate={() => null}
                  style={{
                    height: "10px",
                    width: "20%",
                    marginLeft: "10px",
                    minWidth: "200px",
                  }}
                />
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="text-center mb-4">
              <div className="h2  mb-2">
                {aggregateRating} <br />
              </div>
              <div className="mb-2 ">
                <StarRatings
                  rating={aggregateRating}
                  starRatedColor="#fcc603"
                  numberOfStars={5} // Total number of stars
                  starDimension="30px"
                  starSpacing="5px"
                />
                <br />
              </div>
              <div>
                <span className="">{totalReviews} reviews </span>
                <br />
                <span>Sort Reviews:</span>
                <select
                  value={sortOption}
                  onChange={handleSortChange}
                  className="ml-2"
                >
                  <option value="newest">Newest</option>
                  <option value="highest">Highest Rating</option>
                  <option value="lowest">Lowest Rating</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            label="Write a Review"
            style={{ borderRadius: "200px" }}
            onClick={() => setReviewModalVisible(true)}
          />
        </div>
      </div>

      {/* <p>Business reviews will go here</p> */}
      <div className="d-flex flex-row flex-wrap justify-content-center">
        {/* {reviews.map((review) => ( */}
        {sortedReviews.map((review) => (
          <div
            className="d-flex flex-column"
            style={{ margin: "20px" }}
            key={review.id}
          >
            <ReviewCard review={review} />
          </div>
        ))}
      </div>
      {/* <div className="d-flex justify-content-center">
        <Button
          className="button-primary px-3 py-3"
          label="Add Review"
          icon="pi pi-plus"
          onClick={() => setReviewModalVisible(true)}
        />
      </div> */}
      <AddReviewModal
        visible={addreviewModalVisible}
        setVisible={setReviewModalVisible}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
      />
      {/* <Dialog
        header="Add Review"
        visible={addreviewModalVisible}
        onHide={() => setReviewModalVisible(false)}
        style={{ width: "300px" }}
      >
        <form onSubmit={handleSubmit}>
          <div className="p-fluid d-grid">
            <div className="p-field mt-4">
              <FloatLabel>
                <label htmlFor="name">Name</label>
                <InputText
                  id="name"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  required={true}
                />
                {errors.userName && (
                  <small className="p-error">{errors.userName}</small>
                )}
              </FloatLabel>
            </div>

            <div className="p-field mt-4">
              <FloatLabel>
                <label htmlFor="email">Email</label>
                <InputText
                  id="email"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  required={true}
                />
                {errors.userEmail && (
                  <small className="p-error">{errors.userEmail}</small>
                )}
              </FloatLabel>
            </div>

            <div className="p-field mt-3">
              <Rating
                style={{ placeContent: "center" }}
                value={rating}
                onChange={(e) => setRating(e.value)}
                cancel={false}
                required={true}
              />
              {errors.rating && (
                <small className="p-error">{errors.rating}</small>
              )}
            </div>

            <div className="p-field mt-3">
              <InputTextarea
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
                placeholder="Write Review Here"
                rows={3}
                cols={20}
                autoResize
                required={true}
              />
              {errors.reviewText && (
                <small className="p-error">{errors.reviewText}</small>
              )}
            </div>

            <Button
              label="Submit"
              type="submit"
              //   disabled={!userName || !userEmail || !rating || !reviewText}
            />
          </div>
        </form>
      </Dialog> */}
    </div>
  );
};

export default Reviews;
