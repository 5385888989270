import React from "react";
// import '../.../SummaryCard.css'; // Import your styles
import "../../styles/SummaryCard.css";
const SummaryCard = ({ title, value, icon, color, onClick }) => {
  return (
    <div className="summary-card cursor-pointer" onClick={onClick}>
      <div className="summary-card-content">
        <div className="summary-card-header">
          <span className="summary-card-title">{title}</span>
          <div className="summary-card-icon" style={{ backgroundColor: color }}>
            {icon}
          </div>
        </div>
        <div className="summary-card-value">{value}</div>
      </div>
    </div>
  );
};

export default SummaryCard;
