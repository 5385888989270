import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";

const PostDetail = ({ post }) => {
  // let postResponse;
  const [postHtml, setPostHtml] = useState(post || null); // Use initial post HTML from SSR or null
  const { slug } = useParams();
  const location = useLocation();
  const metaTitle = location.state?.title;
  const metaDescription = location.state?.description;
  useEffect(() => {
    const fetchResponse = async () => {
      const response = await fetch(`/api/wp/${slug}`);
      const textResponse = await response.text();
      setPostHtml(textResponse);
    };
    fetchResponse();
  }, []);
  return (
    <div className="container-md mt-5">
      {postHtml ? (
        /* <Card> */
        <>
          <Helmet>
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />
          </Helmet>
          <div
            // style={{ fontFamily: "sans-serif" }} // Style your HTML content
            dangerouslySetInnerHTML={{ __html: postHtml }} // Render HTML safely
          ></div>
        </>
      ) : (
        /* </Card> */
        <p className="text-center mt-5">
          {" "}
          <i className="pi pi-spin pi-spinner fs-1" />
        </p>
        /* <p>Loading post...</p> */
      )}
    </div>
  );
  // <div dangerouslySetInnerHTML={{ __html: postResponse }}></div>;
  // <div className="container mt-5">
  //   {post ? (
  //     <Card
  //       title={
  //         <div
  //           dangerouslySetInnerHTML={{ __html: post.title.rendered }}
  //         ></div>
  //       }
  //     >
  //       <div
  //         style={{ fontFamily: "sans-serif" }}
  //         className="content"
  //         dangerouslySetInnerHTML={{ __html: post.content.rendered }}
  //       ></div>
  //     </Card>
  //   ) : (
  //     <p>Loading post...</p>
  //   )}
  // </div>
};

export default PostDetail;

// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom"; // Hook to get URL params
// import { Card } from "primereact/card";

// const PostDetail = () => {
//   const { slug } = useParams();
//   const [post, setPost] = useState(null);

//   useEffect(() => {
//     const fetchPost = async () => {
//       try {
//         const response = await axios.get(`/api/wp/${slug}`);
//         console.log(response);
//         setPost(response.data[0]);
//       } catch (error) {
//         console.error("Error fetching the post:", error);
//       }
//     };
//     fetchPost();
//   }, [slug]);

//   return (
//     <div className="container mt-5">
//       {post ? (
//         <Card
//           title={
//             <div
//               dangerouslySetInnerHTML={{ __html: post.title.rendered }}
//             ></div>
//           }
//         >
//           <div
//             style={{ fontFamily: "sans-serif" }}
//             className="content"
//             dangerouslySetInnerHTML={{ __html: post.content.rendered }}
//           ></div>
//         </Card>
//       ) : (
//         <p>Loading post...</p>
//       )}
//     </div>
//   );
// };

// export default PostDetail;
