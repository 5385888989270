import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { addPrivateLearner, getUsers } from "../../services/users";
import "../../styles/AddPrivateLearnerModal.css";
import { Toast } from "primereact/toast";
import { toast } from "react-toastify";
import { getAllCourses } from "../../services/courses";
import { Dropdown } from "primereact/dropdown";
import { addManualOrder } from "../../services/orders";
import { getSettings } from "../../services/settings";
import LoaderModal from "../../Components/LoaderModal";
const AddManualJobModal = ({
  visible,
  setVisible,
  refreshData,
  setRefreshData,
  changeValue,
}) => {
  const [job, setJob] = useState({
    courseId: "",
    transmission: "manual", // default is manual
    price: "",
    wantTheoryTest: false,
    wantPracticalTest: false,
  });
  const [errors, setErrors] = useState({});
  const [allCourses, setAllCourses] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState();
  const [totalOrderAmount, setTotalOrderAmount] = useState(0);
  const [allSettings, setAllSettings] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // Fetch all required data when the modal becomes visible
    const fetchData = async () => {
      setLoading(true); // Show loader
      try {
        const usersPromise = getUsers();
        const settingsPromise = getSettings();
        const coursesPromise = getAllCourses();

        const [usersResponse, settingsResponse, coursesResponse] =
          await Promise.all([usersPromise, settingsPromise, coursesPromise]);

        const customers = usersResponse.data.filter(
          (user) => user?.role?.name === "customer"
        );
        console.log(coursesResponse);
        setAllUsers(customers);
        setAllSettings(settingsResponse?.data);
        setAllCourses(coursesResponse);

        setLoading(false); // Hide loader after fetching data
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Hide loader in case of error
      }
    };

    if (visible) {
      fetchData();
    }
  }, [visible, refreshData]);

  useEffect(() => {
    const coursePrice = selectedCourse?.price || 0;
    const PracticalTestPrice = job.wantPracticalTest ? practicalTestPrice : 0;
    const TheoryTestPrice = job.wantTheoryTest ? theoryTestPrice : 0;
    const calculatedTotal = coursePrice + PracticalTestPrice + TheoryTestPrice;
    setTotalOrderAmount(calculatedTotal);
  }, [selectedCourse, job.wantPracticalTest, job.wantTheoryTest, allSettings]);

  useEffect(() => {
    if (selectedCourseId !== null) {
      const course = allCourses.find(
        (course) => course.id === JSON.parse(selectedCourseId)
      ); // Ensure the comparison is correct
      setSelectedCourse(course);
      handleManualChange("courseId", selectedCourseId);
      handleManualChange("price", course?.price);
    }
  }, [selectedCourseId]);

  const handleManualChange = (name, value) => {
    setJob({ ...job, [name]: value });
  };

  const theoryTestPrice = job.wantTheoryTest
    ? parseFloat(
        allSettings.find((setting) => setting.name === "theory_test_price")
          ?.value
      )
    : 0;

  const practicalTestPrice = job.wantPracticalTest
    ? parseFloat(
        allSettings.find((setting) => setting.name === "practical_test_price")
          ?.value
      )
    : 0;

  const handleAddJob = async (e) => {
    e.preventDefault();
    const fieldErrors = validateFields();
    if (Object.keys(fieldErrors).length > 0) {
      setErrors(fieldErrors);
      return;
    }

    try {
      await addManualOrder(job, totalOrderAmount, customerId).then(() => {
        setVisible(false);
        resetForm();
        toast.success("Job Created Successfully");
        changeValue("refreshData", !refreshData);
      });
    } catch (err) {
      console.error(err);
      setVisible(false);
      toast.error("Error in Creating Job");
    }
  };

  const resetForm = () => {
    setJob({
      courseId: "",
      transmission: "manual",
      price: "",
      wantTheoryTest: false,
      wantPracticalTest: false,
    });
    setCustomerId(null);
    setTotalOrderAmount(0);
    setErrors({});
  };

  const validateFields = () => {
    const newErrors = {};
    if (!job.courseId) newErrors.courseId = "*Course is required";
    return newErrors;
  };

  const allCoursesForDropdown = allCourses?.map((course) => ({
    value: course.id,
    label: `${course.hours} Hours Course`,
  }));

  const allUsersForDropdown = allUsers?.map((user) => ({
    value: user.id,
    label: user.name,
  }));

  const transmissionOptions = [
    { value: "manual", label: "Manual" },
    { value: "automatic", label: "Automatic" },
  ];

  return loading ? (
    <LoaderModal modalShow={loading} />
  ) : (
    <Dialog
      header="Add Job"
      visible={visible}
      style={{ width: "fit-content" }}
      onHide={() => {
        setVisible(false);
        resetForm();
        //   setJob({
        //     courseId: "",
        //     transmission: "manual",
        //     price: "",
        //     wantTheoryTest: false,
        //     wantPracticalTest: false,
        //   });
        //   setCustomerId(null);
        //   setTotalOrderAmount(0);
        //   setErrors({});
      }}
    >
      <form onSubmit={handleAddJob}>
        <div className="dialog-field-div mt-4 py-1">
          <FloatLabel>
            <Dropdown
              id="course"
              value={job.courseId}
              options={allCoursesForDropdown}
              onChange={(e) => {
                setSelectedCourseId(e.value);
                setJob({ ...job, courseId: e.value });
              }}
              placeholder="Select Course"
            />
            <label>Course</label>
          </FloatLabel>
          {errors.courseId && (
            <small className="p-error">{errors.courseId}</small>
          )}
        </div>
        <div className="dialog-field-div mt-4 py-1">
          <FloatLabel>
            <Dropdown
              id="user"
              value={customerId}
              options={allUsersForDropdown}
              onChange={(e) => {
                setCustomerId(e.value);
              }}
              placeholder="Select Course"
            />
            <label>Customer</label>
          </FloatLabel>
        </div>

        <div className="dialog-field-div mt-4 py-1">
          <FloatLabel>
            <Dropdown
              id="transmission"
              value={job.transmission}
              options={transmissionOptions}
              onChange={(e) => {
                setJob({ ...job, transmission: e.value });
              }}
              placeholder="Select Transmission"
            />
            <label>Transmission</label>
          </FloatLabel>
        </div>
        <div className="dialog-field-div mt-4 py-1">
          <div className="checkbox-container">
            <input
              type="checkbox"
              name="wantTheoryTest"
              checked={job.wantTheoryTest}
              onChange={(e) =>
                setJob({ ...job, wantTheoryTest: e.target.checked })
              }
            />
            <label className="mx-1"> Theory Test</label>
          </div>
        </div>

        <div className="dialog-field-div mt-4 py-1">
          <div className="checkbox-container">
            <input
              type="checkbox"
              name="wantPracticalTest"
              checked={job.wantPracticalTest}
              onChange={(e) =>
                setJob({ ...job, wantPracticalTest: e.target.checked })
              }
            />
            <label className="mx-1"> Practical Test</label>
          </div>
        </div>
        <div className="mt-4 py-1">
          <button
            type="submit"
            className="button-secondary py-2 px-2 float-end"
          >
            Add Job
          </button>
        </div>
      </form>
    </Dialog>
  );
};

export default AddManualJobModal;
