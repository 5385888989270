import { useEffect, useState } from "react";
import { formatDate } from "../Helpers/Helper";
// const formatImagePath = (imagePath) => {
//   if (!imagePath) return require("../../Assets/logo.webp"); // Default image if no path

//   // Replace the local path with the server URL
//   return imagePath.replace(
//     "C:\\Users\\GNG\\Documents\\GitHub\\pass-swiftly\\server\\express\\uploads\\images\\",
//     "/uploads/" // Adjust according to your server's URL structure
//     // "http://localhost:8003/uploads/" // Adjust according to your server's URL structure
//   );
// };
// const formatProfileImagePath = (imagePath) => {
//   if (!imagePath) return require("../../Assets/profileAvatar.png"); // Default image if no path

//   // Replace the local path with the server URL
//   return imagePath.replace(
//     "C:\\Users\\GNG\\Documents\\GitHub\\pass-swiftly\\server\\express\\uploads\\images\\",
//     "/uploads/" // Adjust according to your server's URL structure
//   );
// };
export const ReviewCard = ({ review }) => {
  // const [objectFit, setObjectFit] = useState("cover");
  const imageUrl = review.review_image
    ? `/uploads/${review.review_image}`
    : require("../../Assets/logo.webp");
  const profileImageUrl =
    review.user && review.user.profile_img
      ? `/uploads/${review.user.profile_img}`
      : require("../../Assets/profileAvatar.png");
  // const imageUrl = formatImagePath(review.review_image);
  // const profileImageUrl = formatProfileImagePath(review.user.profile_img);
  // useEffect(() => {
  //   const getImageObjectFit = (imagePath = imageUrl) => {
  //     return new Promise((resolve) => {
  //       const img = new Image();
  //       img.src = imagePath;

  //       img.onload = () => {
  //         // Check if the image is portrait or landscape
  //         if (img.width > img.height) {
  //           resolve("cover"); // Landscape
  //         } else {
  //           resolve("contain"); // Portrait
  //         }
  //       };

  //       img.onerror = () => {
  //         resolve("cover"); // Default to cover on error
  //       };
  //     });
  //   };
  //   const getObjectFit = async () =>
  //     await getImageObjectFit().then((fit) => {
  //       setObjectFit(fit);
  //     });
  //   getObjectFit();
  // }, [imageUrl]);
  return (
    <div
      className="p-4 m-2 shadow-lg border-0 rounded-lg d-flex flex-column justify-content-between text-white"
      style={{
        // width: "300px",
        // height:"250px"
        width: "350px",
        height: "480px", // Fixed height for all cards
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        cursor: "pointer",
        background: "linear-gradient(135deg, #023047, #219EBC)", // Gradient background
        borderRadius: "15px", // Softer rounded corners
        overflow: "hidden", // To prevent any overflow
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "scale(1.05)";
        e.currentTarget.style.boxShadow = "0 12px 24px rgba(0, 0, 0, 0.15)"; // Deeper shadow on hover
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "scale(1)";
        e.currentTarget.style.boxShadow = "0 6px 12px rgba(0, 0, 0, 0.1)"; // Regular shadow
      }}
    >
      {/* User Image and Name */}
      <div className="d-flex align-items-center mb-1">
        <div className="flex-shrink-0 ">
          {/* Profile Avatar */}
          <img
            src={profileImageUrl}
            // src={
            //   review.user?.image || require("../../Assets/profileAvatar.png")
            // }
            alt="UserImage"
            style={{
              width: "50px", // Avatar width
              height: "50px", // Avatar height
              borderRadius: "50%", // Circular avatar
              objectFit: "cover", // Ensures the image covers the space without distortion
              border: "2px solid #fff", // Optional border for a cleaner look
              boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
            }}
          />
        </div>
        <div className="flex-grow-1 ms-3 ">
          <div
            className="fw-bold"
            style={{
              fontSize: "20px",
              fontWeight: "600",
              whiteSpace: "nowrap", // Prevent wrapping to the next line
              overflow: "hidden", // Hide overflow content
              textOverflow: "ellipsis", // Add ellipsis (...) for overflow
              color: "#fff", // Ensure consistent text color (white)
              maxWidth: "200px", // Add max width to control text size before overflow
            }}
          >
            {review.user?.name || "Anonymous"}
          </div>
        </div>
      </div>

      {/* Image Section */}
      <div className="mb-1" style={{ height: "200px", overflow: "hidden" }}>
        <img
          src={imageUrl}
          alt="ReviewImage"
          height="100%"
          width="100%"
          style={{
            width: "100%", // Full width of the container
            height: "100%", // Full height of the container
            objectFit: "contain", // Ensures the image covers the space without distortion
            // objectFit: objectFit, // Ensures the image covers the space without distortion
            borderRadius: "10px", // Optional: to match card design
          }}
        />{" "}
      </div>
      {/* <p>{objectFit}</p> */}

      {/* Rating */}
      <div className="mb-1  text-center">
        {[...Array(5)].map((_, index) => {
          const isFullStar =
            index + 1 <= Math.floor(review.aggregate_rating || 0);

          return isFullStar ? (
            <svg
              key={index}
              focusable="false"
              width="26"
              height="26"
              viewBox="0 0 40 40"
              className="NMm5M"
            >
              <path
                d="M30.1738537,33.7654006 L27.0921483,24.1156159 C26.959813,23.7012327 27.1105413,23.2488331 27.4649676,22.9966268 L35.4477621,17.3161477 C35.8977477,16.9959424 36.0029553,16.3715793 35.68275,15.9215937 C35.4950528,15.6578223 35.1912764,15.5012346 34.8675395,15.5013769 L25.0804893,15.5056789 C24.6453619,15.5058702 24.2600542,15.2246667 24.1275461,14.8102063 L20.9521753,4.87824826 C20.7839895,4.35219504 20.2211976,4.06208615 19.6951444,4.23027193 C19.3871406,4.32874458 19.1457633,4.57007364 19.047229,4.87805774 L15.8695464,14.8103968 C15.7369778,15.2247605 15.3517177,15.5058702 14.916664,15.5056789 L5.13246101,15.5013772 C4.58017631,15.5011344 4.13226426,15.9486528 4.13202145,16.5009375 C4.13187911,16.8246744 4.28846681,17.1284508 4.55223829,17.316148 L12.5352875,22.9968084 C12.8895911,23.2489273 13.0403512,23.7011146 12.908231,24.1154083 L9.83068127,33.7657819 C9.66288114,34.2919582 9.95340248,34.8545373 10.4795788,35.0223375 C10.7880012,35.1206948 11.1249561,35.0636649 11.3838335,34.8692915 L19.3997363,28.8506971 C19.7553884,28.5836621 20.2446591,28.5835988 20.6003804,28.8505416 L28.6210329,34.8694549 C29.0627701,35.2009464 29.6895959,35.1115746 30.0210874,34.6698373 C30.2154254,34.4108674 30.2723531,34.073833 30.1738537,33.7654006 Z"
                fill="#fabb05"
              ></path>
            </svg>
          ) : (
            <svg
              key={index}
              focusable="false"
              width="26"
              height="26"
              viewBox="0 0 40 40"
              className="NMm5M"
            >
              <path
                d="M25.19,24.72c-0.4-1.24,0.06-2.6,1.12-3.36l5.43-3.86l-6.65,0 c-1.31,0-2.46-0.84-2.86-2.09L20,8.47l-2.22,6.95c-0.4,1.24-1.55,2.09-2.86,2.09l-6.65,0l5.43,3.86c1.06,0.76,1.52,2.11,1.12,3.36 l-2.13,6.67l5.51-4.14c1.07-0.8,2.53-0.8,3.6,0l5.51,4.14L25.19,24.72z M30.02,34.67c-0.33,0.44-0.96,0.53-1.4,0.2l-8.02-6.02 c-0.36-0.27-0.84-0.27-1.2,0l-8.02,6.02c-0.26,0.19-0.6,0.25-0.9,0.15c-0.53-0.17-0.82-0.73-0.65-1.26l3.08-9.65 c0.13-0.41-0.02-0.87-0.37-1.12l-7.98-5.68c-0.26-0.19-0.42-0.49-0.42-0.82c0-0.55,0.45-1,1-1l9.78,0c0.44,0,0.82-0.28,0.95-0.7 l3.18-9.93c0.1-0.31,0.34-0.55,0.65-0.65c0.53-0.17,1.09,0.12,1.26,0.65l3.18,9.93c0.13,0.41,0.52,0.7,0.95,0.7l9.79,0 c0.32,0,0.63,0.16,0.82,0.42C36,16.37,35.9,17,35.45,17.32L27.46,23c-0.35,0.25-0.51,0.7-0.37,1.12l3.08,9.65 C30.27,34.07,30.22,34.41,30.02,34.67z"
                fill="#80868b"
              ></path>
            </svg>
          );
        })}
      </div>
      <div
        className="text-body mb-1 text-white px-2 my-2"
        style={{
          fontSize: "16px",
          color: "#555",
          placeContent: "center",
          textAlign: "justify", // Center alignment
          overflowY: "auto", // Enable vertical scrolling
          height: "125px", // Fixed height
          lineHeight: "23px", // Set line height
          wordBreak: "break-word", // Break long words
        }}
        // style={{
        //   fontSize: "16px",
        //   color: "#555",
        //   placeContent: "center",
        //   textAlign: "center",
        //   overflowY: "auto",
        //   height: "128px",
        //   lineHeight: "23px",
        //   // lineHeight: "1.2em",
        //   display: "-webkit-box",
        //   WebkitLineClamp: 5,
        //   WebkitBoxOrient: "vertical",
        //   textOverflow: "ellipsis",
        //   wordBreak: "break-word",
        // }}
      >
        {review.review_text || "No review text available."}
      </div>

      {/* Date */}
      <div className="text-muted small text-white ">
        {formatDate(
          review.clearance_date ? review.clearance_date : review.createdAt
        )}
      </div>
    </div>
  );
};
