import React, { useContext } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async"; // To manage the head
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/saga-orange/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./styles/index.css";
import "./styles/App.css";
import "./styles/App.scss";
import "./styles/index.scss";
import PrimeReact from "primereact/api";
import { AppContext } from "./contexts/AppContext";
import { AuthProvider } from "./contexts/AuthContext";
import { GlobalContext, GlobalProvider } from "./contexts/GlobalContext";

PrimeReact.ripple = true;

const initialData = window.__INITIAL_DATA__ || null;

// Root component that will access the global context
const Root = () => {
  const { allSettings } = useContext(GlobalContext);
  // prettier-ignore
  const businessSchema = {
    "@context": "https://schema.org",
    "@type": "DrivingSchool",
    "@id": "https://pass-swiftly.com/#business",
    "name": "Pass Swiftly",
    "image": "https://www.passswiftly.com/logo.webp",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "3 Loganswell Drive, Thornliebank,",
      "addressLocality": "Glasgow",
      "addressRegion": "Scotland",
      "postalCode": "G46 8QL",
      "addressCountry": "GB",
    },
    email: allSettings.find((setting) => setting.name === "email")?.value || "",
    telephone:
      allSettings.find((setting) => setting.name === "phone")?.value || "",
    url: "https://pass-swiftly.com/",
    sameAs: [
      allSettings.find((setting) => setting.name === "facebook_url")?.value ||
        "",
      allSettings.find((setting) => setting.name === "instagram_url")?.value ||
        "",
      allSettings.find((setting) => setting.name === "youtube_url")?.value ||
        "",
    ],
    description:
      "We offer professional driving courses in Glasgow with experienced instructors.",
  };

  return (
    <>
      <Helmet>
        <title>Pass Swiftly</title>
        <meta
          name="description"
          content="We offer professional driving courses in Glasgow with experienced instructors."
        />
        <script type="application/ld+json">
          {JSON.stringify(businessSchema)}
        </script>
      </Helmet>
      <App initialData={initialData} />
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <GlobalProvider>
        <AppContext.Provider>
          <React.StrictMode>
            <HelmetProvider>
              <Root />
            </HelmetProvider>
          </React.StrictMode>
        </AppContext.Provider>
      </GlobalProvider>
    </AuthProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
