import React from "react";

export const appProps = {
  appSettings: {
    storeURL: "",
    appURL: "",
    appName: "Seller Center",
    fbURL: "",
    instaURL: "",
    twitterURL: "",
    youtubeURL: "",
  },
};

export const AppContext = React.createContext({
  appSettings: appProps.appSettings,
});
