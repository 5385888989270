import React, { useEffect, useState } from "react";

const SecurePayment = () => {
  const [paymentData, setPaymentData] = useState({
    cardNumber: "",
    cardCVV: "",
    cardExpiryMonth: "",
    cardExpiryYear: "",
  });
  const [browserInfo, setBrowserInfo] = useState(null);
  useEffect(() => {
    // const collectBrowserInfo = () => {
    //   return {
    //     deviceChannel: "browser",
    //     deviceIdentity: navigator.userAgent,
    //     deviceTimeZone: new Date().getTimezoneOffset().toString(),
    //     deviceCapabilities:
    //       "javascript" + (navigator.javaEnabled() ? ",java" : ""),
    //     deviceScreenResolution: `${window.screen.width}x${window.screen.height}x${window.screen.colorDepth}`,
    //     deviceAcceptContent: navigator.mimeTypes.length > 0 ? "true" : "false",
    //     deviceAcceptEncoding:
    //       navigator.userAgent.indexOf("gzip") !== -1 ? "gzip" : "",
    //     deviceAcceptLanguage: navigator.language || navigator.userLanguage,
    //     deviceAcceptCharset: document.characterSet || document.charset,
    //   };
    // };

    const collectBrowserInfo = () => {
      const screen_width = window.screen ? window.screen.width : "0";
      const screen_height = window.screen ? window.screen.height : "0";
      const screen_depth = window.screen ? window.screen.colorDepth : "0";
      const identity = window.navigator ? window.navigator.userAgent : "";
      const language = window.navigator
        ? window.navigator.language
          ? window.navigator.language
          : window.navigator.browserLanguage
        : "";
      const timezone = new Date().getTimezoneOffset();
      const java = window.navigator ? navigator.javaEnabled() : false;
      const charset = document.characterSet || document.charset;
      const acceptcontent = document.contentType;
      const os = "win";
      const type = "desktop";

      return {
        deviceChannel: "browser",
        deviceIdentity: identity,
        deviceTimeZone: timezone,
        deviceCapabilities: "javascript" + (java ? ",java" : ""),
        deviceScreenResolution: `${screen_width}x${screen_height}x${screen_depth}`,
        deviceAcceptContent: acceptcontent,
        deviceAcceptEncoding: "gzip, deflate, br, zstd",
        deviceAcceptLanguage: language,
        deviceAcceptCharset: charset,
        // deviceOperatingSystem: os,
        // deviceType: type,
        // deviceAcceptContent: "*/*",
        // deviceAcceptContent:
        //   "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7",
      };
    };

    setBrowserInfo(collectBrowserInfo());
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handlePayment = async (e) => {
    e.preventDefault();
    const paymentDetails = {
      amount: 1001,
      cardNumber: paymentData.cardNumber,
      cardExpiryMonth: paymentData.cardExpiryMonth,
      cardExpiryYear: paymentData.cardExpiryYear,
      cardCVV: paymentData.cardCVV,
      // cardNumber: "4012001037141112",
      // cardExpiryMonth: 12,
      // cardExpiryYear: 15,
      // cardCVV: "083",
      customerName: "Test Customer",
      customerEmail: "test@testcustomer.com",
      customerAddress: "16 Test Street",
      customerPostCode: "TE15 5ST",
      orderRef: "Test purchase - 66a9095ea4e66",
      // orderRef: "Test purchase",
      // threeDSOptions: {
      //   paymentAccountAge: "20190601",
      //   paymentAccountAgeIndicator: "05",
      // },
    };

    // const browserInfo =

    try {
      const response = await fetch("/api/payment-process", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentDetails,
          browserInfo,
        }),
      });
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes("text/html")) {
        // Handle HTML response
        const html = await response.text();
        const iframe = document.createElement("iframe");
        iframe.style = "width: 100%; height: 500px; border: none;";
        document.body.appendChild(iframe);
        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(html);
        iframe.contentWindow.document.close();
      } else if (response.ok) {
        const data = await response.json();

        if (data.threeDSURL) {
          // Handle 3DS redirect
          const form = document.createElement("form");
          form.method = "POST";
          form.action = data.threeDSURL;
          form.target = "_blank";
          console.log("data.threeDSRequest", data.threeDSRequest);
          Object.keys(data.threeDSRequest).forEach((key) => {
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = data.threeDSRequest[key];
            form.appendChild(input);
          });
          document.body.appendChild(form);
          form.submit();
        } else {
          alert(data.message);
        }
      } else {
        // Handle non-OK responses
        const text = await response.text();
        console.error("Non-OK response:", text);
        alert("There was an error processing your payment.");
      }
      // const data = await response.json();
      // const data = await response;
      // console.log(data);
      // console.log(data.threeDSURL);
      // console.log("object threeds", Object.entries(data.threeDSRequest));
      // if (data.threeDSURL) {
      //   // Handle 3DS redirect
      //   document.body.innerHTML += `
      //   <form id="3ds-form" action="${
      //     data.threeDSURL
      //   }" method="post" target="threeds_acs">
      //     ${Object.entries(data.threeDSRequest)
      //       .map(
      //         ([name, value]) =>
      //           `<input type="hidden" name="${name}" value="${value}">`
      //       )
      //       .join("")}
      //   </form>
      //   <iframe name="threeds_acs" style="height:420px; width:420px;"></iframe>
      //   <script>document.getElementById('3ds-form').submit();</script>
      // `;
      //   console.log("jklasdf");
      // } else {
      //   alert(response.data);
      // }
      // if (data.threeDSURL) {
      //   // Redirect to 3DS URL
      //   console.log("threeDSRequest", data.threeDSRequest);
      //   const form = document.createElement("form");
      //   form.method = "POST";
      //   form.action = data.threeDSURL;
      //   form.target = "_blank";

      //   Object.keys(data.threeDSRequest).forEach((key) => {
      //     const input = document.createElement("input");
      //     console.log("threeDSRequest", data.threeDSRequest);
      //     console.log("key", key);
      //     console.log("value", data.threeDSRequest);
      //     input.type = "hidden";
      //     input.name = key;
      //     input.value = data.threeDSRequest[key];
      //     form.appendChild(input);
      //   });
      //   const iframe = document.createElement("iframe");
      //   iframe.name = "threeds_acs";
      //   iframe.style = "height:420px; width:420px; display:none;";
      //   document.body.appendChild(iframe);

      //   document.body.appendChild(form);
      //   form.submit();
      // } else if (data.message) {
      //   alert(data.message);
      // } else if (data.error) {
      //   alert(`Payment failed: ${data.error}`);
      // }
    } catch (error) {
      console.log("dsfa");
      alert(`Error: ${error.message}`);
    }
  };
  return (
    <form
      onSubmit={handlePayment}
      className="mb-4"
      style={{
        borderRadius: "10px",
        padding: "20px",
        margin: "auto",
        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
      }}
    >
      {/* {JSON.stringify(browserInfo)} */}
      <h3 className="payment-step-title">Enter Payment Details</h3>
      {/* <div className="input-container">
      <label>Card Holder Name</label>
      <input
        type="text"
        placeholder="Card Holder Name"
        name="cardHolderName"
        value={input.cardHolderName}
        onChange={handleChange}
        className="payment-input"
      />
    </div>{" "} */}
      {/* <div className="input-container"> */}
      <label>Card Number</label>
      <input
        type="text"
        placeholder="Card Number"
        name="cardNumber"
        value={paymentData.cardNumber}
        onChange={handleChange}
        className="payment-input"
        required
      />
      {/* </div> */}
      {/* <div
        style={{ display: "flex", alignItems: "center" }}
        className="input-container"
      >
        <label>Card Expiry (MM/YY): </label>
        {"  "}
        <div>
          <input
            type="text"
            // value={month}
            name="cardExpiryMonth"
            value={paymentData.cardExpiryMonth}
            onChange={handleChange}
            // onChange={handleMonthChange}
            placeholder="MM"
            min="1"
            max="12"
            style={{
              width: "3rem",
              marginRight: "0.5rem",
              marginLeft: "0.1rem",
            }}
            maxLength="2"
            required
          />
          /
          <input
            type="text"
            // value={year}
            name="cardExpiryYear"
            value={paymentData.cardExpiryYear}
            onChange={handleChange}
            // onChange={handleYearChange}
            placeholder="YY"
            min="0"
            max="99"
            style={{ width: "3rem", marginLeft: "0.5rem" }}
            maxLength="2"
            required
          />
        </div>
      </div> */}
      {/* <div className="input-container">
      <label>Expiry Date (MM/YY):</label>
      <input
        type="text"
        value={expiry}
        onChange={handleExpiryChange}
        placeholder="MM/YY"
        maxLength="5"
        className="payment-input"
        required
      />
    </div> */}
      {/* <div className="input-container"> */}
      <label>CVV</label>
      <input
        type="password"
        placeholder="CVV"
        value={paymentData.cardCVV}
        name="cardCVV"
        onChange={handleChange}
        className="payment-input"
        required
      />
      {/* </div> */}
      {/* <button type="submit" onClick={handlePayment}> */}
      <button type="submit">Pay</button>
    </form>
    // <div>
    // //   <h1>3D secure</h1>
    //   <button onClick={handlePayment}>Pay</button>
    // </div>
  );
};
export default SecurePayment;
