import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import React, { useRef, useState } from "react";
import { addPrivateLearner } from "../../services/users";
import "../../styles/AddPrivateLearnerModal.css";
import { Toast } from "primereact/toast";
import { toast } from "react-toastify";
const AddPrivateLearnerModal = ({
  visible,
  setVisible,
  refreshData,
  setRefreshData,
}) => {
  const [learner, setLearner] = useState({
    name: "",
    email: "",
    phone: "",
    postcode: "",
    address: "",
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    setLearner({ ...learner, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" }); // Clear errors on input change
  };
  const validateFields = () => {
    const newErrors = {};
    if (!learner.name) newErrors.name = "*Name is required";
    if (!learner.email) newErrors.email = "*Email is required";
    if (!learner.phone) newErrors.phone = "*Phone is required";
    if (!learner.address) newErrors.address = "*Address is required";
    if (!learner.postcode) newErrors.postcode = "*Postcode is required";
    return newErrors;
  };
  const userId = localStorage.getItem("userId");
  const handleAddPrivateLearner = async (e) => {
    e.preventDefault();
    const fieldErrors = validateFields();
    if (Object.keys(fieldErrors).length > 0) {
      setErrors(fieldErrors);
      return;
    }
    try {
      await addPrivateLearner(userId, learner).then((res) => {
        setVisible(false);
        setLearner({
          name: "",
          email: "",
          phone: "",
          postcode: "",
          address: "",
        });
        setErrors({});
        setRefreshData(!refreshData);
        toast.success("Private Learner Added Successfully");
      });
    } catch (err) {
      console.log(err);
      setVisible(false);
      toast.success("Error in Adding Private Learner ");
    }
  };
  return (
    <Dialog
      header="Add Private Learner"
      visible={visible}
      style={{ width: "fit-content" }}
      onHide={() => {
        if (!visible) return;
        setVisible(false);
        setLearner({
          name: "",
          email: "",
          phone: "",
          postcode: "",
          address: "",
        });
        setErrors({
          name: "",
          email: "",
          phone: "",
          postcode: "",
          address: "",
        });
      }}
    >
      {" "}
      <form onSubmit={handleAddPrivateLearner}>
        {["name", "phone", "email", "address", "postcode"].map((field) => (
          <div className="dialog-field-div mt-4 py-1" key={field}>
            <FloatLabel>
              <InputText
                type="text"
                className="private-learner-dialog-input"
                value={learner[field]}
                name={field}
                onChange={handleChange}
              />
              <label>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
            </FloatLabel>
            {errors[field] && (
              <small className="p-error">{errors[field]}</small>
            )}
          </div>
        ))}
        <div className="mt-4 py-1">
          <button
            type="submit"
            className="button-secondary py-2 px-2 float-end"
          >
            Add Learner
          </button>
        </div>
      </form>
      {/* <form
        className=""
        // style={{ padding: "20px" }}
        onSubmit={handleAddPrivateLearner}
      >
        <div className="dialog-field-div mt-4 py-1">
          <FloatLabel>
            <InputText
              type="text"
              className="private-learner-dialog-input"
              value={learner.name}
              name="name"
              onChange={handleChange}
            />
            <label>Name</label>
          </FloatLabel>
        </div>
        <div className="dialog-field-div mt-4 py-1">
          <FloatLabel>
            <InputText
              type="text"
              name="phone"
              className="private-learner-dialog-input"
              value={learner.phone}
              onChange={handleChange}
            />
            <label>Phone</label>
          </FloatLabel>
        </div>
        <div className="dialog-field-div mt-4 py-1">
          <FloatLabel>
            <InputText
              type="text"
              className="private-learner-dialog-input"
              value={learner.email}
              name="email"
              onChange={handleChange}
            />
            <label>Email</label>
          </FloatLabel>
        </div>
        <div className="dialog-field-div mt-4 py-1">
          <FloatLabel>
            <InputText
              type="text"
              className="private-learner-dialog-input"
              value={learner.address}
              name="address"
              onChange={handleChange}
            />
            <label>Address</label>
          </FloatLabel>
        </div>
        <div className="dialog-field-div mt-4 py-1">
          <FloatLabel>
            <InputText
              type="text"
              className="private-learner-dialog-input"
              value={learner.postcode}
              name="postcode"
              onChange={handleChange}
            />
            <label>Postcode</label>
          </FloatLabel>
        </div>
        <div className="mt-4 py-1">
          <button
            type="submit"
            className="button-secondary py-2 px-2 float-end"
          >
            Add Learner
          </button>
        </div>
      </form> */}
    </Dialog>
  );
};

export default AddPrivateLearnerModal;
