import React from "react";
import "../styles/FindCourseSection.css";
import { Link } from "react-router-dom";

const FindCourseSection = () => {
  return (
    <div
      className="justify-content-center align-items-center pt-5 pb-2"
      style={{
        backgroundColor: "aliceblue",
      }}
    >
      <h2 className="text-center">Find Your Course!</h2>
      <p className="text-center">
        Based on your experience level, we have a perfect course for you
      </p>
      <div className="row justify-content-center">
        <div className="col-md-4 beginner-container">
          <span className="text-center">Beginner Courses</span>
          <p className="text-center">
            If you’re new to driving, then there courses are ideal for beginners
            like you.
          </p>
          <Link to="/courses/Beginner">
            <button className="btn">View Course</button>
          </Link>
        </div>
        <div className="col-md-4 intermediate-container">
          <span className="text-center">Intermediate Courses</span>
          <p className="text-center">
            If you already have some familiarity with driving rules, then this
            set of courses is tailored to suit your needs.
          </p>
          <Link to="/courses/Intermediate">
            <button className="btn">View Course</button>
          </Link>
        </div>
        <div className="col-md-4 refresher-container">
          <span className="text-center">Refresher Courses</span>
          <p className="text-center">
            As you near readiness for your driving test, these courses are
            crafted to cater specifically to your needs.
          </p>
          <Link to="/courses/Refresher">
            <button className="btn">View Course</button>
          </Link>
        </div>
        {/* <div className="col-md-4 intermediate-container">
          Intermediate Courses
        </div>
        <div className="col-md-4 refresher container">Refresher Courses</div> */}
      </div>
    </div>
  );
};

export default FindCourseSection;
