import React, { useContext } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import { Card } from "primereact/card";
import { Link } from "react-router-dom";
import "../styles/WPCourses.css";
import Reviews from "../Components/Reviews";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Helmet } from "react-helmet-async";

const WPCourses = () => {
  const { allCourses } = useContext(GlobalContext);

  const renderCourses = (courseCategory, textColor) => {
    return allCourses
      .filter((course) => course.category === courseCategory)
      .map((course, index) => (
        <div
          key={index}
          className="wp-card-container"
          style={{ ...(textColor && { color: textColor }) }}
        >
          <Card
            title={() => (
              <div style={{ ...(textColor && { color: textColor }) }}>
                <i className="pi pi-clock" style={{ fontSize: "20px" }} />{" "}
                {course.hours} Hours Course <br />
                <i
                  className="pi pi-calendar"
                  style={{ fontSize: "20px" }}
                />{" "}
                {course.duration}
              </div>
            )}
            className={`md:w-25rem wp-course-card ${course.category.toLowerCase()}`}
            footer={() => (
              <div className="d-flex justify-content-center">
                <Link
                  to={`/booking?courseId=${
                    course.id
                  }&courseType=${course.category.toLowerCase()}`}
                >
                  <button
                    className={`btn course-button ${course.category.toLowerCase()} mx-1`}
                  >
                    Book Now
                  </button>
                </Link>
                <Link to={`/course/${course?.slug}`}>
                  <button
                    className={`btn course-button ${course.category.toLowerCase()}-inverse mx-1 `}
                  >
                    View Details
                  </button>
                </Link>
                {/* <Link
                to={`/booking?courseId=${course.id}&courseType=${course.category}`}
              >
                <button
                  className={`btn wp-course-button ${course.category.toLowerCase()} mx-1`}
                >
                  Book Now
                </button>
              </Link>
              <Link to={`/course/${course?.slug}`}>
                <button
                  className={`btn wp-course-button ${course.category.toLowerCase()}-inverse mx-1`}
                >
                  View Details
                </button>
              </Link> */}
              </div>
            )}
          >
            <>
              <div
                className="wp-card-content mb-5 px-2"
                style={{
                  textAlign: "justify",
                  ...(textColor && { color: textColor }),
                }}
                dangerouslySetInnerHTML={{ __html: course.details }}
              ></div>
              {course.price && (
                <div
                  style={{
                    textAlign: "justify",
                    ...(textColor && { color: textColor }),
                  }}
                >
                  <strong style={{ fontSize: "20px" }}>
                    From Price <i className="pi pi-pound" />
                    {course.from_price}
                  </strong>
                </div>
              )}
            </>
          </Card>
        </div>
      ));
  };
  const CourseRecommenderSection = () => {
    return (
      <section className="course-recommender-section py-5  ">
        <div className="container">
          <div className="row text-center mb-4">
            <div className="col-12">
              <h2 className=" mb-3 ">
                Discover the Perfect Course with Our Recommender
              </h2>
              <p className=" fs-6  text-justify">
                By simply answering a few questions about your driving goals,
                preferences, and current skills, you’ll receive personalized
                recommendations that align with your needs. We analyze various
                factors, such as course duration, instructor expertise, and
                training methods to ensure you get the best fit.
              </p>
              <p className=" fs-6  text-justify ">
                Don't waste time browsing through countless options. Let our
                tool streamline your decision-making process and connect you
                with a course that sets you up for success. Our recommender
                takes the guesswork out of finding the perfect course, so you
                can focus on what really matters – becoming a confident and
                skilled driver.
              </p>
              <div className="mt-5">
                <Link
                  to="/course-recommender "
                  className="button-secondary-inverse  px-5 py-3 text-decoration-none "
                >
                  <span className="fw-bold fs-6 ">Try Now</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  const BenifitsSection = () => {
    return (
      <div className="courses-benefits-section text-center mt-5">
        <h2 className="courses-benifits-section-title">
          Why Choose Pass-Swiftly?
        </h2>
        <div className="row justify-content-center">
          <div className="courses-benefits-item col-md-2">
            <div className="courses-benifits-image-container">
              <img
                className="benefit-image"
                src={require("../Assets/benifitsImages/clock.png")}
                alt="Flexible Hours"
              />
            </div>
            <h3 className="courses-benifits-text-card-heading">
              Customize and Flexible Lessons
            </h3>
            <div className="courses-benifits-text-card">
              <p>
                Our adaptive schedule is crafted to align with your unique
                requirements. Intensive lessons can vary from daily{" "}
                <strong>two-hour sessions</strong> to consecutive days of
                back-to-back lessons.
                {/* These sessions are customized to cater to
                your individual needs, considering your previous experience. */}
              </p>
            </div>
          </div>

          <div className="courses-benefits-item col-md-2">
            <div className="courses-benifits-image-container">
              <img
                className="courses-benefit-image"
                src={require("../Assets/benifitsImages/school.png")}
                alt="Experienced Instructors"
              />
            </div>
            <h3 className="courses-benifits-text-card-heading">
              Experienced and Skilled Instructors
            </h3>
            <div className="courses-benifits-text-card">
              <p>
                {/* If you’re eager to swiftly pass your driving test and obtain
                your license, Pass Swiftly is your solution.  */}
                Our highly <strong>skilled instructors</strong> will thoroughly
                prepare you to excel in your test within just{" "}
                <strong>2-3 weeks</strong>, helping you achieve your goal.
              </p>
            </div>
          </div>

          <div className="courses-benefits-item col-md-2">
            <div className="courses-benifits-image-container">
              <img
                className="courses-benefit-image"
                src={require("../Assets/benifitsImages/correct-icon.png")}
                alt="Success Stories"
              />
            </div>
            <h3 className="courses-benifits-text-card-heading">
              Our Success Stories
            </h3>
            <div className="courses-benifits-text-card">
              <p>
                Since 2015, our intensive driving courses have successfully
                assisted hundreds of students in passing their driving tests.
              </p>
            </div>
          </div>

          <div className="courses-benefits-item col-md-2">
            <div className="courses-benifits-image-container">
              <img
                className="courses-benefit-image"
                src={require("../Assets/benifitsImages/Pound-rbg.png")}
                alt="Affordable Pricing"
              />
            </div>
            <h3 className="courses-benifits-text-card-heading">
              Budget-Friendly Pricing
            </h3>
            <div className="courses-benifits-text-card">
              <p>
                We offer our courses at highly competitive prices. Explore our
                pricing options{" "}
                <Link to="/courses/beginner">
                  <u style={{ cursor: "pointer" }}>here.</u>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const FAQSection = () => {
    const faqItems = [
      {
        question: "Is Intensive Driving Course for me?",
        answer:
          "This option is perfect if you're aiming to pass your test quickly without investing months or years in learning.",
      },
      {
        question: "What is intensive driving course?",
        answer:
          "An intensive driving course, also referred to as a crash course or fast-track driving course, compresses the learning period into a short timeframe. Unlike the traditional approach of weekly lessons spread over months, intensive driving courses often consist of several hours of lessons per day, typically spanning one to two weeks. Passswiftly has successfully assisted hundreds of individuals in obtaining their driving licenses through their intensive driving programs.",
      },
      {
        question: "Price of intensive driving course?",
        answer:
          "The price of an intensive driving course varies depending on the number of hours needed, typically ranging from 10 to 48 hours. One of the benefits of PassSwiftly's pricing for intensive driving courses is its all-inclusive nature: a single fee covers everything from theory and practical driving tests to all your lessons. When you book a PassSwiftly course, you won't need to manage cash for each lesson—you can choose to pay conveniently through monthly instalments.",
      },
      {
        question: "How to choose suitable course for myself?",
        answer:
          "Our website is designed to help you find the best course based on your abilities. Alternatively, one of our Booking Managers can personally discuss your needs with you and recommend the most suitable course.",
      },
      {
        question: "Timing for my driving lessons?",
        answer:
          "Lesson times typically range from 8am to 6pm, allowing you to schedule them without needing to take extensive time off from work. We can coordinate this together based on your availability.",
      },
      {
        question:
          "What if I only have one or two days off a week, can I still do lessons with you?",
        answer:
          "Certainly! You have the option to enroll in our semi-intensive course, where classes are scheduled one to two days per week for 3-5 hours each day.",
      },
      {
        question: "Can I pass my driving test in 2 weeks?",
        answer:
          "Numerous learners have completed their driving lessons in as little as 2 weeks! If you quickly grasp new skills and can dedicate yourself to 4 to 5-hour lessons on consecutive days, you could potentially finish one of our beginner courses in just 2 weeks.",
      },
      {
        question: "Are you guaranteed to pass on a crash course?",
        answer:
          "Success depends on the individual's performance during the test, which is beyond their control of driving school. Claims of 'guaranteed pass' for intensive driving courses often come with extensive terms and conditions. At PassSwiftly, we prioritize transparency and do not offer 'guaranteed pass' courses.",
      },
      {
        question:
          "Which type of car should I learn to drive: manual or automatic?",
        answer:
          "It ultimately depends on your preferences. Choosing a manual car means you'll gain proficiency in both manual and automatic vehicles, though it requires mastering additional skills. In contrast, automatic cars are easier to operate and are a great choice for individuals who may feel anxious about learning to drive.",
      },
    ];

    return (
      <div className="faq-container mt-5 container ">
        <h2 className="faq-text-center mb-4">Frequently Asked Questions</h2>
        <Accordion className="">
          {faqItems.map((item, index) => (
            <AccordionTab key={index} header={item.question} className="py-1 ">
              <p>{item.answer}</p>
            </AccordionTab>
          ))}
        </Accordion>
      </div>
    );
  };
  return (
    <div className=" ">
      <Helmet>
        <title>Explore Driving Courses for All Levels | Pass Swiftly</title>
        <meta
          name="description"
          content="Browse Pass Swiftly's comprehensive driving courses for beginners, intermediates, and refreshers. Find the right course to match your needs and become a confident driver."
        />
      </Helmet>
      <div className="d-flex flex-column justify-content-center">
        <div className="">
          <h3 className=" wp-course-category-heading fs-1">Beginner Courses</h3>
          <p className="container wp-course-category-content">
            For new drivers seeking to build a strong foundation, these courses
            offers everything you need to get started on the road with
            confidence. Ideal for individuals with no prior driving experience,
            it provides a structured introduction to the essential rules of the
            road, basic driving techniques, and critical safety protocols. These
            courses includes <strong> step-by-step guidance </strong> on key
            driving fundamentals, such as understanding traffic signs, mastering
            vehicle control, and adhering to road safety practices. With a focus
            on <strong> instilling safe driving habits </strong> from the
            outset, these courses ensures that new learners are well-equipped to
            handle various driving scenarios while{" "}
            <strong>developing the confidence</strong> needed to navigate the
            road safely and effectively.
            {/* For new drivers looking to build a solid foundation.
            <ul>
              <li>Ideal for: New learners with no prior experience. </li>
              <li>
                Includes: Introduction to road rules, basic driving techniques,
                safety protocols, and more.
              </li>{" "}
            </ul> */}
          </p>
          <div className="d-flex flex-wrap justify-content-center">
            {renderCourses("Beginner")}
          </div>
        </div>
        <div className=" ">
          <h3 className="wp-course-category-heading fs-1">
            Intermediate Courses
          </h3>
          <p className="container wp-course-category-content">
            {/* Enhance your driving skills and gain confidence on the road.
            <ul>
              <li>Ideal for: Learners with some driving experience. </li>
              <li>
                Includes: Mastering traffic scenarios, handling road complexity,
                and improving control.
              </li>{" "}
            </ul>    */}
            Enhance your driving skills and build lasting confidence on the road
            with our expertly designed programs, ideal for drivers with some
            prior experience who want to further refine their abilities. Thses
            courses provides comprehensive training on{" "}
            <strong> mastering a wide range of traffic scenarios</strong>, from
            busy urban intersections to highway driving, and teaches you how to
            navigate complex road environments with greater ease and control.
            You’ll <strong> gain valuable insights </strong> into handling
            challenging driving conditions, improving vehicle maneuverability,
            and{" "}
            <strong>
              {" "}
              responding confidently to real-world road situations.
            </strong>{" "}
            Whether you're looking to refresh your skills or advance your
            driving expertise, these courses will help you feel fully prepared
            and capable on the road.
          </p>
          <div className="d-flex flex-wrap justify-content-center">
            {renderCourses("Intermediate", "#023047")}
          </div>
        </div>
        <div className=" ">
          <h3 className="wp-course-category-heading fs-1">Refresher Courses</h3>
          <p className="container wp-course-category-content">
            These comprehensive programs are designed for individuals who need a
            quick yet thorough refresher on driving. Whether you are a driver
            looking to regain confidence after time away from the road or
            someone who wants to update their skills to meet the demands of
            today's driving environment, these courses are ideal. It focuses on
            core areas essential for safe and effective driving, including{" "}
            <strong>defensive driving techniques</strong>, situational
            awareness, and adapting to modern traffic patterns. Additionally,
            these courses covers essential updates in road regulations,{" "}
            <strong>tips for handling new vehicle technologies </strong>, and
            best practices for navigating through complex driving scenarios such
            as urban traffic, highway merging, and roundabouts. Perfect for
            drivers of all levels who wish to ensure they are up-to-date and{" "}
            <strong>prepared for today's roads</strong>.
            {/* Perfect for those who need a quick refresher on driving.
            <ul>
              <li>
                Ideal for: Drivers looking to regain confidence or update their
                skills after a break.{" "}
              </li>
              <li>
                Includes: Defensive driving techniques, navigating through
                modern traffic, and more.
              </li>{" "}
            </ul> */}
          </p>

          <div className="d-flex flex-wrap justify-content-center">
            {renderCourses("Refresher")}
          </div>
        </div>
      </div>
      {/* <div className="d-flex flex-wrap justify-content-center">
        {renderCourses("Beginner")}
        <br />
        {renderCourses("Intermediate")}
        <br />
        {renderCourses("Refresher")}
        <br />
      </div> */}
      <CourseRecommenderSection />
      <BenifitsSection />
      <Reviews />
      <FAQSection />
    </div>
  );
};

export default WPCourses;
// import axios from "axios";
// import React, { useEffect, useState } from "react";

// const WPCourses = () => {
//   const [coursesContent, setCoursesContent] = useState();
//   //   useEffect(async () => {
//   //     const response = await axios.get(
//   //       "https://www.pass-swiftly.com/content/wp-json/wp/v2/pages"
//   //     );
//   //     console.log(response);
//   //   }, []);
//   useEffect(() => {
//     fetchPages();
//   }, []);
//   const fetchPages = async () => {
//     const response = await fetch(`/api/wp/pages/courses`);
//     console.log(response);
//     const textResponse = await response.text();
//     setCoursesContent(textResponse);
//   };
//   return (
//     <div className="container mt-2">
//       {" "}
//       {coursesContent ? (
//         <div
//           style={{ fontFamily: "sans-serif" }} // Style your HTML content
//           dangerouslySetInnerHTML={{ __html: coursesContent }} // Render HTML safely
//         ></div>
//       ) : (
//         /* </Card> */
//         <p className="text-center mt-5">
//           {" "}
//           <i className="pi pi-spin pi-spinner fs-1" />
//         </p>
//       )}
//       {/* <h1
//         className="text-center"
//         dangerouslySetInnerHTML={{ __html: coursesContent?.title?.rendered }}
//       ></h1>
//       <div
//         style={{ fontFamily: "sans-serif" }} // Style your HTML content
//         dangerouslySetInnerHTML={{ __html: coursesContent?.content?.rendered }} // Render HTML safely
//       ></div> */}
//     </div>
//   );
// };

// export default WPCourses;
