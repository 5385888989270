import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
// import LessonModal from "../Modals/LessonModal";
import { toast } from "react-toastify";
import { getAllLessons } from "../services/lessons";
import LessonViewModal from "./Modals/LessonViewModal";
import { CalendarView } from "../Components/misc/CalendarComponent";
import { TabPanel, TabView } from "primereact/tabview";
import { NewCustomListing } from "../Components/custom/NewCustomListing";
import { Column } from "primereact/column";
import { formatDate } from "../Components/Helpers/Helper";
import { Can } from "../configs/Ability-context";

const AllLessons = ({ userId, role }) => {
  //   const [lessons, setLessons] = useState(initialLessons);
  const [lessons, setLessons] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  useEffect(() => {
    const fetchLessons = async () => {
      const response = await getAllLessons();
      console.log(response);

      //   const response = await getLessonsByUser(JSON.parse(role), id);
      setLessons(response);
      console.log(lessons);
      // if (!response.ok) {
      //   toast.error("Error in Getting Lessons");
      // }
    };
    fetchLessons();
  }, [userId, refreshData]);

  const handleCreateLesson = (lessonInfo) => {
    // API call to create lesson and update state
    // Mock API response
    const newLesson = { id: lessons.length + 1, ...lessonInfo };
    setLessons([...lessons, newLesson]);

    toast.current.show({
      severity: "success",
      summary: "Lesson Created",
      detail: `Lesson scheduled for ${lessonInfo.date}`,
    });
  };

  const handleRescheduleLesson = (lessonInfo) => {
    // API call to reschedule lesson and update state
    const updatedLessons = lessons.map((lesson) =>
      lesson.id === lessonInfo.id ? lessonInfo : lesson
    );
    setLessons(updatedLessons);

    toast.done("Lesson Resheduled Successfully");
  };

  const handleRescheduleClick = (lesson) => {
    setSelectedLesson(lesson);
    setModalVisible(true);
  };
  const columns = [
    {
      field: "instructor.name",
      header: "Instructor",
      filter: true,
      // body: (rowData) => inlineEditor("value", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      headerStyle: { width: "290px" },
    },
    {
      field: "date",
      header: "Date",
      filter: true,
      body: (rowData) => <span>{formatDate(rowData.date)}</span>,
      // body: (rowData) => inlineEditor("value", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      headerStyle: { width: "290px" },
    },
    {
      field: "start_time",
      header: "Start Time",
      filter: true,
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      headerStyle: { width: "290px" },
    },
    {
      field: "duration",
      header: "Duration",
      filter: true,
      selectedByDefault: true,
      body: (rowData) => <span>{rowData.duration} Minutes</span>,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      headerStyle: { width: "290px" },
    },
  ];
  return (
    <Can I="manage" a="all">
      <div className="lesson-screen container">
        <Toast ref={toast} />
        {/* <Button
        label="Create Lesson"
        icon="pi pi-plus"
        onClick={() => setModalVisible(true)}
      /> */}
        <TabView className="container">
          <TabPanel header="Calendar View">
            <CalendarView
              lessons={lessons}
              onRescheduleClick={handleRescheduleClick}
            />
          </TabPanel>
          <TabPanel header="List View">
            <NewCustomListing
              allRecords={lessons}
              heading={"Lessons"}
              firstColumn={
                <Column
                  field="student.name"
                  header="Student"
                  sortable={true}
                  style={{ borderBottom: "1px solid grey" }}
                  // body={(rowData) => nameBody(rowData)}
                  filter={true}
                  filterPlaceholder="Search by name"
                  filterMatchMode="contains"
                  headerStyle={{ width: "170px" }}
                />
              }
              columns={columns}
              // isLoading={isLoading}
              handleRefresh={() => setRefreshData(!refreshData)}
              editDataKey={"id"}
              // tableRef={dt}
              timeStamps={true}
            />
          </TabPanel>
        </TabView>
        <LessonViewModal
          visible={modalVisible}
          onHide={() => {
            setModalVisible(false);
            setSelectedLesson(null);
          }}
          refreshData={refreshData}
          setRefreshData={setRefreshData}
          // onCreate={handleCreateLesson}
          admin={true}
          lesson={selectedLesson}
          // orderPopulated={orderPopulated}
        />
        {/* <LessonModal
        visible={modalVisible}
        onHide={() => {
          setModalVisible(false);
          setSelectedLesson(null);
        }}
        // onCreate={handleCreateLesson}
        onReschedule={handleRescheduleLesson}
        lesson={selectedLesson}
        // orderPopulated={orderPopulated}
      />
       */}
      </div>
    </Can>
  );
};

export default AllLessons;
