import React from "react";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";

const CustomSelect = (props) => {
  const {
    label,
    options,
    optionLabel,
    optionValue,
    value,
    handleChange,
    className,
    labelClass,
    isMulti,
    isDisabled,
    isFormField,
    isRequired,
    filter,
    showClear,
    resetFilterOnHide,
    style,
  } = props;

  const multiSelect = (
    <MultiSelect
      className={`${className ? className : ""} custom-multi-select`}
      display="chip"
      filter={filter}
      filterBy="label"
      onChange={handleChange}
      optionLabel={optionLabel ? optionLabel : undefined}
      optionValue={optionValue ? optionValue : undefined}
      options={options}
      // placeholder={placeholder}
      showClear={showClear}
      value={value}
      disabled={isDisabled}
      resetFilterOnHide={resetFilterOnHide}
      required={isRequired}
    />
  );
  const dropdown = (
    <Dropdown
      className={`${
        className ? className : ""
      } custom-dropdown border-radius-0`}
      filter={filter}
      onChange={handleChange}
      optionLabel={optionLabel ? optionLabel : undefined}
      optionValue={optionValue ? optionValue : undefined}
      options={options}
      panelClassName={`p-text-left border-radius-0 p-ml-1 custom-dropdown-panel`}
      // placeholder={placeholder}
      showClear={showClear}
      value={value}
      disabled={isDisabled}
      resetFilterOnHide={resetFilterOnHide}
      style={style}
      required={isRequired}
    />
  );
  const floatLabel = (
    <label
      className={`float-label ${labelClass ? labelClass : ""}`}
      style={
        value || value === 0
          ? {
              borderTopLeftRadius: "3px",
              borderTopRightRadius: "3px",
              fontSize: "11px ",
              backgroundColor: "white",
              marginTop: "0px",
              paddingTop: "4px",
              paddingRight: "4px",
              paddingBottom: "0px",
              paddingLeft: "4px",
            }
          : undefined
      }
    >
      {`${label}${isRequired ? "*" : ""}`}
    </label>
  );
  const element = isMulti ? multiSelect : dropdown;
  return isFormField ? (
    <span className="p-float-label p-mb-4">
      {element}
      {floatLabel}
    </span>
  ) : (
    element
  );
};

export default CustomSelect;
