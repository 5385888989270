import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { createUser, createUserWithProfileImg } from "../services/users";
import { addBussinessReview } from "../services/businessReviews";
import { toast } from "react-toastify";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Rating } from "primereact/rating";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
const AddReviewModal = ({
  visible,
  setVisible,
  refreshData,
  setRefreshData,
}) => {
  const [rating, setRating] = useState(null);
  const [reviewText, setReviewText] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userProfileImage, setUserProfileImage] = useState("");
  const [reviewImage, setReviewImage] = useState("");
  const [clearanceDate, setClearanceDate] = useState();
  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setUserProfileImage(file);
    } else {
      console.log(`No file selected `);
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // const name = e.target.name;
    if (file) {
      // console.log(`File selected for ${name}:`, file); // Log the file details
      setReviewImage(file);
    } else {
      console.log(`No file selected `);
    }
  };
  const [errors, setErrors] = useState({});
  const handleFileUploadChange = (e) => {
    console.log("File change event triggered");
    const file = e.files[0]; // PrimeReact's FileUpload provides the files array

    if (file) {
      setReviewImage(file); // Store the selected file immediately
      console.log(reviewImage);

      console.log("File selected:", file);
    } else {
      console.log("No file selected");
    }
  };
  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Simple email regex

    if (!userName) newErrors.userName = "Name is required";
    if (!userEmail) newErrors.userEmail = "Email is required";
    else if (!emailPattern.test(userEmail))
      newErrors.userEmail = "Invalid email address";
    if (!rating) newErrors.rating = "Rating is required";
    if (!reviewText) newErrors.reviewText = "Review cannot be empty";

    setErrors(newErrors);
    return Object.keys(newErrors)?.length === 0;
  };
  const user = {
    name: userName,
    email: userEmail,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let userId;
    const userFormData = new FormData();
    userFormData.append("userData", JSON.stringify(user));
    userFormData.append("profileImage", userProfileImage);
    if (validateForm()) {
      await createUserWithProfileImg(userFormData).then((res) => {
        userId = res.user.id;
      });
      const formData = new FormData();
      formData.append(
        "reviewData",
        JSON.stringify({
          rating,
          reviewText,
          clearanceDate,
          userId,
          // reviewImage,
        })
      );
      formData.append("reviewImage", reviewImage);
      userId &&
        (await addBussinessReview(formData)
          .then(() => {
            setVisible(false);
            setUserName("");
            setUserEmail("");
            setRating(0);
            setReviewText("");
            setClearanceDate();
            setRefreshData(!refreshData);
            toast.success("Review Added", { autoClose: 200 });
          })
          .catch((err) => {
            setVisible(false);
            setUserName("");
            setUserEmail("");
            setRating(0);
            setReviewText("");
            setClearanceDate();
            setRefreshData(!refreshData);
            toast.error("Error Adding Review", { autoClose: 200 });
            console.error(err);
          }));
    }
  };
  return (
    <div>
      <Dialog
        header="Add Review"
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ minWidth: "350px", maxWidth: "450px" }}
      >
        <form onSubmit={handleSubmit}>
          <div className="p-fluid d-grid">
            <div className="p-field mt-1">
              <label htmlFor="profileImg">
                Upload Profile Image <small>(Skip If Already Uploaded)</small>
              </label>
              <InputText
                type="file"
                id="profileImg"
                name="userProfileImage"
                accept="image/*"
                onChange={handleProfileImageChange}
              />
            </div>
            <div className="p-field mt-4">
              <FloatLabel>
                <label htmlFor="name">Name</label>
                <InputText
                  id="name"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  required={true}
                />
                {errors.userName && (
                  <small className="p-error">{errors.userName}</small>
                )}
              </FloatLabel>
            </div>

            <div className="p-field mt-4">
              <FloatLabel>
                <label htmlFor="email">Email</label>
                <InputText
                  id="email"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  required={true}
                />
                {errors.userEmail && (
                  <small className="p-error">{errors.userEmail}</small>
                )}
              </FloatLabel>
            </div>
            <div className="p-field mt-4">
              <FloatLabel>
                <Calendar
                  id="cdate"
                  dateFormat="dd/mm/yy"
                  value={clearanceDate}
                  onChange={(e) => setClearanceDate(e.value)}
                  required={true}
                  maxDate={new Date()}
                />
                <label htmlFor="cdate">Clearance Date</label>
              </FloatLabel>
            </div>
            <div className="p-field mt-3">
              <Rating
                style={{ placeContent: "center" }}
                value={rating}
                onChange={(e) => setRating(e.value)}
                cancel={false}
                required={true}
              />
              {errors.rating && (
                <small className="p-error">{errors.rating}</small>
              )}
            </div>

            <div className="p-field mt-4">
              <FloatLabel>
                <InputTextarea
                  value={reviewText}
                  onChange={(e) => setReviewText(e.target.value)}
                  // placeholder="Write Review Here"
                  rows={3}
                  cols={20}
                  autoResize
                  required={true}
                />
                {errors.reviewText && (
                  <small className="p-error">{errors.reviewText}</small>
                )}
                <label>Your Review</label>
              </FloatLabel>
            </div>
            <div className="p-field my-3">
              <label htmlFor="reviewImg">Upload Review Image (if any)</label>
              <InputText
                id="reviewImg"
                type="file"
                name="reviewImage"
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>

            <Button
              label="Submit"
              type="submit"
              //   disabled={!userName || !userEmail || !rating || !reviewText}
            />
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default AddReviewModal;
