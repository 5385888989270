// services/facebookPixel.js
import ReactPixel from "react-facebook-pixel";

const initializeFacebookPixel = (pixelId) => {
  if (pixelId) {
    ReactPixel.init(pixelId, {
      autoConfig: true, // enable auto configuration
      debug: false, // enable or disable logging for debugging
    });

    // Track initial page view
    // ReactPixel.pageView();
  } else {
    console.error("Pixel ID is missing.");
  }
};

const trackPageView = () => {
  ReactPixel.pageView(); // For subsequent page views
};
const trackCustomEvent = (eventName, eventParams) => {
  if (eventName && eventParams) {
    ReactPixel.trackCustom(eventName, eventParams);
  } else {
    console.error("Event name or parameters are missing.");
  }
};
export { initializeFacebookPixel, trackPageView, trackCustomEvent };

// // src/facebookPixel.js
// import ReactPixel from "react-facebook-pixel";

// const pixelId = "YOUR_PIXEL_ID_HERE"; // Replace with your Facebook Pixel ID

// const options = {
//   autoConfig: true, // Optional
//   debug: false, // Optional
// };

// ReactPixel.init(pixelId, options);
// ReactPixel.pageView(); // To track page views

// export default ReactPixel;
