import React, { useState, useContext, useRef, useEffect } from "react";
import { Can, AbilityContext } from "../../configs/Ability-context";
import { AppContext } from "../../contexts/AppContext.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { ProgressBar } from "primereact/progressbar";
import { ContextMenu } from "primereact/contextmenu";

import { CustomDatePicker } from "./CustomDatePicker.js";
import LoaderModal from "./LoaderModal.js";
import ImportDataPanel from "./ImportDataPanel.js";
import CustomSelect from "./CustomSelect.js";
import { handleExportData, handleImportData } from "./DataImportExport.js";
import "../../styles/CustomListing.css";
// import { getUsers } from "../../../services/users/user-service";
import { formatDate } from "./Helpers.js";

const filterDate = (value, filter) => {
  if (
    filter === undefined ||
    filter === null ||
    (typeof filter === "string" && filter.trim() === "")
  ) {
    return true;
  }
  if (value === undefined || value === null) {
    return false;
  }
  return formatDate(new Date(value)) === formatDate(filter);
};

let _isMounted;
export const NewCustomListing = (props) => {
  // const {
  //   //  appName,
  //   // storeURL,
  // } = useContext(AppContext);
  const appName = "";
  const ability = useContext(AbilityContext);
  let importDataPanel = useRef(null);
  let fileUpload = useRef(null);

  let {
    collapsed,
    allRecords,
    selectedRecords,
    selectionDataKey,
    heading,
    emptyMessage,
    firstColumn,
    columns,
    isSyncing,
    syncedPercentage,
    globalSearchDisabled,
    permissionSubject,
    handleCreate,
    handleRequest,
    handleSync,
    totalRecords,
    syncedRecords,
    handleRefresh,
    handleAdd,
    handleTrash,
    trashLabel,
    trashPermission,
    trashIcon,
    exportData,
    importData,
    columnsToImport,
    updateColumns,
    extraToolbarButton,
    handleView,
    viewDataKey,
    customViewButton,
    readPermission,
    readPermissionSubject,
    handleEdit,
    editDataKey,
    editBtnTitle,
    customEditButton,
    updatePermission,
    updatePermissionSubject,
    handleDelete,
    deleteDataKey,
    deleteBtnTitle,
    customDeleteButton,
    deletePermission,
    deletePermissionSubject,
    extraButton,
    customLeftToolbar,
    customPaginatorLeft,
    bulkOptions,
    selectedBulkOption,
    bulkValueOptions,
    selectedBulkValueOption,
    handleBulkAction,
    tableRef,
    toastRef,
    sortField,
    rowClassName,
    rows,
    contextMenuRef,
    contextMenuModel,
    contextMenuSelection,
    viewSummaryHeader,
    summaryHeader,
    handleEmptyTrash,
    handleRestoreAll,
    showPaginator,
  } = props;

  //body templates
  const creatorBodyTemplate = (rowData) => {
    const createdBy = getUser(rowData.created_by);
    return (
      <>
        <span className="p-column-title">created by</span>
        {createdBy?.name}
      </>
    );
  };

  const createdAtTemplate = (rowData) => {
    let date = formatDate(new Date(rowData.createdAt));
    return (
      <>
        <span className="p-column-title">created at</span>
        {date}
      </>
    );
  };

  const updaterBodyTemplate = (rowData) => {
    const updatedBy = getUser(rowData.last_updated_by);
    return (
      <>
        <span className="p-column-title">last updated by</span>
        {updatedBy?.name}
      </>
    );
  };

  const updatedAtTemplate = (rowData) => {
    let date = formatDate(new Date(rowData.updatedAt));
    return (
      <>
        <span className="p-column-title">last updated at</span>
        {date}
      </>
    );
  };

  let timeStamps = [
    {
      field: "creator.name",
      header: "Created By",
      body: creatorBodyTemplate,
      // exportable: false,
      filter: true,
      filterPlaceholder: "Search by creator",
      filterMatchMode: "contains",
      headerStyle: { width: "120px" },
      permission: "viewCreator",
    },
    {
      field: "createdAt",
      header: "Created At",
      body: createdAtTemplate,
      sortable: true,
      filter: true,
      dateFilter: true,
      filterFunction: filterDate,
      headerStyle: { width: "120px" },
      permission: "viewCreatedAt",
    },
    {
      field: "updater.name",
      header: "Last Updated By",
      body: updaterBodyTemplate,
      filter: true,
      filterPlaceholder: "Search by updater",
      filterMatchMode: "contains",
      headerStyle: { width: "140px", fontSize: "16px" },
      permission: "viewUpdater",
    },
    {
      field: "updatedAt",
      header: "Last Updated At",
      body: updatedAtTemplate,
      sortable: true,
      filter: true,
      dateFilter: true,
      filterFunction: filterDate,
      headerStyle: { width: "140px", fontSize: "16px" },
      permission: "viewUpdatedAt",
    },
  ];

  let columnsForOptions = props.timeStamps
    ? [...columns, ...timeStamps]
    : [...columns];
  let columnOptions = columnsForOptions.map((col) =>
    !col.permission || col.permission
      ? // &&
        // ability.can(col.permission, permissionSubject)
        {
          field: col.field,
          header: col.header,
          selectedByDefault: col.selectedByDefault,
        }
      : null
  );
  let defaultSelectedColumn = columnOptions.filter((col) =>
    col ? col.selectedByDefault === true : false
  );

  const [
    { globalFilter, selectedColumns, createdDate, updatedDate },
    setState,
  ] = useState({
    globalFilter: null,
    selectedColumns: columnOptions,
    createdDate: null,
    updatedDate: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    _isMounted = true;
    const fetchData = async () => {
      // try {
      //   // const { data } = await getUsers();
      //   // _isMounted && setAllUsers(data);
      // } catch (error) {
      //   console.log(error);
      // }
    };
    fetchData();
    return () => {
      _isMounted = false;
    };
  }, []);

  useEffect(() => {
    changeValue("selectedColumns", defaultSelectedColumn);
  }, [props.columns]);

  const changeValue = (name, value) => {
    _isMounted && setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const getUser = (id) => allUsers.find((u) => u.id === id);

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    console.log(selectedColumns);
    let orderedSelectedColumns = columnOptions.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    changeValue("selectedColumns", orderedSelectedColumns);
  };

  const leftToolbarTemplate = () => {
    if (customLeftToolbar) return customLeftToolbar();
    else
      return (
        <>
          {handleCreate && (
            <Can I="create" a={permissionSubject}>
              <Button
                label="New"
                icon="pi pi-plus"
                className="p-button-rounded p-button-raised p-button-sm with-icon-button
                                p-py-1 p-px-3 bg-Primary bg-hover-tertiary hover-primary primary-border p-m-2 text-white"
                onClick={handleCreate}
              />
            </Can>
          )}
          {handleRequest && (
            <Button
              label="New"
              icon="pi pi-plus"
              className="p-button-rounded p-button-sm with-icon-button p-py-1 p-px-3 bg-Primary
                             bg-hover-tertiary hover-primary primary-border p-m-2"
              onClick={handleRequest}
            />
          )}
          {handleSync && (
            <Can I="sync" a={permissionSubject}>
              <Button
                label="Sync"
                icon="pi pi-spinner"
                className="p-button-rounded p-button-sm with-icon-button p-py-1 p-px-3 bg-primary-light
                                 bg-hover-tertiary hover-primary-light primary-light-border p-m-2"
                onClick={handleSync}
              />
            </Can>
          )}

          {importData && (
            <Can I="import" a={permissionSubject}>
              <Button
                label="Import"
                icon="pi pi-cloud-upload"
                className="p-button-rounded p-button-sm with-icon-button p-py-1 p-px-3 bg-Secondary
                                 bg-hover-tertiary hover-secondary secondary-border p-m-2"
                onClick={(e) => {
                  importDataPanel.current.toggle(e);
                }}
              />
            </Can>
          )}
          {/* {exportData && (
                <Can I="export" a={permissionSubject}>
                  <Button
                    label="Export"
                    icon="pi pi-cloud-download"
                    className="p-button-rounded p-button-sm with-icon-button p-py-1 p-px-3 bg-Primary
                                 bg-hover-tertiary hover-primary primary-border p-m-2"
                    onClick={() => {
                      handleExportData(
                        exportData,
                        setIsLoading
                        // appName
                      );
                    }}
                  />
                </Can>
              )} */}
          {handleRefresh && (
            <Button
              label={"Refresh"}
              icon="pi pi-refresh"
              className="p-button-rounded p-button-sm with-icon-button p-py-1 p-px-3 bg-primary-light
                             bg-hover-tertiary hover-primary-light primary-light-border p-m-2"
              onClick={handleRefresh}
            />
          )}
          {handleAdd && (
            <Button
              label={"Add New"}
              icon="pi pi-plus"
              className="p-button-rounded p-button-sm with-icon-button p-py-1 p-px-3 bg-primary-light
                             bg-hover-tertiary hover-primary-light primary-light-border p-m-2"
              onClick={handleAdd}
            />
          )}
          {handleTrash && (
            <Can
              I={trashPermission ? trashPermission : "readTrash"}
              a={permissionSubject}
            >
              <Button
                label={trashLabel ? trashLabel : "Trash"}
                icon={trashIcon ? trashIcon : "pi pi-trash"}
                // style={{ backgroundColor: "#023047", color: "white" }}
                className="p-button-rounded p-button-sm with-icon-button p-py-1 p-px-3 
                               bg-hover-tertiary hover-secondary secondary-border p-m-2 mx-1 text-white background-primary"
                onClick={handleTrash}
              />
            </Can>
          )}
          {/* <div
            // style={{ display: "flex", alignItems: "center", gap: "2px" }}
            className="mx-1"
          > */}
          {handleEmptyTrash && (
            <Can I="manage" a="all">
              <Button
                label="Empty Trash"
                icon="pi pi-trash"
                style={{
                  whiteSpace: "nowrap",
                  // backgroundColor: "#E63946",
                  color: "white",
                }}
                className="p-button-rounded p-button-sm with-icon-button p-py-1 p-px-3 bg-danger bg-hover-secondary hover-danger danger-border overflow-visible mx-1"
                onClick={handleEmptyTrash}
              />
            </Can>
          )}
          {handleRestoreAll && (
            <Can I="manage" a="all">
              <Button
                label="Restore All"
                icon="pi pi-undo"
                style={{
                  whiteSpace: "nowrap",
                  // backgroundColor: "#2A9D8F",
                  color: "white",
                }}
                className="p-button-rounded p-button-sm with-icon-button p-py-1 p-px-3 bg-success bg-hover-secondary hover-success success-border overflow-visible mx-1"
                onClick={handleRestoreAll}
              />
            </Can>
          )}
          {/* </div> */}
          {extraToolbarButton && extraToolbarButton}
        </>
      );
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        {!globalSearchDisabled && (
          <span className="p-input-icon-left p-m-2">
            {/* <i className="pi pi-search" /> */}
            <InputText
              onInput={(e) => changeValue("globalFilter", e.target.value)}
              className="p-inputtext-sm border-radius-3 color-primary"
              placeholder="Global search"
            />
          </span>
        )}
        <MultiSelect
          value={selectedColumns}
          fixedPlaceholder
          options={columnOptions}
          optionLabel="header"
          placeholder="Toggle columns"
          onChange={onColumnToggle}
          className="p-m-2 border-radius-3"
        />
      </>
    );
  };
  bulkOptions = bulkOptions
    ? bulkOptions.map((o) => {
        o.disabled = ability.cannot(o.permission, permissionSubject);
        delete o.permission;
        return o;
      })
    : null;
  bulkOptions = bulkOptions ? bulkOptions.filter((o) => !o.disabled) : null;
  const paginatorLeft = () => {
    return (
      <>
        {bulkOptions?.length > 0 && (
          <div className="p-d-inline-flex p-flex-column p-flex-sm-row p-flex-wrap p-jc-between p-ai-center p-mr-4">
            <Dropdown
              options={bulkOptions}
              value={selectedBulkOption}
              onChange={(e) => {
                props.changeValue("selectedBulkOption", e.value);
                props.changeValue("selectedBulkValueOption", null);
              }}
              placeholder={"Bulk actions"}
            />
            {selectedBulkOption &&
            bulkValueOptions &&
            Array.isArray(bulkValueOptions) ? (
              selectedBulkOption === "categories" ? (
                <CustomSelect
                  value={selectedBulkValueOption}
                  options={bulkValueOptions}
                  placeholder="Select categories"
                  handleChange={(e) => {
                    props.changeValue("selectedBulkValueOption", e.value);
                  }}
                  filter
                  isMulti
                />
              ) : (
                <Dropdown
                  options={bulkValueOptions}
                  value={selectedBulkValueOption}
                  onChange={(e) => {
                    props.changeValue("selectedBulkValueOption", e.value);
                  }}
                  placeholder={"Select.."}
                />
              )
            ) : (
              bulkValueOptions
            )}
            <Button
              label="Apply"
              className="p-button-danger p-button-raised p-button-sm p-ml-1"
              onClick={() => {
                handleBulkAction();
              }}
              disabled={
                !selectedBulkOption ||
                (bulkValueOptions &&
                  (!selectedBulkValueOption || selectedBulkValueOption == 0))
              }
            />
          </div>
        )}
        {customPaginatorLeft}
      </>
    );
  };
  const actionButtonsTemplate = (rowData) => {
    let customButton = (icon, onClick, disabled, title) => (
      <Button
        icon={icon}
        className="icon-button p-button-lg p-button-text"
        onClick={onClick}
        disabled={disabled}
        title={title}
      />
    );

    return (
      <div className="d-flex align-items-center">
        <div className="btn-group" role="group">
          {extraButton ? extraButton(customButton, rowData) : null}
          {handleView && (
            <Can
              I={readPermission ? readPermission(rowData) : "read"}
              a={
                readPermissionSubject
                  ? readPermissionSubject(rowData)
                  : permissionSubject
              }
            >
              {customViewButton ? (
                customViewButton(customButton, rowData)
              ) : (
                <Button
                  icon="pi pi-eye"
                  className="p-button-lg icon-button p-button-text"
                  onClick={() => {
                    handleView(viewDataKey ? rowData[viewDataKey] : rowData);
                  }}
                />
              )}
            </Can>
          )}
          {handleEdit && (
            <Can
              I={updatePermission ? updatePermission(rowData) : "update"}
              a={
                updatePermissionSubject
                  ? updatePermissionSubject(rowData)
                  : permissionSubject
              }
            >
              {customEditButton ? (
                customEditButton(customButton, rowData)
              ) : (
                <Button
                  icon="pi pi-pencil"
                  className="p-button-lg icon-button p-button-text mx-1"
                  onClick={(e) => {
                    handleEdit(editDataKey ? rowData[editDataKey] : rowData)(e);
                  }}
                  title={editBtnTitle ? editBtnTitle : "edit"}
                />
              )}
            </Can>
          )}
          {handleDelete && (
            <Can
              I={deletePermission ? deletePermission(rowData) : "delete"}
              a={
                deletePermissionSubject
                  ? deletePermissionSubject(rowData)
                  : permissionSubject
              }
            >
              {customDeleteButton ? (
                customDeleteButton(customButton, rowData)
              ) : (
                <Button
                  icon="pi pi-trash"
                  className="p-button-lg icon-button p-button-text mx-2"
                  onClick={(e) => {
                    handleDelete(
                      deleteDataKey ? rowData[deleteDataKey] : rowData
                    )(e);
                  }}
                  title={deleteBtnTitle ? deleteBtnTitle : "delete"}
                />
              )}
            </Can>
          )}
        </div>
      </div>
    );
  };

  // const actionButtonsTemplate = (rowData) => {
  //   let customButton = (icon, onClick, disabled, title) => (
  //     <Button
  //       icon={icon}
  //       className="icon-button p-button-lg p-button-text"
  //       onClick={onClick}
  //       disabled={disabled}
  //       title={title}
  //     />
  //   );
  //   return (
  //     <div>
  //       {extraButton ? extraButton(customButton, rowData) : null}
  //       {handleView && (
  //         <Can
  //           I={readPermission ? readPermission(rowData) : "read"}
  //           a={
  //             readPermissionSubject
  //               ? readPermissionSubject(rowData)
  //               : permissionSubject
  //           }
  //         >
  //           {customViewButton ? (
  //             customViewButton(customButton, rowData)
  //           ) : (
  //             <Button
  //               icon="pi pi-eye"
  //               className="p-button-lg icon-button p-button-text"
  //               onClick={() => {
  //                 handleView(viewDataKey ? rowData[viewDataKey] : rowData);
  //               }}
  //             />
  //           )}
  //         </Can>
  //       )}
  //       {handleEdit && (
  //         <>
  //           <Can
  //             I={updatePermission ? updatePermission(rowData) : "update"}
  //             a={
  //               updatePermissionSubject
  //                 ? updatePermissionSubject(rowData)
  //                 : permissionSubject
  //             }
  //           >
  //             {customEditButton ? (
  //               customEditButton(customButton, rowData)
  //             ) : (
  //               <Button
  //                 icon="pi pi-pencil"
  //                 className="p-button-lg icon-button p-button-text"
  //                 onClick={(e) => {
  //                   handleEdit(editDataKey ? rowData[editDataKey] : rowData)(e);
  //                 }}
  //                 title={editBtnTitle ? editBtnTitle : "edit"}
  //               />
  //             )}
  //           </Can>
  //         </>
  //       )}
  //       {handleDelete && (
  //         <Can
  //           I={deletePermission ? deletePermission(rowData) : "delete"}
  //           a={
  //             deletePermissionSubject
  //               ? deletePermissionSubject(rowData)
  //               : permissionSubject
  //           }
  //         >
  //           {customDeleteButton ? (
  //             customDeleteButton(customButton, rowData)
  //           ) : (
  //             <Button
  //               icon="pi pi-trash"
  //               className="p-button-lg icon-button p-button-text"
  //               onClick={(e) => {
  //                 handleDelete(
  //                   deleteDataKey ? rowData[deleteDataKey] : rowData
  //                 )(e);
  //               }}
  //               title={deleteBtnTitle ? deleteBtnTitle : "delete"}
  //             />
  //           )}
  //         </Can>
  //       )}
  //     </div>
  //   );
  // };

  const dateFilter = (field) => {
    return (
      <CustomDatePicker
        value={field === "createdAt" ? createdDate : updatedDate}
        handleChange={(value) => {
          tableRef.current.filter(value, field, "custom");
          changeValue(
            field === "createdAt" ? "createdDate" : "updatedDate",
            value
          );
        }}
        placeholder={
          field === "createdAt"
            ? "Search by created at"
            : "Search by updated at"
        }
        isClearable
      />
    );
  };

  const progressBarValueTemplate = (value) => {
    return (
      <span
        className={syncedPercentage > 49 ? "color-tertiary" : "color-primary"}
      >
        {" "}
        {syncedRecords}/{totalRecords} records synced
      </span>
    );
  };

  let columnsForComponents = props.timeStamps
    ? [...columns, ...timeStamps]
    : [...columns];
  let columnComponents = columnsForComponents.filter((col) =>
    selectedColumns.some((sCol) => (sCol ? sCol.field === col.field : false))
  );
  columnComponents = columnComponents.map((col) => {
    return (
      <Column
        body={col.body}
        editor={col.editor}
        exportable={col.exportable}
        field={col.field}
        filter={col.filter}
        style={{ borderBottom: "1px solid grey" }}
        filterElement={
          col.dateFilter ? dateFilter(col.field) : col.filterElement
        }
        filterFunction={
          col.filterFunction === "filterDate" ? filterDate : col.filterFunction
        }
        filterMatchMode={col.filterMatchMode}
        filterPlaceholder={col.filterPlaceholder}
        header={col.header}
        headerStyle={
          col.headerStyle ? { ...col.headerStyle } : { width: "170px" }
        }
        key={col.field}
        onEditorInit={col.onEditorInit}
        onEditorCancel={col.onEditorCancel}
        onEditorSubmit={col.onEditorSubmit}
        sortable={col.sortable}
        // showFilterOperator={false}
      />
    );
  });
  return (
    // <div className="p-mt-3 p-pt-6 root-div ">
    <div
      className={
        !collapsed
          ? "p-mt-3 p-pt-6 root-div container"
          : "p-mt-3 p-pt-6 root-div-collapsed"
      }
    >
      {/* <h1 className="bg-Secondary p-m-0 p-p-0 p-py-1 color-tertiary p-text-center p-text-normal">{heading}</h1> */}
      {viewSummaryHeader && summaryHeader}
      <h3 className="p-m-0 p-p-0 p-py-1 color-dark p-text-center p-text-normal text-center">
        {heading}
      </h3>
      <div
        className={`${
          isSyncing ? "border-radius-0" : ""
        } card p-m-3 new-custom-listing`}
      >
        <Toast ref={toastRef} className="p-mt-5" />
        <ContextMenu
          model={contextMenuModel}
          style={{ width: "auto" }}
          ref={contextMenuRef}
          onHide={() => props.changeValue("selectedRecord", null)}
        />
        {isSyncing && (
          <ProgressBar
            value={syncedPercentage.toFixed(0)}
            mode={totalRecords ? "determinate" : "indeterminate"}
            displayValueTemplate={progressBarValueTemplate}
            color="#bf1515"
            style={{
              height: totalRecords ? "1.2rem" : "9px",
              borderRadius: "0px",
            }}
          />
        )}
        {/* <Toolbar
          className="p-flex-wrap p-py-2"
          //   left={leftToolbarTemplate}
          // right={rightToolbarTemplate}
        /> */}
        <Toolbar className="p-flex-wrap p-py-2" left={leftToolbarTemplate} />
        <DataTable
          // paginator={showPaginator}
          paginator
          rows={allRecords.length <= 15 ? allRecords.length : 15}
          rowsPerPageOptions={[allRecords.length, 10, 15, 25, 50]}
          contextMenuSelection={contextMenuSelection}
          onContextMenuSelectionChange={(e) =>
            props.changeValue("selectedRecord", e.value)
          }
          onContextMenu={
            contextMenuRef
              ? (e) => contextMenuRef.current.show(e.originalEvent)
              : null
          }
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          editMode="cell"
          emptyMessage={
            emptyMessage ? emptyMessage : `No ${heading.toLowerCase()} found.`
          }
          globalFilter={globalFilter}
          loading={props.isLoading}
          pageLinkSize={4}
          //   paginator={true}
          paginatorTemplate={
            allRecords?.length > 100
              ? "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              : "CurrentPageReport RowsPerPageDropdown"
          }
          paginatorLeft={
            bulkOptions
              ? paginatorLeft()
              : customPaginatorLeft
              ? customPaginatorLeft
              : false
          }
          removableSort
          ref={tableRef}
          rowClassName={rowClassName}
          // rows={rows ? rows : 100}
          // rowsPerPageOptions={[100, 150, 250, 500, 1000, allRecords.length]}
          rowHover
          scrollable
          scrollHeight={"60vh"}
          value={allRecords}
          selection={bulkOptions?.length > 0 && selectedRecords}
          onSelectionChange={(e) => {
            props.changeValue("selectedRecords", e.value);
          }}
          dataKey={selectionDataKey}
          sortField={sortField}
        >
          {selectedRecords && bulkOptions?.length > 0 && (
            <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />
          )}
          {firstColumn}
          {columnComponents}
          {(handleView || handleEdit || handleDelete) && (
            <Column
              header="Action"
              className="action-buttons p-text-center"
              body={actionButtonsTemplate}
              headerStyle={{ width: "200px" }}
              style={{ borderBottom: "1px solid grey" }}
            />
          )}
        </DataTable>
      </div>
      <ImportDataPanel
        panelRef={importDataPanel}
        fileUploadRef={fileUpload}
        toastRef={toastRef}
        handleImport={(e) => {
          handleImportData(
            e,
            setIsLoading,
            columnsToImport,
            importData,
            toastRef,
            fileUpload,
            importDataPanel,
            updateColumns,
            // storeURL,
            handleRefresh
          );
        }}
      />
      <LoaderModal modalShow={isLoading} />
    </div>
  );
};
