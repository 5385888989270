import React, { useState, useEffect, useRef, useContext } from "react";
import { Column } from "primereact/column";
import { NewCustomListing } from "../Components/custom/NewCustomListing.jsx";
import { AbilityContext, Can } from "../configs/Ability-context.js";
import {
  deleteAllReviewsInTrash,
  deleteReview,
  getBussinessReviews,
  getReviewsTrash,
  moveReviewToTrash,
  restoreAllReviewsFromTrash,
  restoreReview,
  updateReview,
} from "../services/businessReviews.js";
import SummaryCard from "../Components/UI/SummaryCards.jsx";
import { FaCheckCircle, FaPlus, FaTh } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { MDBPopper } from "mdbreact";
import { RiHourglassLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import { formatDate } from "../Components/Helpers/Helper.js";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { FloatLabel } from "primereact/floatlabel";

const AllReviews = () => {
  const dt = useRef(null);
  const [loading, setLoading] = useState(false);
  const [allReviews, setAllReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("all");
  const [statusType, setStatusType] = useState("");
  const [reviewEditModalVisible, setReviewEditModalVisible] = useState(false);
  const [editedReview, setEditedReview] = useState({
    clearance_date: null,
    status: "",
    review_text: "",
  });

  const [title, setTitle] = useState("Reviews");
  const [
    {
      refreshData,
      activeEditor,
      editorText,
      trashView,
      selectedRecord,
      selectedRecords,
    },
    setState,
  ] = useState({
    refreshData: false,
    activeEditor: { field: "", id: "" },
    editorText: "",
    trashView: false,
    selectedRecords: [],
  });
  let contextMenu = useRef(null);
  const ability = useContext(AbilityContext);
  const changeValue = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const userRole = localStorage.getItem("userRole");
  const handleUpdateReview = async (e) => {
    e.preventDefault();
    try {
      console.log(editedReview.id, editedReview);

      const response = await updateReview(editedReview.id, editedReview);
      console.log(response);
      if (response.success) {
        setState((prevState) => ({
          ...prevState,
          refreshData: !refreshData,
        }));
        // setState("refreshData", !refreshData);
        setReviewEditModalVisible(false);
        console.log(response.message);
      } else {
        console.error(response.message);
        setReviewEditModalVisible(false);
      }
    } catch (error) {
      setReviewEditModalVisible(false);
      console.log(error);
    }
    // await updateReview(editedReview.id, editedReview);
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await getBussinessReviews();
        const sortedReviews = res.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setAllReviews(sortedReviews);
        setFilteredReviews(sortedReviews);
        setState((prevState) => ({
          ...prevState,
          trashView: false,
        }));
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [refreshData]);
  const handleRestore = (reviewId) => async (e) => {
    console.log(e);
    const isConfirmed = window.confirm(
      "Are You Sure You Want to Restore the Review"
    );
    if (isConfirmed) {
      changeValue("isLoading", true);
      try {
        const updatedReviews = allReviews.filter(
          (review) => review.id !== reviewId
        );

        const { success, message } = await restoreReview(reviewId);

        if (success) {
          setAllReviews(updatedReviews);
          setFilteredReviews(updatedReviews);
          setState((prevState) => ({
            ...prevState,
            selectedRecords: [],
          }));
          toast.success("Review restored successfully");
        } else {
          toast.error(message || "Something Went Wrong");
        }
      } catch (error) {
        toast.error("Something Went Wrong");
        console.log(error);
      } finally {
        changeValue("isLoading", false);
      }
    }
  };
  const handleMoveToTrash = (rowData) => async (e) => {
    const reviewId = rowData.id;
    console.log(reviewId);
    const isConfirmed = window.confirm(
      trashView
        ? "Are You sure You want to delete this Review"
        : "Are You sure you want to move this Review to trash"
    );
    if (isConfirmed) {
      setLoading(true);
      try {
        const { success, message } = trashView
          ? await deleteReview(reviewId)
          : await moveReviewToTrash(reviewId);
        let updatedReviews = allReviews.filter(
          (review) => review.id !== reviewId
        );
        if (success) {
          setAllReviews(updatedReviews);
          setFilteredReviews(updatedReviews);
          setState((prevState) => ({
            ...prevState,
            selectedRecords: [],
          }));
          toast.warning(
            trashView
              ? "Review Deleted Successfully"
              : "Review Moved to Trash Successfully"
          );
          // changeValue("refreshData", !refreshData);
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
  };
  const handleGetTrash = () => {
    const fetchData = async () => {
      changeValue("isLoading", true);
      try {
        const { data } = await getReviewsTrash();
        console.log(data);
        setAllReviews(data);
        setFilteredReviews(data);
        setState((prevState) => ({
          ...prevState,
          trashView: true,
        }));
      } catch (error) {
        console.log(error);
      }
      changeValue("isLoading", false);
    };
    fetchData();
  };
  const handleRestoreAll = async (e) => {
    const isConfirmed = window.confirm("Are you want to restore all Reviews?");
    if (isConfirmed) {
      changeValue("isLoading", true);
      try {
        const { success, message } = await restoreAllReviewsFromTrash();
        console.log(message);
        if (success) {
          changeValue("refreshData", !refreshData);
          toast.success("Reviews Restored Successfully");
        }
      } catch (error) {
        console.error(error);
      }

      changeValue("isLoading", false);
    }
  };
  const handleEmptyTrash = async (e) => {
    const isConfirmed = window.confirm(
      "Are You Want to delete all Reviews Permanently?"
    );
    if (isConfirmed) {
      setLoading(true);
      try {
        const { success, message } = await deleteAllReviewsInTrash();
        console.log(message);
        if (success) {
          changeValue("refreshData", !refreshData);
          toast.warning("Reviews Deleted Permanently From Trash");
        }
      } catch (error) {
        console.error(error);
      }

      changeValue("isLoading", false);
    }
  };
  let menuModel = trashView
    ? [
        {
          label: "Restore this record",
          icon: "pi pi-undo color-primary-light lead",
          command: (e) => {
            handleRestore(selectedRecord.id)(e);
          },
        },
        {
          label: "Delete this record",
          icon: "pi pi-trash color-primary-light lead",
          command: (e) => {
            handleMoveToTrash(selectedRecord.id)(e);
          },
        },
      ]
    : [
        {
          label: "Move to trash",
          icon: "pi pi-trash color-primary-light lead",
          command: (e) => {
            console.log(e);
            handleMoveToTrash(selectedRecord.id)(e);
          },
        },
      ];
  const handleUpdate = async (id, field, newValue) => {
    try {
      const response = await updateReview(id, { [field]: newValue });
      console.log(id, { [field]: newValue });

      if (response.success) {
        allReviews.map((order) => {
          return order.id === id ? { ...order, [field]: newValue } : order;
        });
        console.log(response.message);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleStatusUpdate = async (rowData, field) => {
    await handleUpdate(rowData.id, field, statusType);
    setStatusType("");
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field: "", id: "" },
      editorText: "",
      refreshData: !prevState.refreshData,
    }));
  };
  const handleCancelEdit = () => {
    setStatusType("");
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field: "", id: "" },
      editorText: "",
    }));
  };
  const inlineEditor = (field, rowData) => (
    <Inplace
      closable={false}
      active={activeEditor.field === field && activeEditor.id === rowData.id}
      onOpen={setEditor(field, rowData.id, rowData[field])}
    >
      <InplaceDisplay>
        <MDBPopper domElement tag="span" placement="right">
          {rowData[field] ? (
            <span>{rowData[field]}</span>
          ) : (
            <span>
              <FaPlus style={{ cursor: "pointer" }} />
            </span>
          )}
          <span>{rowData[field] ? "Click to edit" : "Click to add"}</span>
        </MDBPopper>
      </InplaceDisplay>
      <InplaceContent>{getEditor(rowData, field)}</InplaceContent>
    </Inplace>
  );
  const setEditor = (field, id, value) => () => {
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field, id },
      editorText: value,
    }));
  };
  const statusTypes = [
    { label: "Approved", value: "approved" },
    { label: "Pending", value: "pending" },
    { label: "Rejected", value: "rejected" },
  ];
  const statusBody = (rowData) => {
    const field = "status";
    const StatusfirstLetterUpperCase = rowData[field]
      ?.slice(0, 1)
      .toUpperCase();

    return (
      <Inplace
        closable={false}
        active={activeEditor.field === field && activeEditor.id === rowData.id}
        onOpen={setEditor(field, rowData.id, rowData[field])}
      >
        <InplaceDisplay>
          <MDBPopper domElement tag="span" placement="right">
            <span>{StatusfirstLetterUpperCase + rowData[field]?.slice(1)}</span>
            <span>{" Click to edit"}</span>
          </MDBPopper>
        </InplaceDisplay>
        <InplaceContent>{getStatusEditor(rowData, field)}</InplaceContent>
      </Inplace>
    );
  };
  const getStatusEditor = (rowData, field) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Dropdown
          id="couponType"
          value={statusType}
          options={statusTypes}
          onChange={(e) => setStatusType(e.value)}
          placeholder="Select Status"
          required
        />
        <Button
          icon="pi pi-check"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => handleStatusUpdate(rowData, field)}
        />
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-danger"
          onClick={handleCancelEdit}
        />
      </div>
    );
  };
  const handleInlineUpdate = async (rowData, field) => {
    await handleUpdate(rowData.id, field, editorText);
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field: "", id: "" },
      editorText: "",
      refreshData: !refreshData,
    }));
  };
  const handleInputChange = (e) => {
    setState((prevState) => ({ ...prevState, editorText: e.target.value }));
  };
  const getEditor = (rowData, field) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <InputText autoFocus value={editorText} onChange={handleInputChange} />
      <Button
        icon="pi pi-check"
        className="p-button-rounded p-button-success p-mr-2"
        onClick={() => handleInlineUpdate(rowData, field)}
      />
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-danger"
        onClick={handleCancelEdit}
      />
    </div>
  );
  const dateBody = (rowData) => {
    return (
      <span>
        {rowData.clearance_date
          ? formatDate(rowData.clearance_date)
          : formatDate(rowData.createdAt)}
      </span>
    );
  };

  const approved = allReviews?.filter((order) => {
    return order.status === "approved";
  });
  const pending = allReviews?.filter((order) => {
    return order.status === "pending";
  });
  const rejected = allReviews?.filter((order) => {
    return order.status === "rejected";
  });
  const columns = [
    {
      field: "user.name",
      header: "Username",
      filter: true,
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
    },
    {
      field: "aggregate_rating",
      header: "Rating",
      filter: true,
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
    },
    {
      field: "review_text",
      header: "Review Text",
      body: (rowData) => (
        <div>
          {rowData?.review_text && rowData.review_text.length > 25
            ? `${rowData.review_text.slice(
                0,
                rowData.review_text.lastIndexOf(" ", 25)
              )}...`
            : rowData?.review_text}
        </div>
      ),
      filter: true,
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      headerStyle: { width: "290px" },
    },
    {
      field: "status",
      header: "Status",
      filter: true,
      body: statusBody,
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
    },
  ];
  const onEdit = (rowData) => (e) => {
    setEditedReview(rowData);
    setReviewEditModalVisible(true);
  };

  return userRole === `"admin"` ? (
    <Can I="manage" a="all">
      {" "}
      <div className="summary-card-container container">
        <SummaryCard
          title="All Reviews"
          value={allReviews?.length}
          icon={<FaTh />} // or <FaListAlt /> or <FaTh />
          color="#E0F7FA" // or #F5F5F5, #E0F4F4
          onClick={() => {
            setFilteredReviews(allReviews);
            setCurrentFilter("");
            setTitle("Reviews");
          }}
        />
        <SummaryCard
          title="Pending"
          value={pending?.length}
          icon={<RiHourglassLine />}
          color="#DFF5E4"
          // color="#FFE5E5"
          onClick={() => {
            setFilteredReviews(pending);
            setCurrentFilter("pending");
            setTitle("Pending Reviews");
          }}
        />
        <SummaryCard
          title="Approved"
          value={approved?.length}
          icon={<FaCheckCircle />}
          color="#D9E5FF"
          onClick={() => {
            setFilteredReviews(approved);
            setCurrentFilter("approved");
            setTitle("Approved Reviews");
          }}
        />
        <SummaryCard
          title="Rejected"
          value={rejected?.length}
          icon={<GiCancel />}
          color="#FFE5E5"
          // color="#DFF5E4"
          onClick={() => {
            setFilteredReviews(rejected);
            setCurrentFilter("rejected");
            setTitle("Rejected Reviews");
          }}
        />
      </div>
      <NewCustomListing
        allRecords={filteredReviews}
        heading={title}
        firstColumn={
          <Column
            field="clearance_date"
            header="Clearance Date"
            body={dateBody}
            sortable={true}
            filter={true}
            style={{ borderBottom: "1px solid grey" }}
            filterPlaceholder="Search by date"
            filterMatchMode="contains"
            headerStyle={{ width: "170px" }}
          />
        }
        columns={columns}
        isLoading={loading}
        editDataKey={"id"}
        tableRef={dt}
        timeStamps={true}
        handleDelete={handleMoveToTrash}
        handleTrash={
          trashView
            ? () => changeValue("refreshData", !refreshData)
            : handleGetTrash
        }
        extraButton={
          trashView
            ? (customButton, rowData) => {
                return ability.can("restore", "reviews")
                  ? customButton(
                      "pi pi-refresh color-primary-light",
                      // "fas fa-undo-alt fa-sm color-primary",
                      (e) => {
                        handleRestore(rowData.id)(e);
                      },
                      false,
                      "restore this record"
                    )
                  : null;
              }
            : ""
        }
        handleEdit={true}
        customEditButton={(customButton, rowData) => {
          return (
            !(rowData.action === "manage" && rowData.subject === "all") &&
            customButton("pi pi-pencil", onEdit(rowData), false, "edit")
          );
        }}
        handleEmptyTrash={trashView ? handleEmptyTrash : null}
        handleRestoreAll={trashView ? handleRestoreAll : null}
        contextMenuRef={contextMenu}
        contextMenuModel={menuModel}
        trashLabel={trashView ? "Reviews" : `Trash`}
        trashIcon={trashView ? "pi pi-refresh" : "pi pi-trash"}
        selectedRecords={selectedRecords}
        contextMenuSelection={selectedRecord}
      />
      <Dialog
        visible={reviewEditModalVisible}
        onHide={() => setReviewEditModalVisible(false)}
        header="Edit Review"
        style={{ width: "90%", maxWidth: "370px" }}
      >
        <div className="d-grid">
          <form>
            <div className="p-field mt-4 col-12">
              <FloatLabel>
                <label htmlFor="name">Date</label>
                <Calendar
                  id="date"
                  name="clearance_date"
                  dateFormat="dd/mm/yy"
                  className="w-100"
                  value={
                    editedReview.clearance_date
                      ? new Date(editedReview.clearance_date)
                      : new Date(editedReview.createdAt)
                  }
                  onChange={(e) =>
                    setEditedReview({
                      ...editedReview,
                      clearance_date: e.value,
                    })
                  }
                />
              </FloatLabel>
            </div>
            <div className="p-field mt-4 col-12">
              <FloatLabel>
                <label htmlFor="name">Status</label>
                <Dropdown
                  id="couponType"
                  value={editedReview.status}
                  options={statusTypes}
                  onChange={(e) =>
                    setEditedReview({
                      ...editedReview,
                      status: e.value,
                    })
                  }
                  className="w-100"
                  placeholder="Select Status"
                  required
                />
              </FloatLabel>
            </div>
            <div className="p-field mt-4 col-12">
              <FloatLabel>
                <label htmlFor="review">Review</label>
                <InputTextarea
                  id="review"
                  name="review"
                  className="w-100"
                  style={{ height: "max-content" }}
                  value={editedReview.review_text}
                  onChange={(e) => {
                    setEditedReview({
                      ...editedReview,
                      review_text: e.target.value,
                    });
                  }}
                  rows={7}
                />
              </FloatLabel>
            </div>
            <div className="p-field mt-4">
              <Button
                label="Update"
                className="p-button-primary"
                onClick={handleUpdateReview}
              />
            </div>
          </form>
        </div>
      </Dialog>
    </Can>
  ) : (
    <>
      <div>you are not allowed to see this page</div>
    </>
  );
};

export default AllReviews;
