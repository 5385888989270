const getJson = (str) => {
  if (typeof str !== "string") return str;
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
};
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  // const options = { day: "numeric", month: "long", year: "numeric" };

  return date
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
    .replace(/ /g, "-");
};

export const formatDateWithoutDashes = (date) => {
  return date
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "numeric",
      year: "numeric",
    })
    .replace(/\//g, "");
};
export const getJsonData = async (data, columns) => {
  if (data) {
    if (Array.isArray(data)) {
      for (let eachDataElement of data) {
        eachDataElement = await getJsonData(eachDataElement, columns);
      }
    } else {
      for (let col of columns) {
        if (Array.isArray(col)) {
          data[col[0]] = await getJsonData(data[col[0]], col[1]);
        } else {
          data[col] =
            typeof data[col] !== "object" ? getJson(data[col]) : data[col];
        }
      }
    }
  } else return null;
  return data;
};
