import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams, useMatch } from "react-router-dom";
import { MDBRow, MDBCol } from "mdbreact";
import { getPermission, updatePermission } from "../services/permissions";
import { AbilityContext, Can } from "../configs/Ability-context";
import { ActionButton, Input } from "../Components/UI/Customs";
import LoaderModal from "../Components/custom/LoaderModal";
import PasswordConfirmationModal from "../Components/misc/PasswordConfirmationModal";
import { editUser, getUser, updateUser } from "../services/users";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";

let postcodeField, phoneField, emailField, nameField, roleField;
const INITIAL_STATE = {
  name: "",
  email: "",
  phone: "",
  postcode: "",
  role: "",
  role_id: null,
};

const EditUser = (props) => {
  const [
    {
      name,
      email,
      phone,
      postcode,
      // role,
      role_id,
      validationMessage,
      isLoading,
      isConfirmingPassword,
    },
    setState,
  ] = useState({
    ...INITIAL_STATE,
    validationMessage: "",
    isLoading: false,
    isConfirmingPassword: false,
  });
  const [refreshData, setRefreshData] = useState(false);
  const ability = useContext(AbilityContext);

  let permissionForm = useRef(null);
  let { APO_Permission } = props;
  let { user } = useParams();
  let navigate = useNavigate();
  const isRouteMatched = useMatch("/admin/users/edit/:user");

  useEffect(() => {
    const fetchData = async () => {
      // if (APO_Permission === "update") {
      user =
        user && user != 1 && ability.can("update", "users") && isRouteMatched
          ? user
          : 0;

      changeValue("isLoading", true);
      const { data } = user !== 1 && (await getUser(user));
      const { name, email, phone, postcode, role_id } = data;
      console.log("data in edit permission", data);
      setState((prevState) => ({
        ...prevState,
        name,
        email,
        phone,
        postcode,
        // role,
        role_id,
        isLoading: false,
      }));
    };
    fetchData();
  }, [refreshData]);
  const roleOptions = [
    {
      label: "Admin",
      value: 2,
    },
    {
      label: "Customer",
      value: 3,
    },
    {
      label: "Instructor",
      value: 4,
    },
  ];
  const changeValue = (name, value) =>
    setState((prevState) => ({ ...prevState, [name]: value }));

  const handleInput = (e) => {
    changeValue(e.target.name, e.target.value);
    // let { name } = e.target;
    // name === "slug"
    //   ? changeValue("slug", e.target.value)
    //   : changeValue("enabled", e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    let form = permissionForm.current;

    if (!form.checkValidity()) {
      form.classList.add("was-validated");
      return;
    }
    // let updatedUser = { name, email, phone, postcode, role_id };
    // const { success, message } = await editUser(user, updatedUser);

    // changeValue("validationMessage", message);
    // if (success) {
    //   toast.success("User Updated Successfully", { autoClose: 500 });
    //   setRefreshData(!refreshData);
    //   setTimeout(() => {
    //     success && navigate("/admin/users/all");
    //   }, 1500);
    // }
    changeValue("isConfirmingPassword", true);
    // !success && slugField.focus();
    // setTimeout(() => {
    //   success && navigate("/users");
    // }, 1500);
  };

  const handleUpdate = async () => {
    let updatedUser = { name, email, phone, postcode, role_id };
    const { success, message } = await editUser(user, updatedUser);
    changeValue("validationMessage", message);
    // !success && slugField.focus();
    changeValue("validationMessage", message);
    if (success) {
      toast.success("User Updated Successfully", { autoClose: 500 });
      setRefreshData(!refreshData);
      setTimeout(() => {
        success && navigate("/admin/users/all");
      }, 1500);
    }
  };

  return (
    <Can I={"update"} a="users">
      {/* {JSON.stringify(user)} */}
      <div className="screen-center h-100vh container ">
        <form
          ref={permissionForm}
          className="text-center py-5"
          // className="p-2 mx-auto text-center w-100 w-sm-75 w-md-50"
          // className="m-0 p-0 w-100 w-sm-75 w-md-50 text-center color-tertiary mx-auto"
          onSubmit={handleSubmit}
          style={{ border: "1px  solid grey", width: "400px", maxWidth: "90%" }}
          noValidate
        >
          <h5 className="font-weight-bold mb-3 mb-sm-4 text-dark">Edit User</h5>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div
              className=" mt-3 p-3"
              style={{ width: "400px", maxWidth: "90%" }}
            >
              <FloatLabel>
                <label className="fw-bold">Name</label>
                <InputText
                  id="name"
                  name="name"
                  value={name}
                  disabled
                  className="w-100"
                />
              </FloatLabel>
            </div>
            <div
              className=" mt-3 p-3"
              style={{ width: "400px", maxWidth: "90%" }}
            >
              <FloatLabel>
                <label className="fw-bold">Email</label>
                <InputText
                  id="email"
                  name="email"
                  value={email}
                  disabled
                  className="w-100"
                />
              </FloatLabel>
            </div>
            <div
              className=" mt-3 p-3"
              style={{ width: "400px", maxWidth: "90%" }}
            >
              <FloatLabel>
                <label className="fw-bold">Role</label>
                <Dropdown
                  value={role_id}
                  options={roleOptions}
                  onChange={(e) => changeValue("role_id", e.value)}
                  placeholder="Select Role"
                  className="w-100"
                />
              </FloatLabel>
            </div>
            {/* <div
              className=" mt-3 p-3"
              style={{ width: "400px", maxWidth: "90%" }}
            >
              <FloatLabel>
                <label className="fw-bold">Role</label>
                <InputText
                  id="role"
                  name="role"
                  value={role.name}
                  className="w-100"
                />
              </FloatLabel>
            </div> */}
            <div
              className=" mt-3 p-3"
              style={{ width: "400px", maxWidth: "90%" }}
            >
              <FloatLabel>
                <label className="fw-bold">Phone</label>
                <InputText
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={handleInput}
                  className="w-100"
                />
              </FloatLabel>
            </div>
            <div
              className=" mt-3 p-3"
              style={{ width: "400px", maxWidth: "90%" }}
            >
              <FloatLabel>
                <label className="fw-bold">Postcode</label>
                <InputText
                  id="postcode"
                  name="postcode"
                  value={postcode || ""}
                  onChange={handleInput}
                  className="w-100"
                />
              </FloatLabel>
            </div>
            <div
              className=" mt-3 p-3"
              style={{ width: "400px", maxWidth: "90%" }}
            >
              <ActionButton
                value={"Update"}
                className="bg-Secondary"
                type="submit"
              />
            </div>
          </div>
          {/* <MDBRow center className="m-0 p-0">
            <MDBCol middle sm="10" className="m-0">
              <Input label="Name" name={`name`} value={name} disabled />
              <Input label="Role" name={`role.name`} value={role.name} />
              <Input label="Email" name={`email`} value={email} disabled />
              <Input
                label="Phone"
                name={`phone`}
                value={phone}
                handleInput={handleInput}
                // setRef={(ref) => (slugField = ref)}
                required
                invalidMessage={`Please enter slug for this permission`}
                disabled
              />
              <Input
                label="PostCode"
                name={`postcode`}
                value={postcode}
                handleInput={handleInput}
                setRef={(ref) => (postcodeField = ref)}
                required
                invalidMessage={`Please enter slug for this permission`}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow center className="mx-0 my-2 p-0">
            <MDBCol sm="10" className="m-0 p-0 row">
              <MDBCol size="6" className="m-0 text-left">
                <label className="fw-bold" className="p-0 validating-label">
                  {validationMessage}&nbsp;
                </label>
              </MDBCol>
              <MDBCol size="6" className="m-0 text-right">
                <ActionButton
                  value={"Update"}
                  className="bg-Secondary"
                  type="submit"
                />
              </MDBCol>
            </MDBCol>
          </MDBRow> */}
        </form>
      </div>
      <LoaderModal modalShow={isLoading} />
      {
        <PasswordConfirmationModal
          modalShow={isConfirmingPassword}
          handleVerifyStatus={(verified) => {
            verified && handleUpdate();
            changeValue("isConfirmingPassword", false);
          }}
          hideModal={() => changeValue("isConfirmingPassword", false)}
        />
      }
    </Can>
  );
};

export default EditUser;
