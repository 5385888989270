import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { updateCourse } from "../../services/courses";
import ReactQuill from "react-quill";
import { Tooltip } from "primereact/tooltip";

const CourseEditViewModal = ({ visible, onHide, course }) => {
  const [editField, setEditField] = useState(null); // Track which field is being edited
  const [editedCourse, setEditedCourse] = useState({ ...course }); // Clone course for local editing
  useEffect(() => {
    if (course) {
      setEditedCourse({ ...course });
    }
  }, [course]);
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setEditedCourse((prevCourse) => ({
      ...prevCourse,
      [id]: value, // Update local state
    }));
  };

  // Call the handleUpdate function to update a single field
  const handleFieldUpdate = async (field) => {
    const newValue = editedCourse[field];
    try {
      const response = await updateCourse(course.id, { [field]: newValue });
      if (response.success) {
        console.log(response.message);
        setEditField(null); // Exit edit mode after success
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.error("Error updating course:", error);
    }
  };

  const handleQuillChange = (value, field) => {
    setEditedCourse((prevCourse) => ({
      ...prevCourse,
      [field]: value || "", // Update field with rich text editor content
    }));
  };

  const renderField = (field, label, type = "text", isRichText = false) => {
    return editField === field ? (
      <div key={field}>
        <label htmlFor={field}>
          <strong>{label}</strong>
        </label>
        {isRichText ? (
          <ReactQuill
            theme="snow"
            value={editedCourse[field] || ""}
            onChange={(value) => handleQuillChange(value, field)}
            className="w-full my-2"
          />
        ) : type === "textarea" ? (
          <textarea
            id={field}
            value={editedCourse[field] || ""}
            onChange={handleInputChange}
            rows={4}
            className="p-inputtext p-component w-full"
          />
        ) : (
          <input
            id={field}
            type={type}
            value={editedCourse[field] || ""}
            onChange={handleInputChange}
            className="p-inputtext p-component w-full"
          />
        )}
        <div className="d-flex justify-content-end my-2">
          <button
            className="button-secondary px-2 py-1 mx-1"
            onClick={() => setEditField(null)}
          >
            Cancel
          </button>
          <button
            className="button-primary px-2 py-1 mx-1 "
            onClick={() => handleFieldUpdate(field)}
          >
            Update
          </button>
        </div>
      </div>
    ) : (
      <div
        key={field}
        onClick={() => setEditField(field)}
        className="field-display cursor-pointer"
      >
        <Tooltip
          target={`#${field}`}
          content="Click to edit"
          position="mouse"
        />
        <p style={{ marginBottom: "0rem" }} id={field}>
          <strong>{label}:</strong>
          {isRichText ? (
            <span
              dangerouslySetInnerHTML={{ __html: editedCourse[field] }}
            ></span>
          ) : (
            editedCourse[field]
          )}
        </p>
      </div>
    );
  };

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="Course Details"
      style={{ width: "50vw" }}
      modal
    >
      <div>
        {renderField("name", "Course Name")}
        {renderField("description", "Description", "textarea")}
        {renderField("details", "Details", "text", true)}
        {/* {renderField("details", "Details", "textarea")} */}
        {renderField("duration", "Duration")}
        {renderField("price", "Price", "number")}
      </div>
    </Dialog>
  );
};

export default CourseEditViewModal;

// import { Dialog } from "primereact/dialog";
// import React, { useState } from "react";
// import { updateCourse } from "../../services/courses";

// const CourseEditViewModal = ({ visible, onHide, course }) => {
//   const [updateMode, setUpdateMode] = useState(false);

//   const [editField, setEditField] = useState(null);
//   const handleUpdate = async (id, field, newValue) => {
//     try {
//       const response = await updateCourse(id, { [field]: newValue });
//       console.log(id, { [field]: newValue });
//       if (response.success) {
//         // setAllCourses((prevCourses) =>
//         //   prevCourses.map((course) =>
//         //     course.id === id ? { ...course, [field]: newValue } : course
//         //   )
//         // );
//         // console.log(response.message);
//       } else {
//         console.error(response.message);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const renderField = (field) => {
//     return editField === field ? (
//       <div key={field}>
//         <input
//           id={field}
//           type={field === 'price' ? 'number' : 'text'}
//           value={editedCourse[field]}
//           onChange={handleInputChange}
//           onBlur={() => handleFieldUpdate(field)} // Update field on blur
//           className="p-inputtext p-component w-full"
//         />
//         <button
//           className="btn btn-secondary mt-2"
//           onClick={() => setEditField(null)}
//         >
//           Cancel
//         </button>
//       </div>
//     ) : (
//       <div key={field} onClick={() => setEditField(field)} className="field-display">
//         <p>
//           <strong>{field.charAt(0).toUpperCase() + field.slice(1)}:</strong> {editedCourse[field]}
//         </p>
//       </div>
//     );
//   };
// //   const CourseEditForm = () => {
// //     return (
// //       <div>
// //         <div className="p-fluid">
// //           <div className="p-field">
// //             <label htmlFor="name">Course Name</label>
// //             <input
// //               id="name"
// //               type="text"
// //               value={course.name}
// //               onChange={(e) => {
// //                 course.name = e.target.value;
// //               }}
// //               className="p-inputtext p-component w-full"
// //             />
// //           </div>
// //           <div className="p-field">
// //             <label htmlFor="description">Course Description</label>
// //             <textarea
// //               id="description"
// //               value={course.description}
// //               onChange={(e) => {
// //                 course.description = e.target.value;
// //               }}
// //               className="p-inputtext p-component w-full"
// //             />
// //           </div>
// //         </div>
// //         <div className="p-fluid">
// //           <div className="p-field">
// //             <label htmlFor="price">Course Price</label>
// //             <input
// //               id="price"
// //               type="number"
// //               value={course.price}
// //               onChange={(e) => {
// //                 course.price = e.target.value;
// //               }}
// //               className="p-inputtext p-component w-full"
// //             />
// //           </div>
// //         </div>
// //       </div>
// //     );
// //   };
//   return (
//     <Dialog
//       visible={visible}
//       onHide={onHide}
//       header="Course Details"
//       style={{ width: "50vw" }}
//       modal
//       footer={
//         <div>
//           <button
//             className="btn btn-primary"
//             onClick={() => setEditField('name')}
//           >
//             Edit Course
//           </button>
//           <button className="btn btn-secondary" onClick={onHide}>
//             Close
//           </button>
//         </div>
//       }
//     >
//       <div>
//         {renderField('name')}
//         {renderField('description')}
//         {renderField('details')}
//         {renderField('duration')}
//         {renderField('price')}
//       </div>
//     </Dialog>

//     // <Dialog
//     //   visible={visible}
//     //   onHide={onHide}
//     //   header="Course Details"
//     //   style={{ width: "50vw" }}
//     //   modal
//     //   footer={
//     //     <div>
//     //       <button
//     //         className="btn btn-primary"
//     //         onClick={() => {
//     //           setUpdateMode(true);
//     //         }}
//     //       >
//     //         Update
//     //       </button>
//     //       <button className="btn btn-secondary" onClick={onHide}>
//     //         Cancel
//     //       </button>
//     //     </div>
//     //   }
//     // >
//     //   <div>
//     //     <p>
//     //       <strong>Name:</strong> {course.name}
//     //     </p>
//     //     <p>
//     //       <strong>Description:</strong> {course.description}
//     //     </p>
//     //     <p>
//     //       <strong>Details:</strong> {course.details}
//     //     </p>
//     //     <p>
//     //       <strong>Duration:</strong> {course.duration}
//     //     </p>
//     //     <p>
//     //       <strong>Price:</strong> {course.price}
//     //     </p>
//     //   </div>
//     //   {updateMode && (
//     //     <div>
//     //       <CourseEditForm
//     //         course={course}
//     //         handleUpdate={handleUpdate}
//     //         onHide={() => {
//     //           setUpdateMode(false);
//     //           onHide();
//     //         }}
//     //       />
//     //     </div>
//     //   )}
//     // </Dialog>
//   );
// };

// export default CourseEditViewModal;
