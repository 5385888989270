import { FileUpload } from "primereact/fileupload";

export const CustomFileUpload = (props) => {
  let {
    fileUploadRef,
    accept,
    maxSize,
    chooseLabel,
    validExtensions,
    toastRef,
    auto,
    handleUpload,
    customUpload,
    multiple,
    className,
  } = props;
  return (
    <FileUpload
      className={className}
      ref={fileUploadRef} //to clear files after upload completed
      accept={accept}
      maxFileSize={maxSize}
      chooseLabel={chooseLabel}
      emptyTemplate={
        <p className="p-m-0">Drag and drop files here to upload.</p>
      }
      invalidFileSizeMessageSummary={`Invalid file size, `}
      invalidFileSizeMessageDetail={` Maximum file size allowed is ${
        maxSize / (1000 * 1000).toFixed(0)
      }.`}
      onSelect={(e) => {
        let fileName = e.files[0].name,
          fileExtension = fileName.slice(
            ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
          );
        if (!validExtensions.includes(fileExtension.toLowerCase())) {
          toastRef.current.show({
            severity: "error",
            summary: `Invalid file`,
            detail: `Allowed file extensions are .${validExtensions.join(
              " ."
            )}`,
            life: 4000,
          });
          fileUploadRef.current.clear();
        }
      }}
      // url='/api/data/import/products'
      uploadHandler={(e) => {
        handleUpload(e);
      }}
      customUpload={customUpload}
      auto={auto}
      multiple={multiple}
    />
  );
};
