import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { CustomButton, CustomInput } from "../UI/Customs";

const PasswordConfirmationModal = (props) => {
  const [password, setPassword] = useState("");
  const [validationMessage, setValidationMessage] = useState("");

  let verifyPasswordForm = useRef(null);
  let passwordField = useRef(null);

  useEffect(() => {
    setPassword("");
    setValidationMessage("");
  }, [props.modalShow]);

  const verifyPassword = () => {
    if (!verifyPasswordForm.current.checkValidity()) {
      verifyPasswordForm.current.classList.add("was-validated");
      return;
    } else {
      let options = {
        method: "POST",
        body: JSON.stringify({ username: "a", password: password }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      fetch("/api/auth/verify", options)
        .then((res) => res.json())
        .then((json) => {
          console.log(json);
          let { success, verified, message } = json;
          setValidationMessage(message);
          message === "incorrect password" && passwordField.current.focus();
          if (success) {
            verified && sendVerification(verified);
          } else sendVerification(false);
        })
        .catch((err) => {
          sendVerification(false);
          console.log(err);
        });
    }
  };

  const sendVerification = (value) => {
    setTimeout(() => {
      props.handleVerifyStatus(value);
    }, 250);
  };

  return (
    <Dialog
      header={
        <b
          // className="color-tertiary"
          style={{ letterSpacing: "0.05vw", fontWeight: "500" }}
        >
          {`Please confirm it's You !`}
        </b>
      }
      modal
      onHide={() => props.hideModal()}
      className="text-center mx-4 mx-md-0"
      style={{ width: "30vw" }}
      breakpoints={{
        "1200px": "40vw",
        "992px": "50vw",
        "768px": "75vw",
        "576px": "100vw",
      }}
      visible={props.modalShow}
      contentClassName="pb-2"
      footer={
        <div className="p-grid m-0 p-justify-end">
          <div className="col-6 col-md-4 px-4">
            <CustomButton
              label={"Verify"}
              onClick={() => verifyPassword()}
              bg="Secondary"
              color="tertiary"
              className="w-100 mb-3"
            />
          </div>
        </div>
      }
    >
      <form ref={verifyPasswordForm} className="m-0 pt-4" noValidate>
        <div
          className="m-0 d-flex flex-column background-primary"
          // style={{ border: "1px solid black" }}
        >
          <CustomInput
            type="password"
            label="Password"
            name={`password`}
            value={password}
            className="b2 w-100 background-secondary py-2 "
            handleInput={(e) => {
              setPassword(e.target.value);
            }}
            style={{ border: "1px solid black" }}
            ref={passwordField}
            required
          />
        </div>
        <label
          className={`p-0 validating-label ${
            validationMessage === "Verified" ? "text-success" : ""
          }`}
        >
          {validationMessage}&nbsp;
        </label>
      </form>
    </Dialog>
  );
};

export default PasswordConfirmationModal;
