export const createRole = async (newRole) => {
  try {
    const options = {
      method: "POST",
      body: JSON.stringify({ newRole }),
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(`/api/addNewRole`, options);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const getRoles = async () => {
  try {
    const res = await fetch(`/api/getAllRolesWithData`);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const getRole = async (id) => {
  try {
    const res = await fetch(`/api/getRoleProfile/${id}`);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const updateRole = async (id, updatedRole) => {
  try {
    const options = {
      method: "PUT",
      body: JSON.stringify({ updatedRole }),
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(`/api/updateRole/${id}`, options);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const deleteRole = async (id) => {
  try {
    const options = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(`/api/deleteRole/${id}`, options);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
