import React, { forwardRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import "../../styles/PersonalInfoStep.css";
import { Checkbox } from "primereact/checkbox";
import { Input } from "../UI/Customs";
import "../../styles/PostCode.css";
import CouponsViewModal from "../../Pages/Modals/CouponsViewModal";

const PersonalInfoStep = forwardRef(
  ({ input, handleChange, optIn, setOptIn }, ref) => {
    const [showCouponModal, setShowCouponModal] = useState(false);
    return (
      <div className="postcode-outer-div ">
        {" "}
        <CouponsViewModal
          show={showCouponModal}
          onHide={() => {
            setShowCouponModal(false);
          }}
        />
        {/* <div className="promo-text-container">
          <div className="promo-text-blinking text-center">
            Enjoy Discount On Our Courses, View Available Promos!
          </div>
        </div> */}
        <div
          className="promo-container text-center"
          onClick={() => {
            setShowCouponModal(true);
          }}
        >
          <div className="promo-text-gradient-blink ">
            View Available Promos!
          </div>
        </div>
        <form
          ref={ref}
          className="mb-4"
          style={{
            borderRadius: "10px",
            padding: "20px",
            margin: "auto",
            boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          }}
        >
          {/* <div
        className="mb-4"
        style={{
          borderRadius: "10px",
          padding: "20px",
          margin: "auto",
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        }}
      > */}
          {/* <FE */}
          {/* <h4>Contact details</h4> */}

          <div className="row justify-content-center align-items-center">
            {" "}
            <div className="col-12">
              <div className="p-field">
                <label htmlFor="postcode">Enter PostCode</label>
                <span className="p-float-label input-with-icon">
                  {/* <Input
                name="zipcode" // Added name attribute
                placeholder="Enter Your Area's Postcode"
                id="zipcode"
                type="text" // Changed to text for better handling of zip codes
                value={input.zipcode}
                onChange={handleChange}
                required
                style={{ borderRadius: "10px" }}
              /> */}
                  <InputText
                    name="postcode" // Added name attribute
                    placeholder="Enter Your Area's Postcode"
                    id="postcode"
                    type="text" // Changed to text for better handling of zip codes
                    value={input.postcode}
                    onChange={handleChange}
                    required
                    style={{ borderRadius: "10px" }}
                  />
                  <i className="pi pi-map-marker"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-12">
              <div className="p-field">
                <label htmlFor="email">Email</label>
                <span className="p-float-label input-with-icon">
                  <InputText
                    name="email" // Added name attribute
                    placeholder="Enter Your Email"
                    id="email"
                    type="email"
                    value={input.email}
                    onChange={handleChange}
                    required
                    style={{ borderRadius: "10px" }}
                  />
                  <i className="pi pi-envelope"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="p-field">
                <label htmlFor="name">Name</label>
                <span className="p-float-label input-with-icon">
                  <InputText
                    name="name" // Added name attribute
                    placeholder="Enter your name"
                    value={input.name}
                    onChange={handleChange}
                    id="name"
                    type="text"
                    required
                    style={{ borderRadius: "10px" }}
                  />
                  <i className="pi pi-user"></i>
                </span>
              </div>
            </div>

            <div className="col-6">
              <div className="p-field">
                <label htmlFor="phone">Phone Number</label>
                <span className="p-float-label input-with-icon">
                  <InputText
                    name="phone" // Added name attribute
                    placeholder="Enter Your Phone Number"
                    id="phone"
                    type="tel"
                    value={input.phone}
                    onChange={handleChange}
                    required
                    style={{ borderRadius: "10px" }}
                  />
                  <i className="pi pi-phone"></i>
                </span>
              </div>
            </div>
          </div>
          {/* <div className="row justify-content-center align-items-center">
        <div className="col-6">
          <div className="p-field">
            <label htmlFor="email">Email</label>
            <span className="p-float-label input-with-icon">
              <InputText
                name="email" // Added name attribute
                placeholder="Enter Your Email"
                id="email"
                type="email"
                value={input.email}
                onChange={handleChange}
                required
                style={{ borderRadius: "10px" }}
              />
              <i className="pi pi-envelope"></i>
            </span>
          </div>
        </div>
        <div className="col-6">
          <div className="p-field">
            <label htmlFor="zipcode">Enter PostCode</label>
            <span className="p-float-label input-with-icon">
              <InputText
                name="zipcode" // Added name attribute
                placeholder="Enter Your Area's Postcode"
                id="zipcode"
                type="text" // Changed to text for better handling of zip codes
                value={input.zipcode}
                onChange={handleChange}
                required
                style={{ borderRadius: "10px" }}
              />
              <i className="pi pi-map-marker"></i>
            </span>
          </div>
        </div> */}
          {/* <div className="col-6">
          <div className="p-field">
            <label htmlFor="zipcode">Zip Code</label>
            <span className="p-float-label input-with-icon">
              <InputText
                name="zipcode" // Added name attribute
                placeholder="Enter Your Area's ZipCode"
                id="zipcode"
                type="text" // Changed to text for better handling of zip codes
                value={input.zipcode}
                onChange={handleChange}
                required
                style={{ borderRadius: "10px" }}
              />
              <i className="pi pi-map-marker"></i>
            </span>
          </div>
        </div> */}
          {/* </div> */}
          <small className="d-flex align-items-center ">
            {/* <Checkbox
            inputId="optInCheckbox"
            onChange={(e) => setOptIn(e.checked)}
            checked={optIn}
            className="mx-2"
          ></Checkbox> */}
            <input
              inputId="optInCheckbox"
              onChange={(e) => setOptIn(e.checked)}
              checked={optIn}
              className="mx-2 cursor-pointer"
              type="checkbox"
            />
            <label htmlFor="optInCheckbox">
              Opt-In to be contacted regarding our courses and offers (uncheck
              to opt-out)
            </label>
          </small>
        </form>
      </div>
      // </div>
    );
  }
);

export default PersonalInfoStep;

// import React from "react";
// import { InputText } from "primereact/inputtext";
// import "../../styles/PostCode.css";

// const PostCode = ({ input, handleChange }) => {
//   return (
//     <div
//       className="mb-4 pt-5 post-code-main-div"
//       style={{
//         borderRadius: "10px",
//         padding: "10px",
//         // margin: "auto",
//         boxShadow: "0 0 10px rgba(0,0,0,0.1)",
//       }}
//     >
//       {/* <h4>Postcode</h4> */}
//       {/* <div className="row"> */}
//       {/* <div className="col-6">
//           <div className="p-field">
//             <label htmlFor="name">Name</label>
//             <span className="p-float-label input-with-icon">
//               <InputText
//                 name="name" // Added name attribute
//                 placeholder="Enter your name"
//                 value={input.name}
//                 onChange={handleChange}
//                 id="name"
//                 type="text"
//                 required
//                 style={{ borderRadius: "10px" }}
//               />
//               <i className="pi pi-user"></i>
//             </span>
//           </div>
//         </div> */}
//       {/* <div className="col-6">
//           <div className="p-field">
//             <label htmlFor="email">Email</label>
//             <span className="p-float-label input-with-icon">
//               <InputText
//                 name="email" // Added name attribute
//                 placeholder="Enter Your Email"
//                 id="email"
//                 type="email"
//                 value={input.email}
//                 onChange={handleChange}
//                 required
//                 style={{ borderRadius: "10px" }}
//               />
//               <i className="pi pi-envelope"></i>
//             </span>
//           </div>
//         </div> */}
//       {/* </div> */}
//       <div className="row">
//         {/* <div className="col-6"> */}
//         {/* <div className="p-field">
//             <label htmlFor="phone">Phone Number</label>
//             <span className="p-float-label input-with-icon">
//               <InputText
//                 name="phone" // Added name attribute
//                 placeholder="Enter Your Phone Number"
//                 id="phone"
//                 type="tel"
//                 value={input.phone}
//                 onChange={handleChange}
//                 required
//                 style={{ borderRadius: "10px" }}
//               />
//               <i className="pi pi-phone"></i>
//             </span>
//           </div> */}
//         {/* </div> */}
//         <div
//           className="d-flex align-items-center w-100 justify-content-center"
//           style={
//             {
//               // marginLeft: "25%",
//             }
//           }
//         >
//           <div className="p-field">
//             <label htmlFor="zipcode" className="fw-bold">
//               Enter PostCode
//             </label>
//             <span className="p-float-label input-with-icon">
//               <InputText
//                 name="zipcode" // Added name attribute
//                 placeholder="Enter Your Area's Postcode"
//                 id="zipcode"
//                 type="text" // Changed to text for better handling of zip codes
//                 value={input.zipcode}
//                 onChange={handleChange}
//                 required
//                 style={{ borderRadius: "10px" }}
//               />
//               <i className="pi pi-map-marker"></i>
//             </span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PostCode;
