import React, { useState, useEffect, useRef, useContext } from "react";
import { Column } from "primereact/column";
import { Link, useNavigate } from "react-router-dom";
import { AbilityContext, Can } from "../configs/Ability-context";
import {
  deleteAllUsersInTrash,
  deleteUser,
  getUsers,
  getUsersTrash,
  moveUserToTrash,
  restoreAllUsersFromTrash,
  restoreUser,
} from "../services/users";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { formatToCamelCase } from "../Components/custom/Helpers";
import { toast } from "react-toastify";
import { NewCustomListing } from "../Components/custom/NewCustomListing";
import UserDetailsModal from "./Modals/UserDetailsModal";

// let _isMounted;
const AllUsers = (props) => {
  let dt = useRef(null);
  let navigate = useNavigate();

  const ability = useContext(AbilityContext);
  let contextMenu = useRef(null);
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [showUserDetailsDialog, setShowUserDetailsDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserRole, setSelectedUserRole] = useState(null);
  const [accountApproval, setAccountApproval] = useState(false);
  const [
    {
      allUsers,
      isLoading,
      refreshData,
      selectedAction,
      selectedSubject,
      selectedEnabled,
      trashView,
      selectedRecord,
      selectedRecords,
    },
    setState,
  ] = useState({
    allUsers: [],
    isLoading: false,
    refreshData: false,
    selectedAction: null,
    selectedSubject: null,
    selectedEnabled: null,
    trashView: false,
    selectedRecords: [],
  });
  const obfuscate = (value) => btoa(value); // Base64 encode
  // const deobfuscate = (value) => atob(value); // Base64 decode

  useEffect(() => {
    // _isMounted = true;
    const fetchData = async () => {
      changeValue("isLoading", true);
      const { data } = await getUsers();
      const sortedUsers = data.sort(
        (a, b) => b.id - a.id
        // (a, b) => new Date(b.date) - new Date(a.date)
      );

      // _isMounted &&
      setState((prevState) => ({
        ...prevState,
        allUsers: sortedUsers,
        isLoading: false,
        trashView: false,
      }));
    };
    fetchData();
    // return () => {
    //   // _isMounted = false;
    // };
  }, [refreshData]);

  const changeValue = (name, value) => {
    // _isMounted
    //  &&
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const approveUser = async () => {
    try {
      const response = await fetch(
        `/api/users/account-approval/${selectedUserId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ accountApproval }),
        }
      );
      const result = await response.json();
      if (response.ok) {
        changeValue("refreshData", !refreshData); // Refresh data to reflect the changes
        setShowApproveDialog(false);
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error("Error approving user:", error);
    }
  };
  const handleEdit = (id) => (e) => {
    // navigate(`/users/permissions/edit/${id}`);
    navigate(`/admin/users/edit/${id}`);
  };

  const handleMoveToTrash = (userId) => async (e) => {
    console.log(userId);
    // let text = "Press a button!\nEither OK or Cancel.";
    // if (confirm(text) === true) {
    //   text = "You pressed OK!";
    // } else {
    //   text = "You canceled!";
    // }
    const isConfirmed = window.confirm(
      trashView
        ? "Are You sure You want to delete this user"
        : "Are You sure you want to move this user to trash"
    );
    if (isConfirmed) {
      changeValue("isLoading", true);
      try {
        const { success, message } = trashView
          ? await deleteUser(userId)
          : await moveUserToTrash(userId);
        console.log(message);
        let updatedUsers = allUsers.filter((user) => user.id !== userId);
        if (success) {
          // toast.done(message);
          // _isMounted &&
          setState((prevState) => ({
            ...prevState,
            allUsers: updatedUsers,
            selectedRecords: [],
          }));
          toast.warning(
            trashView
              ? "User Deleted Successfully"
              : "User Moved to Trash Successfully"
          );
          // changeValue("refreshData", !refreshData);
        }
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(error);
      }

      changeValue("isLoading", false);
    }
  };
  const handleRestoreAll = async (e) => {
    // console.log("Restore All Clicked");
    const isConfirmed = window.confirm("Are you want to restore all Users?");
    if (isConfirmed) {
      changeValue("isLoading", true);
      try {
        const { success } = await restoreAllUsersFromTrash();
        if (success) {
          changeValue("refreshData", !refreshData);
          toast.success("Users Restored Successfully");
        }
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(error);
      }

      changeValue("isLoading", false);
    }
  };
  const handleEmptyTrash = async (e) => {
    const isConfirmed = window.confirm(
      "Are You Want to delete all Users Permanently?"
    );
    if (isConfirmed) {
      changeValue("isLoading", true);
      try {
        const { success } = await deleteAllUsersInTrash();

        if (success) {
          changeValue("refreshData", !refreshData);
          toast.warning("All Users Deleted Permanently From Trash");
        }
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(error);
      }

      changeValue("isLoading", false);
    }
  };
  const handleRestore = (userId) => async (e) => {
    console.log(e);
    const isConfirmed = window.confirm(
      "Are You Sure You Want to Restore the User"
    );
    if (isConfirmed) {
      changeValue("isLoading", true);
      try {
        // Filter out the user locally
        const updatedUsers = allUsers.filter((user) => user.id !== userId);

        // Call the API to restore the user
        const { success, message } = await restoreUser(userId);

        if (success) {
          toast.success("User restored successfully");
          // Update the state with the filtered users
          changeValue("allUsers", updatedUsers);
        } else {
          toast.error(message || "Something Went Wrong");
        }
      } catch (error) {
        toast.error("Something Went Wrong");
        console.log(error);
      } finally {
        // Ensure that the loading state is set to false in both success and error cases
        changeValue("isLoading", false);
      }
    }
  };

  // const handleRestore = (userId) => async (e) => {
  //   // console.log('bktId', bktId)
  //   console.log(e);
  //   // isConfirmed({
  //   //   target: selectedRecord ? e.originalEvent.target : e.target,
  //   //   message: "Are you sure to restore this record?",
  //   // accept: async () => {
  //   changeValue("isLoading", true);
  //   try {
  //     let updatedUsers = allUsers.filter((user) => user.id !== userId);
  //     const { success } = await restoreUser(userId)
  //       .then((res) => {
  //         console.log(res);

  //         toast.done("User restored successfully");
  //         // toast.current.show({
  //         //   severity: "success",
  //         //   detail: `User restored successfully.`,
  //         // });
  //         changeValue("allUsers", updatedUsers);
  //         changeValue("isLoading", false);
  //       })
  //       .catch((err) => {
  //         toast.error("Something Went Wrong");
  //       });
  //     // let updatedUsers = allUsers.filter((user) => user.id !== userId);
  //     // if (success) {
  //     //   toast.done("User restored successfully");
  //     //   // toast.current.show({
  //     //   //   severity: "success",
  //     //   //   detail: `User restored successfully.`,
  //     //   // });
  //     //   changeValue("allUsers", updatedUsers);
  //     //   changeValue("isLoading", false);
  //     // }
  //   } catch (error) {
  //     toast.error("Something Went Wrong");
  //     changeValue("isLoading", false);
  //     // toast.current.show({
  //     //   severity: "error",
  //     //   summary: "Something went wrong",
  //     // });
  //     console.log(error);
  //   }
  //   changeValue("isLoading", false);
  //   //   },
  //   // });
  // };
  const handleGetTrash = () => {
    const fetchData = async () => {
      changeValue("isLoading", true);
      try {
        const { data } = await getUsersTrash();
        console.log(data);

        // _isMounted &&
        setState((prevState) => ({
          ...prevState,
          allUsers: data,
          trashView: true,
        }));
      } catch (error) {
        console.log(error);
      }
      changeValue("isLoading", false);
    };
    fetchData();
  };
  const openApproveDialog = (userId) => {
    const user = allUsers.find((user) => user.id === userId);
    if (user) {
      setAccountApproval(!user.account_approved);
      setSelectedUserId(userId);
      setShowApproveDialog(true);
    }
    // setSelectedUserId(userId);
    // setShowApproveDialog(true);
  };

  const closeApproveDialog = () => {
    setShowApproveDialog(false);
    setSelectedUserId(null);
  };
  const ApprovalBody = (rowData) => {
    return (
      <InputSwitch
        // style={{ maxWidth: "2px" }}
        checked={rowData.account_approved}
        onChange={(e) => openApproveDialog(rowData.id)}
        // onChange={(e) => {
        //   setAccountApproval(!rowData.account_approved);
        //   console.log(accountApproval);
        //   openApproveDialog(rowData.id, e.value);
        // }}
      />
    );

    // if (rowData.account_approved) {
    //   return <span>Approved</span>;
    // } else {
    //   return (
    //     <span>
    //       Not Approved
    //       <button
    //         style={{
    //           backgroundColor: "#023047",
    //           color: "white",
    //           borderRadius: "20px",
    //         }}
    //         onClick={() => openApproveDialog(rowData.id)}
    //       >
    //         Approve
    //       </button>
    //     </span>
    //   );
    // }
  };
  const RoleBody = (rowData) => {
    const formattedRole = formatToCamelCase(rowData?.role?.name);
    return <span>{formattedRole}</span>;
  };
  const ActionBody = (rowData) => {
    const readonly = obfuscate("true"); // Encode 'true' as Base64
    const profileUrl =
      rowData?.role?.name === "instructor"
        ? `/instructor/profile?userId=${encodeURIComponent(
            rowData?.id
          )}&readonly=${encodeURIComponent(readonly)}`
        : `/student/profile?userId=${encodeURIComponent(
            rowData?.id
          )}&readonly=${encodeURIComponent(readonly)}`;

    // const profileUrl =
    //   rowData?.role?.name === "instructor"
    //     ? `/instructor/profile?userId=${encodeURIComponent(
    //         rowData?.id
    //       )}&readonly=${encodeURIComponent(true)}`
    //     : `/student/profile?userId=${encodeURIComponent(
    //         rowData?.id
    //       )}&readonly=${encodeURIComponent(true)}`;
    return (
      <div>
        <Link
          to={profileUrl}
          className=" cursor-pointer p-2"
          // onClick={() => {
          //   setSelectedUserId(rowData.id);
          //   setSelectedUserRole(rowData.role.name);
          //   setShowUserDetailsDialog(true);
          // }}
          style={{
            backgroundColor: "#219EBC ",
            color: "white",
            // backgroundColor: "#D5D5D5 ",
            borderRadius: "5px 0px 0px 5px",
            width: "25px",
            margin: "1px",
          }}
        >
          <i className="pi pi-eye" style={{ color: "white" }}></i>
        </Link>
      </div>
    );
  };
  let menuModel = trashView
    ? [
        {
          label: "Restore this record",
          icon: "pi pi-undo color-primary-light lead",
          command: (e) => {
            handleRestore(selectedRecord.id)(e);
          },
        },
        // {
        //   label: "View product on store",
        //   icon: "pi pi-external-link color-primary-light lead",
        //   command: () => {
        //     window.open(selectedRecord.permalink, "_blank");
        //   },
        // },
        {
          label: "Delete this record",
          icon: "pi pi-trash color-primary-light lead",
          command: (e) => {
            handleMoveToTrash(selectedRecord.id)(e);
          },
        },
      ]
    : [
        // {
        //   label: "View product locally",
        //   icon: "pi pi-eye color-primary-light lead",
        //   command: () => {
        //     handleView(selectedRecord.bkt_id);
        //   },
        // },
        // {
        //   label: "View product on store",
        //   icon: "pi pi-external-link color-primary-light lead",
        //   command: () => {
        //     window.open(selectedRecord.permalink, "_blank");
        //   },
        // },
        {
          label: "Edit User",
          icon: "pi pi-pencil color-primary-light lead",
          command: () => {
            handleEdit(selectedRecord.id)();
          },
        },
        {
          label: "Move to trash",
          icon: "pi pi-trash color-primary-light lead",
          command: (e) => {
            console.log(e);
            handleMoveToTrash(selectedRecord.id)(e);
          },
        },
      ];
  let columns = [
    {
      field: "email",
      header: "Email",
      sortable: true,
      filter: true,
      selectedByDefault: true,
    },
    {
      field: "postcode",
      header: "PostCode",
      sortable: true,
      filter: true,
      selectedByDefault: true,
      filterPlaceholder: "Search by slug",
      filterMatchMode: "contains",
    },
    {
      field: "phone",
      header: "Phone",
      sortable: true,
      filter: true,
      selectedByDefault: true,
      filterPlaceholder: "Search by slug",
      filterMatchMode: "contains",
    },
    {
      field: "account_approved",
      header: "Account Approval",
      sortable: true,
      filter: true,
      // body: ApprovalBody,
      body: (rowData) => ApprovalBody(rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by slug",
      filterMatchMode: "contains",
    },
    {
      header: "User Action",
      body: (rowData) => ActionBody(rowData),
      selectedByDefault: true,
      headerStyle: { width: "290px" },
    },
    {
      field: "role.name",
      header: "Role",
      sortable: true,
      filter: true,
      body: (rowData) => RoleBody(rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by slug",
      filterMatchMode: "contains",
    },
  ];

  return (
    // <Can I="read" a={persons}>
    <Can I="read" a="persons">
      {/* <p className="text-dark text-center">
        {" "}
        {trashView ? "trashView" : "No Trash View"}
      </p> */}
      <>
        <UserDetailsModal
          userId={selectedUserId}
          userRole={selectedUserRole}
          visible={showUserDetailsDialog}
          onHide={() => {
            setShowUserDetailsDialog(false);
            setSelectedUserId(null);
            setSelectedUserRole(null);
          }}
        />
        {/* {JSON.stringify(allUsers)} */}
        {/* <CustomListing */}
        <NewCustomListing
          allRecords={allUsers}
          heading={trashView ? "Users (trash)" : "Users"}
          firstColumn={
            <Column
              field="name"
              header="Name"
              sortable={true}
              filter={true}
              style={{ borderBottom: "1px solid grey" }}
              // filterElement={actionFilter}
              headerStyle={{ width: "170px" }}
            />
          }
          columns={columns}
          permissionSubject={"users"}
          isLoading={isLoading}
          // handleRefresh={() => {
          //   changeValue("refreshData", !refreshData);
          // }}
          handleRefresh={
            trashView ? false : () => changeValue("refreshData", !refreshData)
          }
          handleEdit={true}
          customEditButton={(customButton, rowData) => {
            return (
              !(rowData.action === "manage" && rowData.subject === "all") &&
              customButton(
                "pi pi-pencil",
                handleEdit(rowData.id),
                false,
                "edit"
              )
            );
          }}
          handleEmptyTrash={trashView ? handleEmptyTrash : null}
          handleRestoreAll={trashView ? handleRestoreAll : null}
          handleTrash={
            trashView
              ? () => changeValue("refreshData", !refreshData)
              : handleGetTrash
          }
          extraButton={
            trashView
              ? (customButton, rowData) => {
                  return ability.can("restore", "products")
                    ? customButton(
                        "pi pi-refresh color-primary-light",
                        // "fas fa-undo-alt fa-sm color-primary",
                        (e) => {
                          handleRestore(rowData.id)(e);
                        },
                        false,
                        "restore this record"
                      )
                    : null;
                }
              : ""
            // (customButton, rowData) => {
            //     return ability.can('read', 'products') ?
            //         customButton('pi pi-eye color-primary-light', (e) => { handleView(rowData.bkt_id) }, false, 'view product locally')
            //         :
            //         null
            // }
          }
          selectedRecords={selectedRecords}
          selectionDataKey={"id"}
          contextMenuRef={contextMenu}
          contextMenuModel={menuModel}
          contextMenuSelection={selectedRecord}
          trashLabel={trashView ? "Users" : `Trash`}
          trashIcon={trashView ? "pi pi-refresh" : "pi pi-trash"}
          handleDelete={handleMoveToTrash}
          deleteDataKey={"id"}
          tableSmall
          tableRef={dt}
          timeStamps={true}
          // selectedBulkOption={selectedBulkOption}
          //       selectedBulkValueOption={selectedBulkValueOption}
          //       bulkOptions={trashView ? false : bulkOptions}
          //       bulkValueOptions={bulkValueOptions()}
          //       handleBulkAction={trashView ? false : handleBulkAction}
        />
        <Dialog
          header="Approve User"
          visible={showApproveDialog}
          onHide={closeApproveDialog}
        >
          <p>
            Are you sure you want to{" "}
            {!accountApproval ? "disapprove" : "approve"} this user?
          </p>

          {/* <p>Are you sure you want to approve this user?</p> */}
          <Button label="Yes" onClick={() => approveUser()} />
          <Button
            label="No"
            onClick={closeApproveDialog}
            className="p-button-secondary"
          />
        </Dialog>
      </>
    </Can>
  );
};

export default AllUsers;
