import React from "react";
import "../styles/AfterHeroSection.css";
import { Link } from "react-router-dom";
const AfterHeroSection = () => {
  return (
    <div className=" after-hero-section mt-0 p-5 mx-5 row ">
      <div className=" col-5 image-section ">
        <img
          src={require("../Assets/tagline section.webp")}
          alt="Hero"
          loading="lazy"
        />
      </div>
      <div className="text-section col-2 text-center"></div>
      <div className="text-section col-md-5">
        <h2>YOUR FASTER ROUTE TO DRIVING</h2>
        <p className="text-secondary text-section-p">
          See how <strong>PassSwiftly</strong> can help you get behind the
          wheels
        </p>
        <div className="d-flex justify-content-center align-items-center">
          <Link to="/courses/beginner" style={{ textDecoration: "none" }}>
            <button className="button-primary px-2 py-2">Explore More</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AfterHeroSection;
