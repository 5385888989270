import React, { useState, useEffect, useRef } from "react";
// import { Can, AbilityContext } from "../configs/Ability-context.js";
import { Column } from "primereact/column";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import { InputText } from "primereact/inputtext";
import { MDBPopper } from "mdbreact";
import { Button } from "primereact/button";
import "../../styles/AllJobs.css";
import { FaBoxOpen, FaChartLine, FaTimesCircle, FaUsers } from "react-icons/fa";
import { JobsDetailsModal } from "../Modals/JobsDetailsModal.jsx";
import SummaryCard from "../../Components/UI/SummaryCards.jsx";
import { NewCustomListing } from "../../Components/custom/NewCustomListing.jsx";
import { getAllOrders, updateOrder } from "../../services/orders.js";
import { getSettings } from "../../services/settings.js";
import LessonModal from "../Modals/LessonModal.jsx";
import { toast } from "react-toastify";

const InstructorJobs = ({ userId }) => {
  const dt = useRef(null);
  const [
    {
      //   instructorJobs,
      isReadingOrder,
      isLoading,
      refreshData,
      isUpdatingSetting,
      selectedSetting,
      activeEditor,
      editorText,
    },
    setState,
  ] = useState({
    // instructorJobs: [],
    isLoading: false,
    isReadingOrder: false,
    refreshData: false,
    isUpdatingSetting: false,
    selectedSetting: null,
    activeEditor: { field: "", id: "" },
    editorText: "",
  });
  const [showLessonModal, setShowLessonModal] = useState(false);
  const [instructorJobs, setInstructorJobs] = useState([]);
  const [orderPopulated, setOrderPopulated] = useState();
  const [allSettings, setAllSettings] = useState([]); // const ability = useContext(AbilityContext);
  const [showJobAssignModal, setShowJobAssignModal] = useState(false);
  const [courseHours, setCourseHours] = useState(0);

  useEffect(() => {
    const getOrders = async () => {
      console.log("userId", userId);
      console.log("userId", typeof userId);
      await getAllOrders()
        .then((data) => {
          console.log(data);
          console.log(data.filter((order) => order.instructor_id === 21));

          const filteredJobs = data.filter(
            (order) => order.instructor_id === JSON.parse(userId)
          );
          console.log("filteredJobs", filteredJobs);

          setInstructorJobs(filteredJobs);
          console.log("InstructorJobs", InstructorJobs);

          // setallOrders(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getOrders();
  }, [userId]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getSettings();
        setAllSettings(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  }

  const userRole = localStorage.getItem("userRole");
  const handleUpdate = async (id, field, newValue) => {
    try {
      const response = await updateOrder(id, { [field]: newValue });
      console.log(id, { [field]: newValue });

      if (response.success) {
        instructorJobs.map((order) => {
          return order.id === id ? { ...order, [field]: newValue } : order;
        });
        console.log(response.message);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const DateBody = (rowData) => {
    const formattedDate = formatDate(rowData.date);
    return (
      <>
        <span>{formattedDate}</span>
      </>
    );
  };
  const handleCreateLesson = async (lessonData) => {
    try {
      // Call API to create lesson with lessonData
      const response = await fetch("/api/instructor/create-lesson", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(lessonData),
      });
      console.log(response);
      if (response.status === 201) {
        toast.success("Lesson Created Successfully");
      }
      //   alert(response);
      // Handle success
    } catch (error) {
      console.error(error);
      alert("Failed to create lesson");
    }
  };
  const ActionBody = (rowData) => {
    return (
      <div>
        {/* <span
          className=" cursor-pointer p-2"
          onClick={() => {
            setState((prevState) => ({
              ...prevState,
              //   selectedOrder: orderId,
              isReadingOrder: true,
            }));
          }}
          style={{
            backgroundColor: "#219EBC ",
            color: "white",
            // backgroundColor: "#D5D5D5 ",
            borderRadius: "5px 0px 0px 5px",
            width: "25px",
            margin: "1px",
          }}
        >
          <i className="pi pi-eye" style={{ color: "white" }}></i>
        </span> */}
        <span
          className=" cursor-pointer p-2"
          style={{
            backgroundColor: "#219EBC ",
            color: "white",
            borderRadius: "0px 5px 5px 0px",
          }}
          onClick={() => {
            setShowLessonModal(true);
            setOrderPopulated(
              instructorJobs.find((order) => {
                return order.id === rowData.id;
              })
            );
          }}
          //   onClick={() => {
          //     setShowJobAssignModal(true);
          //     setOrderPopulated(
          //       instructorJobs.find((order) => {
          //         return order.id === rowData.id;
          //       })
          //     );
        >
          <i className="pi pi-plus mx-2" style={{ color: "white" }}></i>
          Create Lesson
        </span>
      </div>
    );
  };
  const handleInputChange = (e) => {
    setState((prevState) => ({ ...prevState, editorText: e.target.value }));
  };

  const handleInlineUpdate = async (rowData, field) => {
    await handleUpdate(rowData.id, field, editorText);
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field: "", id: "" },
      editorText: "",
    }));
    // }
  };

  const setEditor = (field, id, value) => () => {
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field, id },
      editorText: value,
    }));
  };

  const handleCancelEdit = () => {
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field: "", id: "" },
      editorText: "",
    }));
  };

  const statusBody = (rowData) => {
    const field = "status";
    const StatusfirstLetterUpperCase = rowData[field]
      ?.slice(0, 1)
      .toUpperCase();

    return (
      <Inplace
        closable={false}
        // closable
        active={activeEditor.field === field && activeEditor.id === rowData.id}
        onOpen={setEditor(field, rowData.id, rowData[field])}
      >
        <InplaceDisplay>
          <MDBPopper domElement tag="span" placement="right">
            <span className={`status-${rowData?.status?.toLowerCase()}`}>
              {/* {rowData[field]} */}
              {StatusfirstLetterUpperCase + rowData[field]?.slice(1)}
            </span>
            <span>{" Click to edit"}</span>
          </MDBPopper>
        </InplaceDisplay>
        <InplaceContent>{getEditor(rowData, field)}</InplaceContent>
      </Inplace>
    );
  };

  const getEditor = (rowData, field) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <InputText autoFocus value={editorText} onChange={handleInputChange} />
      <Button
        icon="pi pi-check"
        className="p-button-rounded p-button-success p-mr-2"
        // style={{ width: "4%" }}
        onClick={() => handleInlineUpdate(rowData, field)}
      />
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-danger"
        // style={{ width: "4%" }}
        onClick={handleCancelEdit}
      />
    </div>
  );

  const newJobs = instructorJobs.filter((order) => {
    return order.status === "new";
  });
  const inProcess = instructorJobs.filter((order) => {
    return order.status === "processing";
  });
  const completed = instructorJobs.filter((order) => {
    return order.status === "completed";
  });
  const cancelled = instructorJobs.filter((order) => {
    return order.status === "pending";
  });
  const columns = [
    {
      field: "customer.name",
      header: "Name",
      //   filter: true,
      //   body: (rowData) => inlineEditor("status", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      headerStyle: { width: "290px" },
    },
    {
      field: "customer.postcode",
      header: "PostCode",
      //   filter: true,
      //   body: (rowData) => inlineEditor("status", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      headerStyle: { width: "290px" },
    },
    {
      field: "date",
      header: "Date",
      //   filter: true,
      selectedByDefault: true,
      body: (rowData) => DateBody(rowData),
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      headerStyle: { width: "290px" },
    },
    {
      //   field: "status",
      header: "Action",
      //   filter: true,
      body: (rowData) => ActionBody(rowData),
      //   body: (rowData) => inlineEditor("status", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      headerStyle: { width: "290px" },
    },
    // {
    //   field: "status",
    //   header: "Status",
    //   //   filter: true,
    //   body: (rowData) => statusBody(rowData),
    //   //   body: (rowData) => inlineEditor("status", rowData),
    //   selectedByDefault: true,
    //   filterPlaceholder: "Search by value",
    //   filterMatchMode: "contains",
    //   headerStyle: { width: "290px" },
    // },
  ];

  return userRole === `"instructor"` ? (
    <div className="mt-3">
      <JobsDetailsModal
        modalShow={isReadingOrder}
        hideModal={() => {
          // _isMounted &&
          setState((prevState) => ({
            ...prevState,
            isReadingOrder: false,
            selectedOrder: null,
          }));
        }}
      />
      <LessonModal
        visible={showLessonModal}
        onHide={() => setShowLessonModal(false)}
        onCreate={handleCreateLesson}
        orderPopulated={orderPopulated}
        // maxDuration={}
      />
      {/* <JobAssignModal
        handleUpdate={handleUpdate}
        modalShow={showJobAssignModal}
        hideModal={() => {
          setShowJobAssignModal(false);
        }}
        order={orderPopulated}
      /> */}
      {/* <OrderDetailsModal
        paymentDetails={orderPaymentDetails}
        modalShow={isReadingOrder}
        // allOrderStatuses={allOrderStatuses}
        // orderId={selectedOrder}
        hideModal={() => {
          // _isMounted &&
          setState((prevState) => ({
            ...prevState,
            isReadingOrder: false,
            selectedOrder: null,
          }));
        }}
        // orders={instructorJobs}
        allSetting={allSettings}
        order={orderPopulated}
      /> */}

      {/* <div style={{ color: "black" }}>{topCards("New Jobs", "6")}</div> */}
      {/* {JSON.stringify(instructorJobs)} */}
      {/* // <Can I="read" a="appSettings"> */}
      <form
        //   ref={settingForm}
        className="p-m-0 p-pt-4"
        noValidate
        onSubmit={(e) => e.preventDefault()}
      >
        {" "}
        <div
          className="summary-card-container container"
          //   style={{ marginLeft: "1px" }}
          //   style={{ marginLeft: "calc(.1 *var(--bs-gutter-x))" }}
        >
          {/* <SummaryCard
            title="New Jobs"
            // value={6}
            value={newJobs.length}
            icon={<FaUsers />}
            color="#D9E5FF" // Adjust as needed
          /> */}
          <SummaryCard
            title="In Process"
            // value={10}
            value={inProcess.length}
            icon={<FaBoxOpen />}
            color="#FFF3D4" // Adjust as needed
          />
          <SummaryCard
            title="Completed"
            // value={90}
            value={completed.length}
            icon={<FaChartLine />}
            color="#DFF5E4" // Adjust as needed
          />
          {/* <SummaryCard
            // title="Cancelled"
            title="Pending"
            // value={2040}
            value={cancelled.length}
            icon={<FaTimesCircle />}
            color="#FFE5E5" // Adjust as needed
          /> */}
        </div>
        <NewCustomListing
          allRecords={instructorJobs}
          heading={"Jobs"}
          firstColumn={
            <Column
              field="id"
              header="ID"
              style={{ borderBottom: "1px solid grey" }}
              //   sortable={true}
              //   body={(rowData) => idTemplate(rowData, handleView)}
              //   filter={true}
              filterPlaceholder="Search by name"
              filterMatchMode="contains"
              headerStyle={{ width: "170px" }}
            />
          }
          columns={columns}
          // permissionSubject={"appSettings"}
          isLoading={isLoading}
          handleRefresh={() =>
            setState((prevState) => ({
              ...prevState,
              refreshData: !prevState.refreshData,
            }))
          }
          editDataKey={"id"}
          tableRef={dt}
          timeStamps={true}
        />
      </form>
    </div>
  ) : (
    /* <SettingModal
        modalShow={isUpdatingSetting}
        settingId={selectedSetting}
        hideModal={() =>
          setState((prevState) => ({
            ...prevState,
            isUpdatingSetting: false,
            selectedSetting: null,
          }))
        }
      /> */
    /* <PasswordConfirmationModal
        modalShow={isConfirmingPassword}
        handleVerifyStatus={handlePasswordConfirm}
        hideModal={() => setIsConfirmingPassword(false)}
      /> */
    // </Can>
    <>
      {/* <h2>{role}</h2>
      <h3>{userRole}</h3> */}
      <div>you are not allowed to see this page</div>
    </>
  );
};

export default InstructorJobs;
