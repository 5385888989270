import React from "react";
import "../../styles/AbilitySelection.css";
import { RadioButton } from "primereact/radiobutton";
import { Link } from "react-router-dom";
const AbilitySelection = ({
  userCategory,
  setUserCategory,
  saveToSessionHandler,
}) => {
  const handleCategoryChange = (e) => {
    setUserCategory(e.target.value);
    console.log(e.target.value);
  };
  return (
    <div
      className="mb-4"
      style={{
        borderRadius: "10px",
        padding: "10px",
        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
      }}
    >
      <h3 className="ability-selection-title">
        Select Your Ability As A Driver
      </h3>
      <div
        className="align-items-center w-50 justify-content-center col-6"
        style={{
          marginLeft: "25%",
        }}
      >
        <div className="select-container justify-content-center">
          <span className="p-field-radiobutton">
            <RadioButton
              inputId="beginner"
              name="ability"
              value="beginner"
              onChange={handleCategoryChange}
              checked={userCategory === "beginner"}
            />
            <label htmlFor="beginner" className="mx-2">
              Beginner
            </label>
          </span>
          <span className="p-field-radiobutton">
            <RadioButton
              inputId="intermediate"
              name="ability"
              value="intermediate"
              onChange={handleCategoryChange}
              checked={userCategory === "intermediate"}
            />
            <label htmlFor="intermediate" className="mx-2">
              Intermediate
            </label>
          </span>
          <span className="p-field-radiobutton">
            <RadioButton
              inputId="refresher"
              name="ability"
              value="refresher"
              onChange={handleCategoryChange}
              checked={userCategory === "refresher"}
            />
            <label htmlFor="refresher" className="mx-2">
              Refresher
            </label>
          </span>
        </div>
      </div>
      Not Sure ?{" "}
      <Link
        to="/course-recommender"
        onClick={saveToSessionHandler}
        state={{ message: "redirected from booking step 2" }}
      >
        <span>Try Our Course Recommender</span>
      </Link>
    </div>
  );
};

export default AbilitySelection;
