import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useState, useEffect } from "react";
import { updateLessonStatus } from "../../services/lessons";
import { toast } from "react-toastify";

export const LessonViewModal = ({
  visible,
  onHide,
  lesson,
  orderPopulated,
  refreshData,
  setRefreshData,
}) => {
  const [date, setDate] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [duration, setDuration] = useState(30);

  useEffect(() => {
    if (lesson) {
      setDate(new Date(lesson.date));
      setStartTime(lesson.start_time);
      setDuration(lesson.duration);
    } else {
      setDate(null);
      setStartTime("");
      setDuration(30);
    }
  }, [lesson]);

  const formatDuration = (value) => {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    return `${hours ? `${hours} hour${hours > 1 ? "s" : ""}` : ""}${
      minutes ? ` ${minutes} minute${minutes > 1 ? "s" : ""}` : ""
    }`;
  };
  function formatDate(date) {
    date = new Date(date);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const handleMarkComplete = async () => {
    console.log(lesson.start_time);
    console.log(typeof lesson.start_time);
    console.log(typeof lesson.date);

    // Assuming lesson.date is in format 'YYYY-MM-DD' and lesson.start_time is in format 'HH:mm:ss'
    const lessonDateTimeString = `${formatDate(lesson.date)}T${
      lesson.start_time
    }`;
    const lessonDateTime = new Date(lessonDateTimeString);
    console.log(lessonDateTimeString);

    console.log(lessonDateTime);

    // Check if the lesson date has passed
    if (lessonDateTime < new Date()) {
      try {
        await updateLessonStatus(lesson.id, "completed");
        toast.success("Lesson Marked Complete");
        setTimeout(() => {
          onHide();
        }, 500);
        setRefreshData(!refreshData);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2500);
      } catch (error) {
        toast.error("Failed to mark lesson as complete");
      }
    } else {
      // Show error message
      toast.error(
        "You cannot mark a lesson as complete if the date has not passed"
      );
    }
  };
  // const handleMarkComplete = async () => {
  //   const lessonDateTimeString = `${lesson.date}T${lesson.start_time}`;
  //   const lessonDateTime = new Date(lessonDateTimeString);
  //   console.log(lessonDateTime);

  //   // Check if the lesson date has passed
  //   if (lessonDateTime < new Date()) {
  //     await updateLessonStatus(lesson.id, "completed").then((res) => {
  //       toast.success("Lesson Marked Complete");
  //     });
  //   } else {
  //     // Show error message
  //     toast.error(
  //       "You cannot mark a lesson as complete if the date has not passed"
  //     );
  //   }
  // };
  // const handleMarkComplete = async () => {
  //   //handle mark complete
  //   // check if lesson date is passed
  //   if (new Date(lesson.start_time) < new Date()) {
  //     await updateLessonStatus(lesson.id, "completed");
  //   } else {
  //     //show error message
  //     alert("You cannot mark a lesson as complete if date is not passed");
  //   }
  // };
  return (
    <Dialog
      header={
        <h5 className="text-center text-primary">
          {lesson ? "Lesson Details" : "Create Lesson"}
        </h5>
      }
      visible={visible}
      onHide={onHide}
      modal
      className="bg-light rounded-lg"
      style={{ width: "max-content" }}
    >
      <div className="container p-4">
        <div className="form-group row">
          <label className="col-sm-3 col-form-label font-weight-bold ">
            Date:
          </label>
          <div className="col-sm-9">
            <p className="form-control-plaintext">
              {date ? date.toLocaleDateString() : "Not Set"}
            </p>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label font-weight-bold ">
            Start Time:
          </label>
          <div className="col-sm-9">
            <p className="form-control-plaintext">{startTime || "Not Set"}</p>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label font-weight-bold ">
            Duration:
          </label>
          <div className="col-sm-9 mx-1">
            <p className="form-control-plaintext">{formatDuration(duration)}</p>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label font-weight-bold ">
            Order No.:
          </label>
          <div className="col-sm-9">
            <p className="form-control-plaintext">{lesson?.order_id || 0}</p>
          </div>
        </div>{" "}
        <div className="form-group row">
          <label className="col-sm-3 col-form-label font-weight-bold ">
            Student:
          </label>
          <div className="col-sm-9">
            <p className="form-control-plaintext">
              {lesson?.student?.name || ""}
            </p>
          </div>
        </div>{" "}
        <div className="form-group row">
          <label className="col-sm-3 col-form-label font-weight-bold mx-1">
            Postcode:
          </label>
          <div className="col-sm-9">
            <p className="form-control-plaintext">
              {lesson?.student?.postcode || ""}
            </p>
          </div>
        </div>
        {lesson?.status !== "completed" && (
          <div className="d-flex justify-content-end mt-4">
            <Button
              label="Mark Complete"
              icon="pi pi-check"
              className="p-button-success mr-2"
              onClick={handleMarkComplete}
            />
          </div>
        )}
        <div className="d-flex justify-content-end mt-4">
          <Button
            label="Close"
            icon="pi pi-times"
            className="p-button-danger mr-2"
            onClick={onHide}
          />
          {/* <Button
            label="Edit"
            icon="pi pi-pencil"
            className="p-button-primary"
          /> */}
        </div>
      </div>
    </Dialog>
  );
};
export default LessonViewModal;
