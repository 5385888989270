// SetPassword.jsx
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/SetPassword.css";
import { Password } from "primereact/password";
import { toast } from "react-toastify";

const SetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const query = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const token = query.get("token");
  const [tokenVerified, setTokenVerified] = useState(false);
  useEffect(() => {
    const tokenmatched = async () => {
      const response = await fetch(`/api/auth/verify-token/?token=${token}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({ token }),
      });
      if (response.ok) {
        // setMessage("Token is valid");
        setTokenVerified(true);
        // toast.success("Password is set,  redirecting to the Login page ");
        // navigate("/auth/login");
      } else {
        // setMessage("Token is invalid");
        setTokenVerified(false);
      }
    };
    tokenmatched();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
      return;
    }
    try {
      const response = await fetch("/api/auth/set-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          password,
        }),
      });
      const data = await response.json();
      if (data.success) {
        setMessage("Password set successfully");
        toast.success("Password is set,  redirecting to the Login page ");
        navigate("/auth/login");
      } else {
        setMessage("Failed to set password");
      }
      // setMessage(
      //   data.success ? "Password set successfully" : "Failed to set password"
      // );
    } catch (error) {
      console.log(error);

      setMessage("Error setting password");
    }
  };

  return tokenVerified ? (
    <div className="set-password-container">
      <div className="set-password-formContainer">
        <h1 className="header">Set Password</h1>
        <form onSubmit={handleSubmit} className="form">
          <Password
            promptLabel="Choose a password"
            weakLabel="Too simple"
            mediumLabel="Average complexity"
            strongLabel="Complex password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="input"
            toggleMask
          />
          <Password
            promptLabel="Choose a password"
            weakLabel="Too simple"
            mediumLabel="Average complexity"
            strongLabel="Complex password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm Password"
            className="input"
            toggleMask
          />
          <button type="submit" className="button">
            Set Password
          </button>
        </form>
        {message && <p className="message">{message}</p>}
      </div>
    </div>
  ) : (
    <p className="message text-center">Invalid Token</p>
  );
};

export default SetPassword;
