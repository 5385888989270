import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { currentAdmin } from "../../functions/auth";
import LoadingToRedirect from "./LoadingToRedirect.js";
import { checkUserRole } from "../custom/Helpers.js";
import { useAuth } from "../../contexts/AuthContext.js";

function AdminRoute({ children }) {
  //   const { user } = useSelector((state) => ({ ...state }));
  const { userRole } = useAuth();
  const [ok, setOk] = useState(true);

  useEffect(() => {
    // if (userRole === "admin") {
    //   setOk(true);
    // } else {
    //   setOk(false);
    // }
    const checkRole = async () => {
      await checkUserRole(userRole, "admin").then((res) => {
        console.log("check userRole", res);
        setOk(res);
      });
    };

    // console.log(userRole);
    // console.log(ok);
  }, [ok, userRole]);
  if (ok) {
    // authorized so return child components
    return children;
  }
  // not logged in so redirect to login page with the return url
  <LoadingToRedirect />;
}

export default AdminRoute;
