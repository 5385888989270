import React, { useContext, useEffect, useRef, useState } from "react";
import "../styles/Login.css";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { UserContext } from "../contexts/UserContext";
import { AbilityContext } from "../configs/Ability-context";
import { defineAbilitiesFor } from "../configs/DefineAbility";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";

const Login = () => {
  const [input, setInput] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const loginForm = useRef(null);
  const emailField = useRef(null);
  const passwordField = useRef(null);
  const { login } = useAuth();
  let navigate = useNavigate();
  const { updateRules } = useContext(UserContext);
  const ability = useContext(AbilityContext);
  // let navigate = useNavigation();
  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };
  const validate = (e) => {
    e.preventDefault();
    setIsLoading(true);
    handleLogin();
  };

  const userRole = localStorage.getItem("userRole");
  const check = () => {
    if (userRole === `"admin"`) {
      // navigate("/admin-home");
      window.location.replace("/admin/courses/all");
    }
    if (userRole === `"instructor"`) {
      window.location.replace("/instructor/jobs");
    }
    if (userRole === `"customer"`) {
      window.location.replace("/student/courses");
    }
  };
  check();
  const handleLogin = async () => {
    console.log("api hit");
    const options = {
      method: "POST",
      body: JSON.stringify({ email: input.email, password: input.password }),
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch("/api/auth/login", options)
      .then((res) => res.json())
      .then((json) => {
        const { success, message, user, loggedIn } = json;
        console.log("login json", json);
        console.log("login ", loggedIn);
        setValidationMessage(message);
        if (message === "incorrect password") {
          passwordField.current.focus();
          toast.error("Incorrect Password");
        }
        if (message === `user with email: ${input.email} doesn't exist`) {
          emailField.current.focus();
          toast.error("No User With This email Exists");
        }
        if (success) {
          login();
          console.log("userRole", userRole);
          console.log("user", user);
          if (typeof Storage !== "undefined") {
            localStorage.setItem("userId", JSON.stringify(user.id));
            localStorage.setItem("userRole", JSON.stringify(user.role.name));
          } else {
            alert("Please use another browser to get it working correctly.");
          }
          // const userAbility = defineAbilitiesFor(user);
          // ability.update(userAbility.rules);
          console.log("setting value in login");
          updateRules(user, loggedIn);

          console.log("You are logged in successfully", user);
          if (user.role.name === "admin") {
            // navigate("/admin-home");
            window.location.replace("/courses/all");
          }
          if (user.role.name === "instructor") {
            // navigate("/admin-home");
            window.location.replace("/instructor/jobs");
          }
          if (user.role.name === "customer") {
            // navigate("/admin-home");
            window.location.replace("/student/courses");
          }
          // user.role.name === "admin" ? navigate("/admin-home") : navigate("/");
          // if (user.role.name === "customer") {
          //   navigate("/");
          // } else if (user.role.name === "admin") {
          //   navigate("/admin-home");
          // }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="login-container mt-5">
      <Helmet>
        <title>Login to Your Account | Pass Swiftly</title>
        <meta
          name="description"
          content="Access your Pass Swiftly account to manage bookings, view lesson schedules, Login now to get started."
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="heading">Sign In</div>
      <form action="" className="form" ref={loginForm} onSubmit={validate}>
        <label htmlFor="email" className="input-label">
          Email
        </label>
        <input
          ref={emailField}
          required
          className="input"
          type="email"
          name="email"
          id="email"
          placeholder="E-mail"
          value={input.email}
          onChange={handleChange}
        />
        <label htmlFor="password" className="input-label">
          Password
        </label>
        <input
          ref={passwordField}
          required
          className="input"
          type="password"
          name="password"
          id="password"
          placeholder="Password"
          value={input.password}
          onChange={handleChange}
        />
        {/* <span className="forgot-password">
          Not Have Account? <Link to="/auth/sign-up">{"  "}Sign-up</Link>
        </span> */}
        <span className="forgot-password">
          <Link className="mb-2" to="/resetpassword">
            <small>Forgot Password?</small>
          </Link>
          {/* <a href="#">Forgot Password ?</a> */}
        </span>
        <button className="login-button" type="submit" disabled={isLoading}>
          {isLoading ? "Signing In..." : "Sign In"}
        </button>
      </form>
    </div>
  );
};

export default Login;

// import React, { useRef, useState } from "react";
// import "../styles/Login.css";
// import { Link } from "react-router-dom";

// const Login = () => {
//   const [input, setInput] = useState({
//     email: "",
//     password: "",
//   });
//   const [isLoading, setIsLoading] = useState(false);
//   const [validationMessage, setValidationMessage] = useState("");
//   const loginForm = useRef(null);
//   const emailField = useRef(null);
//   const passwordField = useRef(null);

//   const handleChange = (e) => {
//     setInput({ ...input, [e.target.name]: e.target.value });
//     console.log({ ...input, [e.target.name]: e.target.value });
//   };
//   const validate = (e) => {
//     e.preventDefault();
//     // if (!loginForm.current.checkValidity()) {
//     //   loginForm.current.classList.add("was-validated");
//     //   return;
//     // } else {
//     setIsLoading(true);
//     handleLogin();
//     // }
//   };

//   const handleLogin = () => {
//     let options = {
//       method: "POST",
//       body: JSON.stringify({ email: input.email, password: input.password }),
//       headers: {
//         "Content-Type": "application/json",
//       },
//     };
//     fetch("/api/auth/login", options)
//       .then((res) => res.json())
//       .then((json) => {
//         // console.log(json);
//         let { success, message, user, loggedIn } = json;
//         setValidationMessage(message);
//         message === "incorrect password" && passwordField.current.focus();
//         message === `email doesn't exist` && emailField.current.focus();
//         if (success) {
//           console.log(user);
//           // setIsLoggedIn(true);
//           if (typeof Storage !== "undefined") {
//             localStorage.setItem("ui", JSON.stringify(user.id));
//           } else {
//             alert("Please use another browser to get it working correctly.");
//           }
//           console.log("you are Logged in Successfully");
//           // props.updateRules(user);
//           // updateRules(user, loggedIn);
//         }
//       })
//       .catch((err) => console.log(err))
//       .finally(() => {
//         setIsLoading(false);
//       });
//   };

//   return (
//     <div className="login-container">
//       <div className="heading">Sign In</div>
//       <form action="" className="form">
//         <label htmlFor="email" className="input-label">
//           Email
//         </label>
//         <input
//           required=""
//           className="input"
//           type="email"
//           name="email"
//           id="email"
//           placeholder="E-mail"
//           value={input.email}
//           onChange={handleChange}
//         />
//         <label htmlFor="password" className="input-label">
//           Password
//         </label>
//         <input
//           required=""
//           className="input"
//           type="password"
//           name="password"
//           id="password"
//           placeholder="Password"
//           value={input.password}
//           onChange={handleChange}
//         />
//         <span className="forgot-password">
//           Not Have Account? <Link to="/auth/sign-up">{"  "}Sign-up</Link>
//         </span>
//         <span className="forgot-password">
//           <a href="#">Forgot Password ?</a>
//         </span>
//         <input
//           className="login-button"
//           type="submit"
//           defaultValue="Sign In"
//           onClick={validate}
//         />
//       </form>
//       {/* <div className="social-account-container">
//         <span className="title">Or Sign in with</span>
//         <div className="social-accounts">
//           <button className="social-button google">
//             <svg
//               className="svg"
//               xmlns="http://www.w3.org/2000/svg"
//               height="1em"
//               viewBox="0 0 488 512"
//             >
//               <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
//             </svg>
//           </button>
//           <button className="social-button apple">
//             <svg
//               className="svg"
//               xmlns="http://www.w3.org/2000/svg"
//               height="1em"
//               viewBox="0 0 384 512"
//             >
//               <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
//             </svg>
//           </button>
//           <button className="social-button twitter">
//             <svg
//               className="svg"
//               xmlns="http://www.w3.org/2000/svg"
//               height="1em"
//               viewBox="0 0 512 512"
//             >
//               <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
//             </svg>
//           </button>
//         </div>
//       </div> */}
//       {/* <span className="agreement">
//         <a href="#">Learn user licence agreement</a>
//       </span> */}
//     </div>
//   );
// };

// export default Login;
