import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404</h1>
      <p style={styles.paragraph}>This page could not be found.</p>
      <Link to="/" style={styles.link}>
        <i className="pi pi-arrow-left" /> Go back home
      </Link>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    textAlign: "center",
    color: "#FFB703 ",
    // background: "linear-gradient(90deg, #023047, #8ecae6)",
  },
  heading: {
    fontSize: "72px",
    marginBottom: "16px",
  },
  paragraph: {
    fontSize: "24px",
    marginBottom: "24px",
  },
  link: {
    textDecoration: "none",
    color: "#023047",
    fontSize: "18px",
    transition: "color 0.2s",
  },
};

export default NotFound;
