// export const getAllCourses = async () => {
//   try {
//     fetch("/api/get-all-courses", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok " + response.statusText);
//         }
//         return response.json();
//       })
//       .then((data) => {

//         console.log("Success in getting data:", data);
//         return data;
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   } catch (error) {
//     console.log(error);
//   }
// };
export const updateCourse = async (id, updatedCourse) => {
  try {
    const options = {
      method: "PUT",
      body: JSON.stringify({ updatedCourse }),
      headers: { "Content-Type": "application/json" },
    };
    const response = await fetch(`/api/update-course/${id}`, options);
    // const response = await fetch(`/api/update-course/${id}`, {
    //   method: "PUT",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(updatedCourse), // Update this line to send the object directly
    // });
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return { success: false, message: err.message }; // Ensure an error response structure
  }
};

// export const updateCourse = async (id, updatedCourse) => {
//   try {
//     const response = await fetch(`/api/update-course/${id}`, {
//       method: "PUT",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         updatedCourse,
//       }),
//     });
//     if (!response.ok) {
//       throw new Error("Network response was not ok " + response.statusText);
//     }
//     const data = await response.json(); // Ensure response is parsed as JSON
//     return data;
//   } catch (err) {
//     console.log(err);
//   }
// };
export const getAllCourses = async () => {
  try {
    const response = await fetch("/api/get-all-courses", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }

    const data = await response.json();
    // console.log("Success in getting data:", data);
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Ensure the error is propagated
  }
};
