import React, { useContext, useEffect, useState } from "react";
import "../styles/Footer.css";
import { Link } from "react-router-dom";
import { getSettings } from "../services/settings";
import { GlobalContext } from "../contexts/GlobalContext";
import {
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaPhone,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import { PiPhoneCallLight } from "react-icons/pi";
import { FiMail, FiPhone } from "react-icons/fi";

const Footer = () => {
  const { allSettings } = useContext(GlobalContext);

  const email = allSettings.find((setting) => setting.name === "email")?.value;
  const phone = allSettings.find((setting) => setting.name === "phone")?.value;
  const instagramLink = allSettings.find(
    (setting) => setting.name === "instagram_url"
  )?.value;
  const facebookLink = allSettings.find(
    (setting) => setting.name === "facebook_url"
  )?.value;
  const tiktokLink = allSettings.find(
    (setting) => setting.name === "tiktok_url"
  )?.value;
  const youtubeLink = allSettings.find(
    (setting) => setting.name === "youtube_url"
  )?.value;
  return (
    <div className="background-primary d-flex justify-content-center align-items-center mt-5">
      <div className="grid mt-2 py-2 w-12 md:w-6">
        <div className="col-12 md:col-4">
          <div className="d-flex justify-content-center md:justify-content-start align-items-center text-center md:text-left">
            <ul className="list-unstyled">
              <li>
                <Link
                  to="/"
                  className="d-flex align-items-center justify-content-center mb-3 link-dark text-decoration-none"
                >
                  <img
                    className="footer-logo"
                    src={require("../Assets/LogoFullWhite.webp")}
                    height="80"
                    alt="Company Logo"
                    loading="lazy"
                  />
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={`tel:${phone}`}
                  className="nav-link p-0 text-white d-flex align-items-start my-2 fs-6 font-semibold"
                >
                  <span className="footer-icon mx-1">
                    <FaPhone className="text-white" />
                  </span>
                  <span className="footer-contact-text">{phone}</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={`mailto:${email}`}
                  className="nav-link p-0 text-white d-flex align-items-start my-2 fs-6 font-semibold"
                >
                  <span className="footer-icon mx-1">
                    <FaEnvelope className="text-white" />
                  </span>
                  <span className="footer-contact-text">{email}</span>
                </Link>
              </li>
              <li className="nav-item">
                {(facebookLink ||
                  instagramLink ||
                  youtubeLink ||
                  tiktokLink) && (
                  <span className="d-flex justify-content-start align-items-end">
                    {facebookLink && (
                      <span className="footer-icon mx-1 fs-5">
                        <a
                          href={facebookLink}
                          className="text-decoration-none text-white"
                        >
                          <FaFacebook />
                        </a>
                      </span>
                    )}
                    {instagramLink && (
                      <span className="footer-icon mx-1 fs-5">
                        <a
                          href={instagramLink}
                          className="text-decoration-none text-white"
                        >
                          <FaInstagram />
                        </a>
                      </span>
                    )}
                    {youtubeLink && (
                      <span className="footer-icon mx-1 fs-5">
                        <a
                          href={instagramLink}
                          className="text-decoration-none text-white"
                        >
                          <FaYoutube />
                        </a>
                      </span>
                    )}
                    {tiktokLink && (
                      <span className="footer-icon mx-1 fs-5">
                        <a
                          href={tiktokLink}
                          className="text-decoration-none text-white"
                        >
                          {" "}
                          <FaTiktok />
                        </a>
                      </span>
                    )}
                  </span>
                )}
              </li>
            </ul>
          </div>
        </div>

        <div className="col-12 md:col-4 vl ">
          <div className="d-flex justify-content-center md:justify-content-start align-items-center mx-3 text-center md:text-left">
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <h2 className="text-white fs-5 fw-bold">Quick Links</h2>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/"
                  className="nav-link p-0 text-white fs-6 font-semibold"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/about-us"
                  className="nav-link p-0 text-white fs-6 font-semibold"
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/courses"
                  // to="/courses/beginner"
                  className="nav-link p-0 text-white fs-6 font-semibold"
                >
                  Courses
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/booking"
                  className="nav-link p-0 text-white fs-6 font-semibold"
                >
                  Booking
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/contact-us"
                  className="nav-link p-0 text-white fs-6 font-semibold"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-12 md:col-4 vl ">
          <div className="d-flex justify-content-center md:justify-content-start align-items-center mx-3 text-center md:text-left">
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <h2 className="text-white fs-5 fw-bold">Learn More</h2>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/FAQs"
                  className="nav-link p-0 text-white fs-6 font-semibold"
                >
                  FAQs
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/blogs"
                  className="nav-link p-0 text-white fs-6 font-semibold"
                >
                  Blog
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/reviews"
                  className="nav-link p-0 text-white fs-6 font-semibold"
                >
                  Reviews
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/privacy-policy"
                  className="nav-link p-0 text-white fs-6 font-semibold"
                >
                  Privacy Policy
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/terms-conditions"
                  className="nav-link p-0 text-white fs-6 font-semibold"
                >
                  Terms & Conditions
                </Link>
              </li>
              {/* <li className="nav-item mb-2">
                <Link
                  to="/courses/beginner"
                  className="nav-link p-0 text-white fs-6 font-semibold"
                >
                  Pricing
                </Link>
              </li> */}

              {/* <li className="nav-item mb-2">
                <Link
                  to="/auth/instructor/sign-up"
                  className="nav-link p-0 text-white text-decoration-none fs-6 font-semibold"
                >
                  Join As Instructor
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
        <hr />
        <div className=" pb-3 text-white mt-3 col-12">
          <small className="d-flex justify-content-center align-items-center text-center">
            <strong>
              Pass swiftly Ltd registered in Scotland company No Sc808067
            </strong>
          </small>
        </div>
      </div>
    </div>

    // <div className="d-grid background-primary">
    //   <div className="col-12 md:col-4">
    //     <div className="footer-logo-container ">
    //       {" "}
    //       <div className=" mx-2 ">
    //         <ul className="list-unstyled">
    //           <li className="">
    //             <a
    //               href="/"
    //               className="d-flex align-items-center mb-3 link-dark text-decoration-none"
    //             >
    //               {" "}
    //               <img
    //                 className="footer-logo"
    //                 src={require("../Assets/LogoFullWhite.webp")}
    //                 height="80"
    //                 alt="logo"
    //                 loading="lazy"
    //               />
    //             </a>
    //           </li>
    //           <li className="nav-item mb-2">
    //             <a
    //               href={`tel:${phone}`}
    //               className="nav-link p-0 text-white d-flex align-items-start my-2 fs-6 font-semibold"
    //             >
    //               {" "}
    //               <span className="footer-icon mx-1">
    //                 <FiPhone />
    //               </span>
    //               <span className="footer-contact-text ">{phone}</span>
    //             </a>
    //           </li>
    //           <li className="nav-item mb-2">
    //             <a
    //               href={`mailto:${email}`}
    //               className="nav-link p-0 text-white d-flex align-items-start my-2 fs-6 font-semibold"
    //             >
    //               <span className="footer-icon mx-1">
    //                 <FiMail />
    //               </span>
    //               <span className=" footer-contact-text ">{email}</span>
    //             </a>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="col-12 md:col-4">
    //     <ul className="nav flex-column">
    //       <li className="nav-item mb-2">
    //         <a href="/" className="nav-link p-0 text-white">
    //           Home
    //         </a>
    //       </li>
    //       <li className="nav-item mb-2">
    //         <a href="/about-us" className="nav-link p-0 text-white">
    //           About
    //         </a>
    //       </li>
    //       <li className="nav-item mb-2">
    //         <a href="/services" className="nav-link p-0 text-white">
    //           Services
    //         </a>
    //       </li>
    //       <li className="nav-item mb-2">
    //         <a href="/FAQs" className="nav-link p-0 text-white">
    //           FAQs
    //         </a>
    //       </li>
    //       <li className="nav-item mb-2">
    //         <a href="/terms-conditions" className="nav-link p-0 text-white">
    //           Terms & Conditions
    //         </a>
    //       </li>
    //     </ul>
    //   </div>
    //   <div className="col-12 md:col-4">
    //     <ul className="nav flex-column">
    //       <li className="nav-item mb-2">
    //         <a href="/courses/beginner" className="nav-link p-0 text-white">
    //           Courses
    //         </a>
    //       </li>
    //       <li className="nav-item mb-2">
    //         <a href="/courses/beginner" className="nav-link p-0 text-white">
    //           Pricing
    //         </a>
    //       </li>
    //       <li className="nav-item mb-2">
    //         <Link
    //           to="/auth/instructor/sign-up"
    //           className="nav-link p-0 text-white text-decoration-none"
    //         >
    //           Join As Instructor
    //         </Link>
    //       </li>{" "}
    //       <li className="nav-item mb-2">
    //         <a href="/contact-us" className="nav-link p-0 text-white">
    //           Contact Us
    //         </a>
    //       </li>
    //       <li className="nav-item mb-2">
    //         <a href="/privacy-policy" className="nav-link p-0 text-white">
    //           Privacy Policy
    //         </a>
    //       </li>
    //     </ul>
    //   </div>
    // </div>
    // <div className="footer-outer-container">
    //   <div className=" footer-container container-md">
    //     <footer className="row row-cols-5 py-5 my-5 border-top">
    //       <div className="footer-logo-container ">
    //         {" "}
    //         <div className="col mx-2 ">
    //           <ul className="list-unstyled">
    //             <li className="">
    //               <a
    //                 href="/"
    //                 className="d-flex align-items-center mb-3 link-dark text-decoration-none"
    //               >
    //                 {" "}
    //                 <img
    //                   className="footer-logo"
    //                   src={require("../Assets/LogoFullWhite.webp")}
    //                   height="80"
    //                   alt="logo"
    //                   loading="lazy"
    //                 />
    //               </a>
    //             </li>
    //             <li className="nav-item mb-2">
    //               <a
    //                 href={`tel:${phone}`}
    //                 className="nav-link p-0 text-white d-flex align-items-start my-2 fs-6 font-semibold"
    //               >
    //                 {" "}
    //                 <span className="footer-icon mx-1">
    //                   <FiPhone />
    //                 </span>
    //                 <span className="footer-contact-text ">{phone}</span>
    //               </a>
    //             </li>
    //             <li className="nav-item mb-2">
    //               <a
    //                 href={`mailto:${email}`}
    //                 className="nav-link p-0 text-white d-flex align-items-start my-2 fs-6 font-semibold"
    //               >
    //                 <span className="footer-icon mx-1">
    //                   <FiMail />
    //                 </span>
    //                 <span className=" footer-contact-text ">{email}</span>
    //               </a>
    //             </li>
    //           </ul>
    //         </div>
    //       </div>
    //       <span className="d-md-none" />

    //       <div className="col">
    //         <ul className="nav flex-column">
    //           <li className="nav-item mb-2">
    //             <a href="/" className="nav-link p-0 text-white">
    //               Home
    //             </a>
    //           </li>
    //           <li className="nav-item mb-2">
    //             <a href="/about-us" className="nav-link p-0 text-white">
    //               About
    //             </a>
    //           </li>
    //           <li className="nav-item mb-2">
    //             <a href="/services" className="nav-link p-0 text-white">
    //               Services
    //             </a>
    //           </li>
    //           <li className="nav-item mb-2">
    //             <a href="/FAQs" className="nav-link p-0 text-white">
    //               FAQs
    //             </a>
    //           </li>
    //           <li className="nav-item mb-2">
    //             <a href="/terms-conditions" className="nav-link p-0 text-white">
    //               Terms & Conditions
    //             </a>
    //           </li>
    //           {/* <li className="nav-item mb-2 text-decoration-none text-white">
    //             <a
    //               href={`tel:${phone}`}
    //               className="text-decoration-none text-white"
    //             >
    //               Phone
    //             </a>
    //           </li> */}
    //         </ul>
    //       </div>
    //       <div className="col">
    //         <ul className="nav flex-column">
    //           <li className="nav-item mb-2">
    //             <a href="/courses/beginner" className="nav-link p-0 text-white">
    //               Courses
    //             </a>
    //           </li>
    //           <li className="nav-item mb-2">
    //             <a href="/courses/beginner" className="nav-link p-0 text-white">
    //               Pricing
    //             </a>
    //           </li>
    //           <li className="nav-item mb-2">
    //             <Link
    //               to="/auth/instructor/sign-up"
    //               className="nav-link p-0 text-white text-decoration-none"
    //             >
    //               Join As Instructor
    //             </Link>
    //           </li>{" "}
    //           <li className="nav-item mb-2">
    //             <a href="/contact-us" className="nav-link p-0 text-white">
    //               Contact Us
    //             </a>
    //           </li>
    //           <li className="nav-item mb-2">
    //             <a href="/privacy-policy" className="nav-link p-0 text-white">
    //               Privacy Policy
    //             </a>
    //           </li>
    //           {/* <li className="nav-item mb-2  text-decoration-none text-white">
    //             <a
    //               href={`mailto:${email}`}
    //               className="text-decoration-none text-white"
    //             >
    //               Email
    //             </a>
    //           </li> */}
    //         </ul>
    //       </div>
    //       <div className="col">
    //         <ul className="nav flex-column">
    //           <li className="nav-item mb-2">
    //             <a href="/" className="nav-link p-0 text-white">
    //               {/* Home */}
    //             </a>
    //           </li>
    //           <li className="nav-item mb-2">
    //             <a href="/" className="nav-link p-0 text-white">
    //               {/* Features */}
    //             </a>
    //           </li>
    //           <li className="nav-item mb-2">
    //             <a href="/" className="nav-link p-0 text-white">
    //               {/* Pricing */}
    //             </a>
    //           </li>
    //           <li className="nav-item mb-2">
    //             <a href="/" className="nav-link p-0 text-white">
    //               {/* FAQs */}
    //             </a>
    //           </li>
    //           <li className="nav-item mb-2">
    //             <a href="/" className="nav-link p-0 text-white">
    //               {/* About */}
    //             </a>
    //           </li>
    //         </ul>
    //       </div>
    //     </footer>
    //     <hr />
    //     <div className="d-flex justify-content-between align-items-center pb-3">
    //       <small>
    //         <strong>
    //           Pass swiftly Ltd registered in Scotland company No Sc808067
    //           {/* 3 Loganswell Drive Glasgow G468QL */}
    //         </strong>
    //       </small>
    //       {/* <small> */}
    //       {(facebookLink || instagramLink || youtubeLink || tiktokLink) && (
    //         <span className="d-flex  align-items-end">
    //           {facebookLink && (
    //             <span className="footer-icon mx-1 fs-5">
    //               <a
    //                 href={facebookLink}
    //                 className="text-decoration-none text-white"
    //               >
    //                 <FaFacebook />
    //               </a>
    //             </span>
    //           )}
    //           {instagramLink && (
    //             <span className="footer-icon mx-1 fs-5">
    //               <a
    //                 href={instagramLink}
    //                 className="text-decoration-none text-white"
    //               >
    //                 <FaInstagram />
    //               </a>
    //             </span>
    //           )}
    //           {youtubeLink && (
    //             <span className="footer-icon mx-1 fs-5">
    //               <a
    //                 href={instagramLink}
    //                 className="text-decoration-none text-white"
    //               >
    //                 <FaYoutube />
    //               </a>
    //             </span>
    //           )}
    //           {tiktokLink && (
    //             <span className="footer-icon mx-1 fs-5">
    //               <a
    //                 href={tiktokLink}
    //                 className="text-decoration-none text-white"
    //               >
    //                 {" "}
    //                 <FaTiktok />
    //               </a>
    //             </span>
    //           )}
    //         </span>
    //       )}
    //       {/* </small> */}
    //     </div>
    //   </div>
    // </div>
  );
};

export default Footer;
